import { forwardRef, useState } from "react";

const RFPsCategories = forwardRef((props: any, ref: any) => {
  const categories = props?.categories;
  const setFilters = props.setFilters;
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (category: any) => {
    setFilters({
      category_id: category.id,
      category_type_id: null,
      category_sub_type_id: null,
      pour_id: null,
    });

    setSelectedCategory(category.id);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="d-flex align-items-stretch gap-2 flex-wrap flex-lg-nowrap mb-6">
          {categories?.map((category: any) => {
            const isSelected = selectedCategory === category.id;

            return (
              <div
                key={category.id}
                onClick={() => handleCategoryClick(category)}
                className={`d-flex align-items-center justify-content-center border-secondary text-center p-0 position-relative overflow-hidden category-card rounded-xl ${isSelected ? 'selected' : ''}`}
              >
                <img
                  src={category.image}
                  className="w-100 h-100"
                  loading="lazy"
                />
                <span className={`category-overlay d-flex align-items-center justify-content-center cursor-pointer ${isSelected ? 'selected-category' : ''}`}>
                  {category.name}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default RFPsCategories;