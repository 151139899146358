import { forwardRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  fetchProduct,
  selectProduct,
} from "src/plugins/redux/reducers/ProductsReducer";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Button, ModalBody } from "reactstrap";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";


const ProductsView = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { id } = useParams();
  const [priceByStateDrob, setPriceByStateDrob] = useState(false);
  const product = useSelector(selectProduct) || {};
  const [showMore, setShowmore] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const currentUser = useSelector(currentUserSelector);
  console.log("🚀 ~ ProductsView ~ currentUser:", currentUser)


  const label_image_front = product.assets?.find(
    (file) => file.assets_type == "label_image_front"
  );
  const label_image_back = product.assets?.find(
    (file) => file.assets_type == "label_image_back"
  );

  const product_image_front = product.assets?.find(
    (file) => file.assets_type == "product_image_front"
  );
  const product_image_back = product.assets?.find(
    (file) => file.assets_type == "product_image_back"
  );
  const sell_sheets = product.assets?.find(
    (file) => file.assets_type == "sell_sheets"
  );
  const bottle_shots = product.assets?.find(
    (file) => file.assets_type == "bottle_shots"
  );

  const recipes = product?.recipes;
  const logos = product?.assets?.find((file) => file.assets_type == "logos");
  useEffect(() => {
    dispatch(fetchProduct(id));
  }, []);

  const handleDownloadClick = (file: any, type: any) => {
    /* change to https://rfp.bevbridge.com/ in production */
    const uri = file.replace('https://bevbridge-assets.s3.amazonaws.com', 'https://app-stage.bevbridge.com/s3imgs')
    const fileExtension = file.split('/').pop().split('.').pop();
    fetch(uri)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${product.name}_${type}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
  // for carosel
  const imagesCarosel = [
    product.assets?.find((file) => file.assets_type == "product_image_front")
      ?.asset_file,
    product.assets?.find((file) => file.assets_type == "product_image_back")
      ?.asset_file,
    product.assets?.find((file) => file.assets_type == "label_image_front")
      ?.asset_file,
    product.assets?.find((file) => file.assets_type == "label_image_back")
      ?.asset_file,
    product.assets?.find((file) => file.assets_type == "logos")?.asset_file,
  ];
  const filteredImages = imagesCarosel?.filter(image => image !== undefined);
  console.log(imagesCarosel)
  const images = imagesCarosel?.map((number) => ({
    src: (number && number != "null" && number != undefined) ? number : '../images/RFP Product Image Placeholder v2.png',
  }));

  // end carosel
  function convertSize(size, price) {
    const ozRegex = /(\d+(\.\d+)?) oz/;
    const mlRegex = /(\d+(\.\d+)?) ml/;
    const literRegex = /(\d+(\.\d+)?) L/;
    const GRegex = /(\d+(\.\d+)?) G/;
    const kegRegex = /Keg - (1\/2|1\/4|1\/6|Euro Keg) Barrel/;

    const ozMatch = size?.match(ozRegex);
    const mlMatch = size?.match(mlRegex);
    const literMatch = size?.match(literRegex);
    const kegMatch = size?.match(kegRegex);
    const GMatch = size?.match(GRegex);

    if (ozMatch) {
      const prices = price / parseFloat(ozMatch[1]);
      return prices.toFixed(2);
    } else if (mlMatch) {
      const mlValue = parseFloat(mlMatch[1]);
      const ozValue = mlValue / 29.5741324921;
      const prices = price / ozValue;
      return prices.toFixed(2);
    } else if (literMatch) {
      const literValue = parseFloat(literMatch[1]);
      const ozValue = literValue * 33.814;
      const prices = price / ozValue;
      return prices.toFixed(2);
    } else if (GMatch) {
      const GValue = parseFloat(GMatch[1]);
      const ozValue = GValue * 128;
      const prices = price / ozValue;
      return prices.toFixed(2);
    } else if (kegMatch) {
      const kegSize = kegMatch[1];
      let kegPrices;
      if (kegSize === "1/2") {
        kegPrices = 1984;
      } else if (kegSize === "1/4") {
        kegPrices = 992;
      } else if (kegSize === "1/6") {
        kegPrices = 661;
      } else if (kegSize === "Euro Keg") {
        kegPrices = 1690;
      }
      if (kegPrices !== undefined) {
        const prices = price / kegPrices;
        return prices.toFixed(2);
      }
    }

    return "-";
  }

  const [states, setStates] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  useEffect(() => {
    let temp = [];
    let tempExpandedItems = [];

    product.PricingByState?.map((pricing) => {
      const stateIndex = temp.findIndex((state) => state.state === pricing.id);

      if (stateIndex !== -1) {
        temp[stateIndex].pricings.push({
          distributer: pricing.distributor,
          price: pricing.price,
        });
      } else {
        temp.push({
          state: pricing.id,
          stete_name: pricing.name,
          pricings: [
            {
              distributer: pricing.distributor,
              price: pricing.price,
            },
          ],
        });
      }

      tempExpandedItems.push(false);
    });

    setStates(temp);
    setExpandedItems(tempExpandedItems);
  }, [product]);


  const handleClose = () => {
    setModal(false);
  };
  const indexes = {
    'product_image_front': 0,
    'product_image_back': 1,
    'label_image_front': 2,
    'label_image_back': 3,
    'logos': 4,

  };

  const handleIndexChange = (value) => {
    setCurrentImageIndex(value.curIndex)
  }

  return (
    <div className="row col-12 m-0">
      {product && (
        <>
          <div className="d-flex align-items-center justify-content-between p-5 border-bottom bg-white col-12">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <IconButton
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon style={{ fontSize: "2.5rem" }} />
                </IconButton>
              </div>
              <div>
                <label className="btn btn-custom-warning btn-pill btn-sm">
                  {product.catinfo?.name}
                </label>
                <h1 className="fw-bold">{product.name}</h1>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2">
              {currentUser?.role_id == 3 && (
                <>
                  <button
                    className="btn btn-outline-custom-primary rounded-pill"
                    onClick={() => {
                      navigate("/products");
                    }}
                  >
                    View All Products
                  </button>
                  <Link
                    className="btn btn-custom-primary btn-pill"
                    to={`/products/${product.id}/edit`}
                  >
                    Edit Product
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className="col-12 col-md-12 mt-5">
            <div className="row mb-6 mx-0">
              <div className="col-12 col-md-9">
                <div
                  className="card product-image"
                  style={{
                    height: "360px",
                    background: "#FFFFFF",
                    position: "relative",
                  }}
                >
                  <Carousel hasThumbnails={false} hasMediaButton={false} index={currentImageIndex} isLoop={true} objectFit='contain' hasIndexBoard={true} images={images} hasSizeButton={false} style={{ height: 360, width: '100%', display: 'flex', backgroundColor: "#FFFFFF" }}
                    onIndexChange={handleIndexChange}>
                  </Carousel>
                  <div className="d-flex justify-content-end w-100"
                    style={{
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                      margin: "10px",
                    }}>
                    {imagesCarosel?.length < 0 ? null : (
                      <div>
                        <Button className="p-3" onClick={toggle}>
                          View All
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div
                  className="card p-5"
                  style={{ height: "360px", background: "#F1F3F4" }}
                >
                  <div className="row mb-4">
                    <div className="col-12 col-md-12 text-center">
                      <img
                        src={product.comInfo?.logo_file}
                        style={{ width: "160px", height: "160px" }}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-12 text-center">
                      <span className="kt_section">
                        {product?.comInfo?.name}
                      </span>
                    </div>
                  </div>

                  <div className="kt_hr mb-4"></div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-12 text-center">
                      <label className="text-bold text-bold d-block w-100">
                        Contact
                      </label>
                      <span className="d-block">
                        {product?.userInfo?.email}
                      </span>
                    </div>
                  </div>
                  <div className="kt_hr mb-4"></div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 col-12 mb-3">
              <button onClick={() => {
                setCurrentImageIndex(indexes['product_image_front'])
              }} className={`btn btn-pill ${currentImageIndex == indexes['product_image_front'] ? 'btn-dark' : 'btn-outline-dark'}`}>
                Product Image Front
              </button>
              <button onClick={() => {
                setCurrentImageIndex(indexes['product_image_back'])
              }} className={`btn btn-pill ${currentImageIndex == indexes['product_image_back'] ? 'btn-dark' : 'btn-outline-dark'}`}>
                Product Image Back
              </button>
              <button onClick={() => {
                setCurrentImageIndex(indexes['label_image_front'])
              }} className={`btn btn-pill ${currentImageIndex == indexes['label_image_front'] ? 'btn-dark' : 'btn-outline-dark'}`}>
                Label Image Front
              </button>
              <button onClick={() => {
                setCurrentImageIndex(indexes['label_image_back'])
              }} className={`btn btn-pill ${currentImageIndex == indexes['label_image_back'] ? 'btn-dark' : 'btn-outline-dark'}`}>
                Label Image Back
              </button>
              <button onClick={() => {
                setCurrentImageIndex(indexes['logos'])
              }} className={`btn btn-pill ${currentImageIndex == indexes['logos'] ? 'btn-dark' : 'btn-outline-dark'}`}>
                Logo
              </button>
            </div>
            <div className="kt_hr_light mb-6"></div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Category</label>
                    {product.catinfo?.name ?? '-'}
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">
                      Product Description
                    </label>
                    <span className="w-100">{(product.description && product.description != '') ? product.description : '-'}</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Supplier</label>
                    <span className="w-100">{product.supplierInfo?.name ?? '-'}</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Producer</label>
                    <span className="w-100">{product.producerInfo?.name ?? '-'}</span>
                  </div>
                </div>

                {/* {product.categoryTypeInfo?.name && ( */}
                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Type</label>
                    {product.categoryTypeInfo?.name ?? '-'}
                  </div>
                </div>
                {/* )} */}

                {/* {product.categorySubTypeInfo?.name && ( */}
                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Varietal/Sub-Type</label>
                    {product.categorySubTypeInfo?.name ?? '-'}
                  </div>
                </div>
                {/* )} */}

                {product?.category_id != '4' && <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Placement Type</label>
                    {product.itemPourInfo?.name ?? '-'}
                  </div>
                </div>}

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">ABV %</label>
                    <span className="w-100">{product?.abv ? `${product?.abv} %` : '-'}</span>
                  </div>
                </div>
                <>
                  <div className="row mb-5">
                    <div className="col-12 col-md-12">
                      <label className="text-bold w-100">
                        Prominent Flavors
                      </label>
                      <div className="w-100 d-flex flex-wrap align-items-center gap-1">
                        {product?.prominentFlavorsInfo?.length > 0 ?
                          product.prominentFlavorsInfo.map((flavor, ind) => {
                            return (
                              <>
                                {flavor.name}{(ind < product?.prominentFlavorsInfo?.length - 1) && ", "}
                              </>

                            );
                          })
                          : "-"
                        }
                      </div>
                    </div>
                  </div>
                </>
                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Country of Origin</label>
                    <span className="w-100">
                      {product.countyOfOriginInfo?.name ?? '-'}
                    </span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Region/Appellation</label>
                    <span className="w-100">{product.regionsInfo?.name ?? '-'}</span>
                  </div>
                </div>


                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Unit Size</label>
                    <div className="w-100 d-flex flex-wrap align-items-center gap-1">
                      {product.productSizeInfo ?
                        product.productSizeInfo[0]?.name ?? '-'
                        :
                        '-'
                      }
                    </div>
                  </div>
                </div>

                {/* {product.catinfo?.name == "Wine" && */}
                {/* product?.itemClosureInfo && ( */}
                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Item Closure</label>
                    <span className="w-100">
                      {product?.itemClosureInfo?.name ?? '-'}
                    </span>
                  </div>
                </div>
                {/* )} */}
              </div>
              <div className="col-12 col-md-6">

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">
                      National Average Unit Price - Case 1
                    </label>
                    <span className="d-block w-100">
                      {product?.national_pricing ? `$${product?.national_pricing} ` : '-'}
                    </span>
                    {product.PricingByState?.length > 0 && (
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#pricePerStateModal"
                      >
                        See pricing by State
                      </a>
                    )}
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Units Per Case</label>
                    <span className="w-100">{product?.units_per_case ?? '-'}</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Other Available Sizes</label>
                    <div className="w-100 d-flex flex-wrap align-items-center gap-1">
                      {product?.productSizeInfo && product.productSizeInfo?.length > 1 &&
                        product.productSizeInfo.map((size, ind) => {
                          return (
                            <>
                              {ind > 0 ? size.name : ""} {(ind < product?.productSizeInfo?.length - 1 && ind > 0) && ", "}
                            </>
                          );
                        })}
                      {product?.productSizeInfo?.length <= 1 &&
                        '-'
                      }
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-5 col-md-3">
                    <label className="text-bold w-100 mb-3">
                      Product Sell Sheet
                    </label>
                    {(sell_sheets?.asset_file && sell_sheets?.asset_file != 'null' && sell_sheets?.asset_file != undefined) ? (
                      <a
                        href={sell_sheets?.asset_file}
                        target="_blank"
                        className="col-11 col-md-12 btn btn-pill btn-outline-dark w-100"
                      >
                        <i className="la la-file" />
                        View
                      </a>
                    ) : "-"}
                  </div>
                  {(sell_sheets?.asset_file && sell_sheets?.asset_file != 'null' && sell_sheets?.asset_file != undefined) &&
                    <div className="col-5 col-md-3 mb-3 pt-3">
                      <br />
                      <button
                        onClick={() => { handleDownloadClick(sell_sheets?.asset_file, 'product_sell_sheets') }}
                        className="btn btn-pill btn-outline-dark w-100"
                        style={{ maxHeight: '3rem' }}
                      >
                        <i className="la la-photo" />
                        Download
                      </button>
                    </div>
                  }
                </div>
                <div className="row mb-5 d-flex justify-content-start align-items-center">
                  <div className="col-5 col-md-3 mb-3">
                    <label className="text-bold w-100">Product Image (front)</label>
                    {(product_image_front?.asset_file && product_image_front?.asset_file != 'null' && product_image_front?.asset_file != undefined) ? (
                      <>
                        <a onClick={() => {
                          setCurrentImageIndex(0)
                        }}
                          href={product_image_front?.asset_file}
                          target="_blank"
                          className={`col-11 col-md-12 btn btn-pill w-100 ${currentImageIndex == 0 ? 'btn-dark' : 'btn-outline-dark'}`}
                        >
                          <i className="la la-photo" />
                          View
                        </a>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  {(product_image_front?.asset_file && product_image_front?.asset_file != 'null' && product_image_front?.asset_file != undefined) &&
                    <div className="col-5 col-md-3 mb-3 pt-2">
                      <br />
                      <button
                        onClick={() => {
                          handleDownloadClick(product_image_front?.asset_file, 'product_image_front')
                          setCurrentImageIndex(0)
                        }}
                        className={`btn btn-pill w-100 ${currentImageIndex == 0 ? 'btn-dark' : 'btn-outline-dark'}`}
                        style={{ maxHeight: '4rem' }}
                      >
                        <i className="la la-photo" />
                        Download
                      </button>
                    </div>
                  }
                </div>
                <div className="row mb-5">
                  <div className="col-5 col-md-3 mb-3">
                    <label className="text-bold w-100">
                      Product Image (back)
                    </label>
                    {(product_image_back?.asset_file && product_image_back?.asset_file != 'null' && product_image_back?.asset_file != undefined) ? (
                      <a
                        onClick={() => {
                          setCurrentImageIndex(1)
                        }}
                        href={product_image_back?.asset_file}
                        target="_blank"
                        className={`col-11 col-md-12 btn btn-pill w-100 ${currentImageIndex == 1 ? 'btn-dark' : 'btn-outline-dark'}`}
                      >
                        <i className="la la-photo" />
                        View
                      </a>
                    ) : "-"}
                  </div>
                  {(product_image_back?.asset_file && product_image_back?.asset_file != 'null' && product_image_back?.asset_file != undefined) &&
                    <div className="col-5 col-md-3 mb-3 pt-2">
                      <br />
                      <button
                        onClick={() => {
                          handleDownloadClick(product_image_back?.asset_file, 'product_image_back');
                          setCurrentImageIndex(1)
                        }}
                        className={`btn btn-pill  w-100 ${currentImageIndex == 1 ? 'btn-dark' : 'btn-outline-dark'}`}
                        style={{ maxHeight: '3rem' }}
                      >
                        <i className="la la-photo" />
                        Download
                      </button>
                    </div>
                  }
                </div>
                <div className="row mb-5 align-items-center">
                  <div className="col-5 col-md-3 mb-3">
                    <label className="text-bold w-100">
                      Label Image (front)
                    </label>
                    {(label_image_front?.asset_file && label_image_front?.asset_file != 'null' && label_image_front?.asset_file != undefined) ? (
                      <a
                        onClick={() => {
                          setCurrentImageIndex(2)
                        }}
                        href={label_image_front?.asset_file}
                        target="_blank"
                        className={`col-11 col-md-12 btn btn-pill w-100 ${currentImageIndex == 2 ? 'btn-dark' : 'btn-outline-dark'}`}
                      >
                        <i className="la la-photo" />
                        View
                      </a>
                    ) : "-"}
                  </div>
                  {(label_image_front?.asset_file && label_image_front?.asset_file != 'null' && label_image_front?.asset_file != undefined) &&
                    <div className="col-5 col-md-3 mb-3 pt-3">
                      <br />
                      <button
                        onClick={() => {
                          handleDownloadClick(label_image_front?.asset_file, 'label_image_front');
                          setCurrentImageIndex(2)
                        }}
                        className={`btn btn-pill w-100 ${currentImageIndex == 2 ? 'btn-dark' : 'btn-outline-dark'}`}
                        style={{ maxHeight: '3rem' }}
                      >
                        <i className="la la-photo" />
                        Download
                      </button>
                    </div>
                  }
                </div>
                <div className="row mb-5">
                  <div className="col-5 col-md-3 mb-3">
                    <label className="text-bold w-100">
                      Label Image (back)
                    </label>
                    {(label_image_back?.asset_file && label_image_back?.asset_file != 'null' && label_image_back?.asset_file != undefined) ? (
                      <a
                        onClick={() => {
                          setCurrentImageIndex(3)
                        }}
                        href={label_image_back?.asset_file}
                        target="_blank"
                        className={`col-11 col-md-12 btn btn-pill w-100 ${currentImageIndex == 3 ? 'btn-dark' : 'btn-outline-dark'}`}
                      >
                        <i className="la la-photo" />
                        View
                      </a>
                    ) : "-"}
                  </div>
                  {(label_image_back?.asset_file && label_image_back?.asset_file != 'null' && label_image_back?.asset_file != undefined) &&
                    <div className="col-5 col-md-3 mb-3 pt-3">
                      <br />
                      <button
                        onClick={() => {
                          handleDownloadClick(label_image_back?.asset_file, 'label_image_back');
                          setCurrentImageIndex(3)
                        }}
                        className={`btn btn-pill w-100 ${currentImageIndex == 3 ? 'btn-dark' : 'btn-outline-dark'}`}
                        style={{ maxHeight: '3rem' }}
                      >
                        <i className="la la-photo" />
                        Download
                      </button>
                    </div>
                  }
                </div>

                <div className="row mb-5">
                  <>
                    <label className="text-bold w-100 col-12">Recipes</label>
                    {recipes && recipes.length > 0 ? (recipes?.map((recipe, index) => {
                      return (
                        <>
                          <div class="col-5 col-md-3">
                            <a
                              href={recipe?.asset_file}
                              target="_blank"
                              className="btn btn-pill btn-outline-dark w-100"
                            >
                              <i className="la la-file-video-o" />
                              View
                            </a>
                          </div>
                          <div className="col-5 col-md-3">
                            <button
                              onClick={() => { handleDownloadClick(recipe?.asset_file, 'product_recipes') }}
                              className="btn btn-pill btn-outline-dark w-100"
                              style={{ maxHeight: '3rem' }}
                            >
                              <i className="la la-photo" />
                              Download
                            </button>
                          </div>
                          <div className="col-12 mb-3">

                          </div>
                        </>
                      )
                    })) : '-'}
                  </>
                </div>

                <div className="row mb-5">
                  <div className="col-5 col-md-3 mb-3">
                    <label className="text-bold w-100">Logo</label>
                    {(logos && logos?.asset_file != 'null' && logos?.asset_file != undefined) ? (
                      <a
                        onClick={() => {
                          setCurrentImageIndex(4)
                        }}
                        href={logos?.asset_file}
                        target="_blank"
                        className={`col-11 col-md-12 btn btn-pill w-100 ${currentImageIndex == 4 ? 'btn-dark' : 'btn-outline-dark'}`}
                      >
                        <i className="la la-photo" />
                        View
                      </a>
                    ) : "-"}
                  </div>
                  {(logos && logos?.asset_file != 'null' && logos?.asset_file != undefined) &&
                    <div className="col-5 col-md-3 mb-3 pt-2">
                      <br />
                      <button
                        onClick={() => {
                          handleDownloadClick(logos?.asset_file, 'logos');
                          setCurrentImageIndex(4)
                        }}
                        className={`btn btn-pill w-100 ${currentImageIndex == 4 ? 'btn-dark' : 'btn-outline-dark'}`}
                        style={{ maxHeight: '3rem' }}
                      >
                        <i className="la la-photo" />
                        Download
                      </button>
                    </div>
                  }
                </div>

                <div className="row mb-5">
                  <div className="col-4 col-md-3 mb-3">
                    <label className="text-bold w-100">Video Links</label>
                    {product?.videos ? (
                      product.videos.split(",").map((video, index) => (
                        video != 'null' ?
                          video.trim().startsWith('http') ? (
                            <a
                              key={index}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={video.trim()}
                            >
                              {video}
                              <br />
                            </a>
                          ) : (
                            <a
                              key={index}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={video.trim() ? `https://${video.trim()}` : 'javascript:void(0)'}
                            >
                              {video}
                              <br />
                            </a>
                          )
                          : '-'
                      ))
                    ) : "-"}
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Awards / Accolades</label>
                    <span className="w-100">{product?.awards && product?.awards != '' ? product?.awards : '-'}</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-12">
                    <label className="text-bold w-100">Notes</label>
                    <span className="w-100">{product?.notes && product?.notes != '' ? product?.notes : '-'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="pricePerStateModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="pricePerStateModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content px-4">
                <div className="modal-header">
                  <h5
                    className="modal-title fw-bold"
                    id="pricePerStateModalLabel"
                  >
                    Pricing by State
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="modal-body">
                  {states?.map((state, index) => {
                    return (
                      <div
                        className="bg-custom-light p-3 mb-2"
                        key={state.state}
                      >
                        <div className="d-flex align-items-center gap-1 toggle-state-pricing">
                          <i
                            className={`state-toggle fa fa-chevron${expandedItems[index] ? "-up" : "-down"
                              }`}
                            onClick={() => {
                              const updatedExpandedItems = [...expandedItems];
                              updatedExpandedItems[index] =
                                !expandedItems[index];
                              setExpandedItems(updatedExpandedItems);
                            }}
                          ></i>
                          <strong>{state.stete_name}</strong>
                        </div>
                        <div className="row">
                          <ul
                            className={`state-pricing-details ${expandedItems[index] ? "d-none" : ""
                              }`}
                          >
                            <li className="d-flex align-items-senter justify-content-between">
                              <span className="text-muted">
                                Distributor Price
                              </span>
                            </li>
                            {state.pricings?.map(({ distributer, price }) => {
                              return (
                                <li
                                  className="d-flex align-items-senter justify-content-between"
                                  key={distributer}
                                >
                                  <span>{distributer}</span>
                                  <span>{`$ ${price}`}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="bg-custom-light p-3">
              <div className="d-flex align-items-center gap-1 toggle-state-pricing">
                <i className="fa fa-chevron-down"></i>
                <strong>Oregon</strong>
              </div>
            </div> */}
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ModalBody style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}>
              <Carousel
                elements={
                  <IconButton onClick={handleClose} style={{ color: "#E4E6EF", position: 'absolute', top: 4, right: 4, zIndex: 1000000000 }}>
                    <CloseIcon style={{ fontSize: '2rem' }} />
                  </IconButton>}
                hasMediaButton={false}
                hasIndexBoard={false}
                images={images}
                hasSizeButton={false}
                isMaximized={true}
                index={currentImageIndex}
                onIndexChange={handleIndexChange}
                style={{ height: 500, width: 800 }} />
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
});

export default ProductsView;
