import { useEffect } from "react";
import Select from "react-select";
import { useEffect, useState } from "react";
import selectStyles from "src/themes/Helpers";
import {
  categorySubTypes,
  categoryTypes,
  poursOptions,
  selectPours,
} from "src/plugins/redux/reducers/CategoriesReducer";
import { useDispatch, useSelector } from "react-redux";


interface ProductRequestListingProps {
  categories: any;
  rfpProducts: any;
  setRfpProducts: any;
  handleNext: any;
  handlePrevious: any;
}

const ProductRequestListing = ({ ...props }: ProductRequestListingProps) => {

  const dispatch = useDispatch();
  const customSelectStyles = selectStyles.customSelectStyles;
  const pours = useSelector(selectPours);

  const categories = props.categories;


  useEffect(() => {
    dispatch(poursOptions());
  }, []);


  const productsNeedsAttentionCount = props.rfpProducts?.reduce(
    (acc: number, value: any) => {
      const types = value?.category?.types;

      const hasType = types?.some(
        (type) => type?.name === value?.type?.name
      );

      const hasCategory = value?.category?.id

      if (!hasType) {
        acc++;
      }
      if (!hasCategory) {
        acc++;
      }
      return acc;
    },
    0
  );

  return (
    <div>
      <h3>Review Imported Data</h3>
      {productsNeedsAttentionCount > 0 && (
        <p className="m-0 fs-3">
          There are
          <span className="text-danger fw-bold"> {productsNeedsAttentionCount} </span>
          data points that need your attention before your import can be
          accepted.
        </p>
      )}
      <p className="text-muted fs-3">If you would like help with your import, please contact the BevBridge team.</p>

      <div className="mb-13 custom-scroll" style={{ overflow: 'auto', maxHeight: '38rem', 'border-top-left-radius': '3rem', 'border-top-right-radius': '3rem' }}>
        <table className="table rounded-top-pill text-center">
          <thead className="bg-custom-secondary">
            <tr className="text-white">
              <th>Category</th>
              <th>Type</th>
              <th>Varietal / Sub-Type</th>
              <th>Placement</th>
              <th>Unit Price(min)</th>
              <th>Unit Price(max)</th>
              {/* <th>Notes</th> */}
            </tr>
          </thead>

          <tbody>
            {props.rfpProducts?.map((product, index) => {

              const hasCategory = product.category?.id;

              let types = product.category?.types ?? [];

              const hasType = types?.some(
                (type) => type.name === product.type?.name
              );


              let varietals = types?.find(
                (type) => type.name === product.type?.name
              )?.sub_type ?? [];

              const hasPour = pours?.some(
                (pour) => pour.name === product.pour?.pour_name
              );


              return (
                <tr
                  key={"product-request-listing-" + index}
                  className="border-bottom"
                >
                  <td>
                    <Select
                      className={'px-4 ' + (hasCategory ? '' : 'invalid')}
                      classNamePrefix="select-container"
                      options={categories?.map((category) => {
                        return {
                          value: category.id,
                          label: category.name,
                        };
                      })}
                      defaultValue={
                        {
                          value: product.category?.id,
                          label: product.category?.name,
                        }
                      }
                      isSearchable={false}
                      isClearable={false}
                      placeholder="Select Category"
                      styles={customSelectStyles}
                      onChange={(newValue) => {
                        product.category = categories?.find(
                          (category) => category.id === newValue.value
                        );
                        types = product.category?.types ?? [];
                        product.type = null;
                        product.varietal = null;
                        product.pour = null;
                        props.setRfpProducts([...props.rfpProducts]);
                      }}
                    />
                  </td>
                  <td>
                    <Select
                      className={hasType ? "" : "invalid"}
                      classNamePrefix="select-container"
                      options={types?.map((type) => ({
                        value: type.id,
                        label: type.name,
                      }))}
                      defaultValue={
                        {
                          value: product.type?.id,
                          label: product.type?.name,
                        }
                      }
                      isSearchable={false}
                      isClearable={false}
                      placeholder="Select Type"
                      styles={customSelectStyles}
                      onChange={(newValue) => {
                        varietals = types?.find(
                          (type) => type.id === newValue.value
                        )?.sub_type ?? [];
                        product.type = types.find((type) => type.id == newValue?.value);
                        product.varietal = null;
                        props.setRfpProducts([...props.rfpProducts]);
                      }}
                    />
                  </td>
                  <td>

                    {varietals?.length > 0 ? (
                      <Select
                        classNamePrefix="select-container"
                        options={
                          varietals &&
                          varietals?.map((varietal) => ({
                            value: varietal.id,
                            label: varietal.name,
                          }))
                        }
                        defaultValue={{
                          label: product.varietal?.name,
                          value: product.varietal?.id
                        }}
                        isSearchable={false}
                        isClearable={false}
                        placeholder="Select Varietal"
                        styles={customSelectStyles}
                        onChange={(newValue) => {
                          product.varietal = varietals.find((vartietal) => vartietal?.id == newValue.value);
                          props.setRfpProducts([...props.rfpProducts]);
                        }}
                      />
                    ) : (
                      <span className="text-muted"> - </span>
                    )}
                  </td>
                  {product.category?.name != "Non Alc" ? (
                    <td>
                      <Select
                        classNamePrefix="select-container"
                        options={pours?.filter((pour) => {
                          return pour.category_id === product.category?.id;
                        })?.map((pour) => ({
                          value: pour.id,
                          label: pour.name,
                        }))}
                        defaultValue={
                          {
                            value: product.pour?.id,
                            label: product.pour?.pour_name,
                          }
                        }
                        isSearchable={false}
                        isClearable={false}
                        placeholder="Select Placement"
                        styles={customSelectStyles}
                        onChange={(newValue) => {
                          product.pour = pours.find((pour) => pour.id == newValue.value);
                          props.setRfpProducts([...props.rfpProducts]);
                        }}
                      />
                    </td>
                  ) : (
                    <td> - </td>
                  )}

                  <td>
                    {product.editingMinPrice ? (
                      <>
                        <label className={'position-relative'}>
                          <input className="form-control rounded-pill w-90px" placeholder="Enter Price" id={`min-price-${index}`}
                            autoFocus={true}
                            onBlur={(e) => {
                              product.min_price = e.target.value;
                              product.editingMinPrice = false;
                              props.setRfpProducts([...props.rfpProducts]);
                            }} />
                        </label>
                      </>
                    ) : (
                      <span className={'cursor-pointer'} onClick={() => {
                        product.editingMinPrice = true;
                        props.setRfpProducts([...props.rfpProducts]);
                      }}>
                        {!isNaN(parseFloat(product.min_price)) ? ('$ ' + parseFloat(product.min_price)?.toFixed(2)) : '-'}
                      </span>
                    )}
                  </td>

                  <td>  {product.editingMaxPrice ? (
                    <>
                      <label className={`position-relative`}>
                        <input className="form-control rounded-pill w-90px" placeholder="Enter Price" id={`max_price-${index}`}
                          autoFocus={true}
                          onBlur={(e) => {
                            product.max_price = e.target.value;
                            product.editingMaxPrice = false;
                            props.setRfpProducts([...props.rfpProducts]);
                          }} />
                      </label>
                    </>
                  ) : (
                    <span className={'cursor-pointer'} onClick={() => {
                      product.editingMaxPrice = true;
                      props.setRfpProducts([...props.rfpProducts]);
                    }}>
                      {!isNaN(parseFloat(product.max_price)) ? ('$ ' + parseFloat(product.max_price)?.toFixed(2)) : '-'}
                    </span>
                  )}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>


      <div className="d-flex align-items-center gap-3">
        <button
          type="button"
          onClick={props.handlePrevious}
          className="rounded-circle btn btn-outline-custom-primary p-4 d-flex align-items-center justify-content-center"
        >
          <i className="la la-arrow-left p-0"></i>
        </button>
        <button
          type="button"
          className="btn btn-custom-primary btn-pill"
          disabled={productsNeedsAttentionCount > 0}
          onClick={props.handleNext}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default ProductRequestListing;
