import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch } from 'src/plugins/redux/reducers/HeaderReducer';
import { Link, useLocation } from 'react-router-dom';
import { fetchSuppliers, selectSuppliers, deleteCompany } from "src/plugins/redux/reducers/AdminReducers";
import AddEditSupplierRepModal from "./includes/AddEditSupplierRepModal";
import AddEditSupplierModal from "./includes/AddEditSupplierModal";
import Stack from "@mui/material/Stack";
import Tooltip from '@mui/material/Tooltip';
import { loginAs } from 'src/plugins/redux/reducers/AuthReducer';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';

interface Supplier {
    id: number;
    name: string;
    logo_file: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
}


export default function () {
    const { state } = useLocation()
    const dispatch = useDispatch();
    const suppliers = useSelector(selectSuppliers);
    const searchValue = useSelector((state) => state.header.search);
    const [filteredSuppliers, setFilteredSuppliers] = useState<Supplier[]>([]);

    const [selectedCompany, setSelectedCompany] = useState<Supplier>({} as Supplier);
    const [supplierRepModal, setSupplierRepModal] = useState<boolean>(false);
    const [supplierModal, setSupplierModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const handleShowDeleteModal = () => setDeleteModal(true);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const toggleSupplierRepModal = () => setSupplierRepModal(!supplierRepModal);
    const toggleSupplierModal = () => setSupplierModal(!supplierModal);

    useEffect(() => {
        dispatch(setHeaderTitle('Suppliers'));
        dispatch(setHeaderActionBtns([]));
        dispatch(setShowSearch(true));
        dispatch(setHeaderArrowBack(false));
        dispatch(fetchSuppliers());
    }, []);

    useEffect(() => {
        if (searchValue && searchValue != '') {
            const filtered = suppliers.filter((supplier: Supplier) => {
                return supplier.name.toLowerCase().includes(searchValue.toLowerCase());
            });
            setFilteredSuppliers(filtered);
        } else {
            setFilteredSuppliers(suppliers);
        }
    }, [searchValue, suppliers]);

    const handleLoginAsSupplier = async (id: number) => {
        const response = await dispatch(loginAs(id));
        if (response.payload?.success) {
            window.open('/login-as', '_blank');
        }
    }

    const handleDeleteSupplier = async () => {
        const response = await dispatch(deleteCompany(selectedCompany.id));
        console.log(response);
        if (response.payload?.success) {
            toast.success('Supplier deleted successfully');
            dispatch(fetchSuppliers());
        } else {
            toast.error('Failed to delete supplier');
        }
        handleCloseDeleteModal();
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-end">
                <button type="button" className="btn btn-outline-custom-primary rounded-pill ml-auto" onClick={() => {
                    setSelectedCompany({} as Supplier);
                    toggleAgencyModal();
                }}>
                    Add Supplier
                </button>
            </div>
            <div className="card mb-4 bg-custom-secondary rounded-pill mt-5">
                <div className="row p-5">
                    <div className="col-4 text-center text-white">Logo</div>
                    <div className="col-4 text-center text-white">Supplier Name</div>
                    <div className="col-4 text-center text-white">Actions</div>
                </div>
            </div>
            {filteredSuppliers && filteredSuppliers.map((supplier: Supplier) => {
                return (
                    <div className="card border-secondary mb-4 rounded-xl" key={supplier.id}>
                        <div className="row p-5 align-items-center text-center">
                            <div className="col-4">
                                <div><img onError={(e) => { e.target.onerror = null; e.target.src = '../images/BevBridgeRFPLogo.png' }}
                                    src={supplier.logo_file ? supplier.logo_file : '../images/BevBridgeRFPLogo.png'} width="56" /></div>
                            </div>
                            <div className="col-4">
                                <div>{supplier.name}</div>
                            </div>
                            <div className="col-4 d-flex align-items-center justify-content-center gap-2">
                                <Tooltip title="Add Supplier Rep" arrow>
                                    <i className="fa fa-user-plus text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(supplier)
                                        setSupplierRepModal(true)
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="View Supplier Reps" arrow>
                                    <Link className="cursor-pointer" to={`/admin/supplier/rep/${supplier.id}`}>
                                        <i className="fa fa-users text-primary-blue"></i>
                                    </Link>
                                </Tooltip>

                                <Tooltip title="Edit Supplier" arrow>
                                    <i className="fa fa-edit text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(supplier)
                                        setSupplierModal(true)
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="Login as Agency" arrow>
                                    <i className="fa fa-right-to-bracket text-primary-blue cursor-pointer" onClick={() => {
                                        handleLoginAsSupplier(supplier.id);
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="Delete Supplier" arrow>
                                    <i className="fa fa-times text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(supplier);
                                        handleShowDeleteModal();
                                    }}></i>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                );
            })}
            {filteredSuppliers.length === 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                <div className="d-flex align-items-center my-5 flex-column">
                    <h4 className="mb-3">No Suppliers Found</h4>
                    <button type="button" className="btn btn-outline-custom-primary rounded-pill" onClick={() => {
                        setSelectedCompany({} as Supplier);
                        toggleSupplierModal();
                    }}>
                        Add Supplier
                    </button>
                </div>
            </Stack>}
            <Dialog
                open={deleteModal}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    Are you sure you want to delete this agency?
                </DialogTitle>
                <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <button className="btn btn-outline-custom-primary btn-pill" onClick={handleCloseDeleteModal}>Close</button>
                    <button className="btn btn-danger btn-pill" onClick={handleDeleteSupplier}>Delete</button>
                </div>
            </Dialog>
            <AddEditSupplierRepModal isOpen={supplierRepModal} toggle={toggleSupplierRepModal} company={selectedCompany} />
            <AddEditSupplierModal isOpen={supplierModal} toggle={toggleSupplierModal} supplier={selectedCompany} />
        </div>
    );

}
