import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  loginRequest,
  currentUserSelector,
  verifyEmail
} from "plugins/redux/reducers/AuthReducer";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';


export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(currentUserSelector);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    Cookies.remove('video_showed', false);
    if (token) {
      dispatch(verifyEmail(token));
    }
  }, [])

  if (currentUser) {
    if (currentUser.role_id == 1) {
      navigate("/admin/agencies");
    } else if (currentUser.role_id == 2) {
      navigate("/rfps");
    } else if (currentUser.role_id == 3) {
      navigate("/rfps/find");
    } else if (currentUser.role_id === 4) {
      navigate("/opertator-rfps");
    }
    window.location.reload();
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<any>({
    mode: "onBlur",
  });

  const onSubmit = async (data: Object) => {
    const pass = await localStorage.setItem("password", data?.password);

    if (isValid) {
      const response = await dispatch(loginRequest(data) as any);
      console.log(response);
      if (!response.payload?.success) {
        if (response.payload == "Unverified.") {
          navigate("/verification/" + data.email);
        }
        setErrorMessage(response.payload);
      }
    }
  };

  return (
    <div className="row m-0">
      <div className="col-4">
        <div className="left-logo">
          <img className="w-25 p-6" src="/images/BevBridgeRFPLogo.png" />
        </div>
        <div className="min-100vh d-flex flex-column  justify-content-center">
          <div className="welcome-message ">
            <h1 className="font-weight-bold text-uppercase mb-10 fw-bold">
              Hi, Welcome Back
            </h1>
          </div>
          <div className="login-image align-self-center">
            {/* <img className=" " src="/assets/media/bg/illustration_login.png" /> */}
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-8 d-flex align-items-center justify-content-center min-100vh bg-custom-light">
        <div>
          <h1 className="mb-10 fw-bold">Sign in to BevBridge RFP</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-12 col-md-12 mb-5">
                <TextField
                  label="Email*"
                  variant="outlined"
                  fullWidth
                  {...register("email", {
                    required: "Email is required",
                  })}
                  InputProps={{ sx: { borderRadius: "50px" } }}
                />
              </div>
              <div className="col-12 col-md-12">
                <TextField
                  label="Password*"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: "50px" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
              </div>
            </div>

            <div className="row mb-6 align-items-center">
              <div className="col-6 col-md-6 fs-2">
                <FormControlLabel
                  control={
                    <Checkbox defaultChecked {...register("remember")} />
                  }
                  label="Remember me"
                />
              </div>
              <div className="col-6 col-md-6 text-right">
                <Link to={"/reset"} className="text-primary-blue fs-2">
                  Forgot password?
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 d-flex flex-column">
                <span className="text-danger fw-bold">{errorMessage}</span>
                <button
                  type="submit"
                  className="btn btn-custom-primary w-100 mb-3"
                  disabled={!isValid}
                >
                  Login
                </button>
                <div className="seperator d-flex align-items-center gap-2">
                  <hr />
                  <span>OR</span>
                  <hr />
                </div>
                <span className="fs-3">
                  Don’t have an account?
                  <Link
                    className="text-primary-blue ml-3 fw-bold"
                    to={"/registration"}
                  >
                    Get started
                  </Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
