import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, forwardRef } from "react";
import {
  fetchCategories,
  selectCategories,
  poursOptions,
  selectPours,
  categorySubTypes,
  categoryTypes,
} from "src/plugins/redux/reducers/CategoriesReducer";
import Categories from "./Categories";
import formatDate from "src/componenets/DateFormat";
import { Button } from "@mui/material";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";

const RfpPreview = forwardRef((props: any, ref: any): JSX.Element => {

  const currentUser = useSelector(currentUserSelector);
  const rfp = props.rfp;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState();
  const categories = useSelector(selectCategories);
  const pours = useSelector(selectPours);
  const [categorizedAssets, setCategorizedAssets] = useState({});
  const [imageAssets, setImageAssets] = useState([]);
  const [videoAssets, setVideoAssets] = useState([]);
  const [documentAssets, setDocumentAssets] = useState([]);

  useEffect(() => {
    dispatch(poursOptions());
    dispatch(categoryTypes());
    dispatch(categorySubTypes());
  }, []);

  function getExtensionFromUrl(url) {
    if (typeof url === 'string') {
      const segments = url?.split("/");
      const fileName = segments[segments.length - 1];
      const fileNameSegments = fileName.split(".");
      if (fileNameSegments.length > 1) {
        return fileNameSegments.pop().toLowerCase();
      }
    } else if (typeof url === 'object') {

      const segments = url?.asset_file?.split("/");
      const fileName = segments[segments.length - 1];
      const fileNameSegments = fileName.split(".");
      if (fileNameSegments.length > 1) {
        return fileNameSegments.pop().toLowerCase();
      }
    }
    return "";
  }
  const imageExtensions = [".jpg", ".png", ".gif", ".webp", ".jfif"];
  const documentExtensions = [".pdf", ".docx", ".txt", ".csv"];

  function getFileExtension(filename) {
    if (typeof filename === 'object') {
      return filename?.asset_file?.slice(filename?.asset_file?.lastIndexOf("."));
    }
    return filename?.slice(filename?.lastIndexOf("."));
  }
  useEffect(() => {
    if (Object.keys(rfp).length !== 0) {
      const rfpAssets = rfp.assets;
      rfpAssets?.forEach((asset) => {
        const fileExtension = getExtensionFromUrl(asset.asset_file);
        if (fileExtension) {
          if (!categorizedAssets[fileExtension]) {
            categorizedAssets[fileExtension] = [];
          }
          categorizedAssets[fileExtension].push(asset);
        }
      });

      const filteredImages = rfpAssets
        ?.filter((asset) => {
          const fileExtension = getFileExtension(asset?.asset_file);
          return imageExtensions.includes(fileExtension?.toLowerCase());
        })
        .map((asset) => asset.asset_file);

      const videoLinks = rfp.videos_links;
      const splitVideoLinks = videoLinks?.split(",");
      const filteredVideos = splitVideoLinks?.filter((link) => { return true });

      const filteredDocuments = rfpAssets
        ?.filter((asset) => {
          const fileExtension = getFileExtension(asset?.asset_file);
          return documentExtensions.includes(fileExtension?.toLowerCase());
        })
        .map((asset) => asset.asset_file);
      setImageAssets(filteredImages);
      setVideoAssets(filteredVideos);
      setDocumentAssets(filteredDocuments);
    }
  }, [rfp]);

  const judges_list = rfp?.rfps_judges?.split(",");

  useEffect(() => {
    dispatch(poursOptions());
    dispatch(fetchCategories());
  }, []);




  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="row mb-6">
          <div className="col-12 col-md-12">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="card p-6 bg-custom-light rounded-xl">
                  <div className="d-flex align-items-center justify-content-between text-center">
                    <div className="d-flex align-items-center gap-2">
                      <div>
                        <img
                          src={rfp.logo?.asset_file}
                          width="130px"
                          className="rounded-xl"
                        />
                      </div>
                      <div className="col-8 col-md-8 text-left">
                        <span className="d-block w-100 kt_section mb-3">
                          {rfp.rfp_title}
                        </span>
                        <span className="d-block w-100 text-muted">
                          Managed by:
                        </span>
                        {currentUser?.company_name}
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-pill btn-outline-custom-primary"
                        data-toggle="modal"
                        data-target="#contactFormModal"
                        disabled={true}
                      >
                        Ask a Question
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-6">
          <div className="col-8 col-md-8 mb-6">
            <div className="row mb-6">
              <div className="col-12 col-md-12 mb-3">
                <h2 className="fw-bold mb-5">Product Requests</h2>
              </div>
              <div className="col-12 col-md-12">
                <div className="d-flex align-items-stretch gap-2 flex-wrap flex-lg-nowrap mb-6">
                  {categories?.map((category: any) => {
                    return (
                      <div
                        key={category.id}
                        className="d-flex align-items-center justify-content-center border-secondary text-center p-0 position-relative overflow-hidden cateogry-card rounded-xl"
                      >
                        <img src={category.image} className="w-100 h-100" loading="lazy" />
                        <span className="category-overlay d-flex align-items-center justify-content-center cursor-pointer">
                          {category.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

            </div>

            <div className="card mb-4 bg-custom-secondary rounded-pill mt-5">
              <div className="row p-5 align-items-center">
                <div className="col-3 col-md-2 text-center text-white">
                  Category

                </div>
                <div className="col-3 col-md-2 text-center text-white">
                  Type

                </div>
                <div className="col-3 col-md-2 text-center text-white">
                  Varietal / Sub-Type
                </div>
                <div className="col-3 col-md-1 text-center text-white">
                  Placement
                </div>
                <div className="col-3 col-md-2 text-center text-white">
                  Unit Price
                </div>
                <div className="col-3 col-md-1 text-center text-white ">
                  Notes
                </div>
                <div className="col-3 col-md-2 text-center text-white">
                  Action
                </div>
              </div>
            </div>
            {/* row data  */}
            {rfp?.rfpsRequests &&
              rfp.rfpsRequests?.map((request, index) => {
                const requestCategory = categories.find(
                  (cat) => cat.id == request.category_id
                );
                const requestType = requestCategory?.types?.find(
                  (type) => type.id == request.type_id
                );
                const requestSubType = requestType?.sub_type.find(
                  (varietal) => varietal.id == request.varietal_id
                );

                return (
                  <div
                    className="card border-secondary mb-4 rounded-xl"
                    key={"product-request-" + index}
                  >
                    <div className="row p-5 align-items-center">
                      <div className="col-3 col-md-2 text-center">
                        {request.category_name
                          ? request.category_name
                          : requestCategory?.name}
                      </div>
                      <div className="col-3 col-md-2 text-center">
                        {request.category_type_name
                          ? request.category_type_name
                          : requestType?.name}
                      </div>

                      {request.sub_type_name || requestSubType?.name ?
                        <div className="col-2 col-md-2 text-center">
                          {request.sub_type_name
                            ? request.sub_type_name
                            : requestSubType?.name}
                        </div>
                        :
                        <div className="col-2 col-md-2 text-center">
                          -
                        </div>

                      }

                      {request.category_name != 'Non Alc' ? (
                        <div className="col-2 col-md-1 text-center">
                          {request?.pours_name
                            ? request?.pours_name
                            : pours.find((pour) => pour.id == request?.pour_id)?.name}
                        </div>
                      ) : (
                        <div className="col-2 col-md-1 text-center" >
                          -
                        </div>
                      )}
                      {request.min_price && request.max_price ? (
                        <div className="col-2 col-md-2 text-center">
                          {`$${request.min_price}-$${request.max_price}`}
                        </div>
                      ) : (
                        <div className="col-2 col-md-2 text-center" >
                          -
                        </div>
                      )}
                      {request.notes ? (
                        <div className="col-3 col-md-1 text-center">
                          <Button
                            disabled={true}
                            size="small"
                            variant="outlined"
                            sx={{
                              borderRadius: "50px",
                              fontSize: "10px",
                              color: "gray",
                              borderColor: "gray",
                              "&:hover": {
                                borderColor: "black",
                                backgroundColor: "black",
                                color: "white",
                              },
                            }}
                          >
                            View Note
                          </Button>
                        </div>
                      ) : (
                        <div className="col-3 col-md-1 text-center" >
                          -
                        </div>
                      )}
                      <div className="col-3  col-md-2 text-center">
                        <Button
                          disabled={true}
                          type="button"
                          variant="outlined"
                          sx={{
                            borderRadius: "50px",
                            fontSize: "10px",
                            color: "gray",
                            borderColor: "gray",
                            "&:hover": {
                              borderColor: "black",
                              backgroundColor: "black",
                              color: "white",
                            },
                          }}
                        >
                          Submit Product
                        </Button>
                        {/* <button
                          type="button"
                          className="btn btn-pill btn-sm btn-outline-dark btn-primary small"
                          onClick={() => {
                            setSelectedRquest(request);
                            toggle();
                          }}
                        >
                          Submit Product
                        </button> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="col-4 col-md-4">
            <div className="row  px-3">
              <div className="col-12 col-md-12 mb-3">
                <span className="kt_section fw-bold">RFP Overview Details</span>
              </div>

              <div className="col-12 col-md-12">
                <div className="card p-6 bg-custom-light rounded-xl">
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">Client</label>
                      <span className="w-100">{rfp?.client}</span>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        RFP Start Date
                      </label>
                      <span className="w-100">
                        {formatDate(rfp.rfp_start_date)}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        RFP End Date
                      </label>
                      <span className="w-100">
                        {formatDate(rfp.rfp_end_date)}
                      </span>
                    </div>
                  </div>

                  {/* <div className="kt_hr mb-3"></div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 text-bold">
                        Presentation Type
                      </label>
                      <span>
                        {rfp.presentation_type == "1" ? "In Person" : "Virtual"}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 text-bold">
                        Presentation Location
                      </label>
                      {rfp.presentation_location ?? 'TBD'}
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        Presentation Date
                      </label>
                      <span className="w-100">
                        {formatDate(rfp.presentation_date)}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        Buyers / Attendees
                      </label>
                    </div>
                    {judges_list &&
                      judges_list?.map((judge) => {
                        return (
                          <div className="col-12 col-md-12 py-1">
                            <span className="w-100">{judge}</span>
                          </div>
                        );
                      })}
                  </div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        Presentation Length
                      </label>
                      <span className="w-100">
                        {rfp.presentation_length} minutes
                      </span>
                    </div>
                  </div> */}

                  <div className="kt_hr mb-3"></div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        Program Start Date
                      </label>
                      <span className="w-100">
                        {formatDate(rfp.program_start_date)}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        Program End Date
                      </label>
                      <span className="w-100">
                        {formatDate(rfp.program_end_date)}
                      </span>
                    </div>
                  </div>

                  <div className="kt_hr mb-3"></div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 mb-0 text-bold">
                        Additional RFP Details
                      </label>
                      <span dangerouslySetInnerHTML={{ __html: rfp.additional_details }}></span>
                    </div>
                  </div>

                  <div className="kt_hr mb-3"></div>

                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="w-100 text-bold">Uploaded Assets</label>
                    </div>
                    <div className="col-12 col-md-12">
                      {imageAssets?.length !== 0 && (
                        <div
                          style={{ cursor: "pointer" }}
                          className="d-flex align-items-center gap-2"
                        >
                          <span>
                            <i
                              className="fa-solid fa-image"
                              style={{ width: "24px", height: "24px" }}
                            ></i>
                            {imageAssets?.length > 0 ? imageAssets.length : props?.assetFiles.length} Images
                          </span>
                        </div>
                      )}
                      {documentAssets?.length !== 0 && (
                        <div
                          style={{ cursor: "pointer" }}
                          className="d-flex align-items-center gap-2 py-2"
                        >
                          <span>
                            <i
                              className="fa-regular fa-file"
                              style={{ width: "24px", height: "24px" }}
                            ></i>
                            {documentAssets?.length} Docs
                          </span>
                        </div>
                      )}
                      {videoAssets?.length !== 0 &&
                        videoAssets?.map((asset, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center gap-2"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_452_6289)">
                                <path
                                  d="M9 7V15L16 11L9 7ZM21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V21H16V19H21C22.1 19 23 18.1 23 17V5C23 3.9 22.1 3 21 3ZM21 17H3V5H21V17Z"
                                  fill="#081324"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_452_6289">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <a
                              href={asset}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {asset}
                            </a>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 py-5 border-top">
          <button
            type="button"
            onClick={props.handlePrevious}
            className="rounded-circle btn btn-outline-custom-primary p-4 d-flex align-items-center justify-content-center"
          >
            <i className="la la-arrow-left p-0"></i>
          </button>
          <button
            type="submit"
            disabled={props.isLoading}
            className="btn btn-outline-custom-primary btn-pill"
            onClick={() => {
              props.setSelectedStatus("draft");
            }}>
            {props.isLoading && (
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
            Save Draft
          </button>
          <button
            disabled={props.isLoading}
            onClick={() => {
              props.setSelectedStatus("published");
            }}
            type="submit"
            className="btn btn-custom-primary btn-pill"
          >
            {props.isLoading && (
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
            Publish
          </button>
        </div>
      </div>
    </div>
  );
});

export default RfpPreview;
