export default function existsIn(arr, val) {
    val = val?.toLowerCase().replace(/[\W\s]/g, '');

    if (!val || val === '') {
        return false;
    }

    return arr?.find(
        item => item.name?.toLowerCase().replace(/[\W\s]/g, '').includes(val)
    );
}