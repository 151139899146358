import { useState, useEffect } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerRequest } from "plugins/redux/reducers/AuthReducer";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  fetchCompanies,
  selectCompanies,
} from "plugins/redux/reducers/AdminReducers";

import helpers from "themes/Helpers";


export default function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyType, setCompanyType] = useState<string | number>(1);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [currentCompany, setCurrentCompany] = useState<string | null>(null);
  const [email, setEmail] = useState();
  const [validEmailDomain, setValidEmailDomain] = useState(false);
  const [roleID, setRoleId] = useState<string | number>('');
  const pupularDomains = helpers.pupularDomains;
  console.log('pupularDomains', pupularDomains);
  const companies = useSelector(selectCompanies);

  const handleCompanyTypeChange = (event: SelectChangeEvent) => {
    const selectedRoleId = event.target.value;
    setCompanyType(selectedRoleId); // Update companyType state
    setRoleId(selectedRoleId); // Update roleId state
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    getValues,
  } = useForm<any>({
    mode: "onBlur",
  });
  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  const isPasswordValid = (password: string) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const validateEmailDomain = () => {
    const email = watch("email");
    console.log(email);
    if (email) {
      const trimmedEmail = email.trim()?.toLowerCase();
      const emailDomain = trimmedEmail.split("@")[1];
      const matchingCompany = companies.find(
        (company) => company.domains?.find((domain) => domain.domain == emailDomain)
      );
      if (matchingCompany && !pupularDomains.includes(emailDomain)) {
        setCurrentCompany(matchingCompany);
        if (matchingCompany.type == 'agency') {
          setRoleId(2)
          setValue('role_id', 2)
        } else if (matchingCompany.type == 'supplier') {
          setRoleId(3)
          setValue('role_id', 3)
        } else if (matchingCompany.type == 'operator') {
          setRoleId(4)
          setValue('role_id', 4)
        }

      } else {
        if (currentCompany && currentCompany != "") {
          setCurrentCompany("");
          setValue('company', '');
        }

      }
    }
  };

  useEffect(() => {
    validateEmailDomain();
  }, [watch, companies, validateEmailDomain]);

  const onSubmit = async (data: any) => {
    console.log(data);
    if (isValid) {
      const response = await dispatch(registerRequest(data) as any);
      if (response.payload.success) {
        navigate("/verification/" + data["email"]);
      } else {
        setErrorMessage(response.payload);
      }
    }
  };
  const firstNameValue = getValues("first_name");

  return (
    <div className="row m-0">
      <div className="col-4">
        <div className="left-logo">
          <img className="w-25 p-6" src="/images/BevBridgeRFPLogo.png" />
        </div>
        <div className="min-100vh d-flex flex-column  justify-content-center">
          <div className="welcome-message ">
            <h1 className="font-weight-bold text-uppercase mb-10 fw-bold">
              Hi, Welcome
            </h1>
          </div>
          <div className="registration-image align-self-center">
            {/* <img className=" " src="/assets/media/bg/form-fill-bot.png" /> */}
          </div>
        </div>
      </div>
      <div className="col-8 d-flex align-items-center justify-content-center min-100vh bg-custom-light">
        <div className="w-50">
          <h1 className="mb-10 fw-bold">Registration</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 mb-5">
                <TextField
                  label="First Name*"
                  variant="outlined"
                  fullWidth
                  {...register("first_name", {
                    required: "First Name is required",
                  })}
                  InputProps={{ sx: { borderRadius: "50px" } }}
                />
                <span className="text-danger">{errors.firstName?.message}</span>
              </div>
              <div className="col-12 mb-5">
                <TextField
                  label="Last Name*"
                  variant="outlined"
                  fullWidth
                  InputProps={{ sx: { borderRadius: "50px" } }}
                  {...register("last_name", {
                    required: "Last Name is required",
                  })}
                />
                <span className="text-danger">{errors.lastName?.message}</span>
              </div>
              <div className="col-12 mb-5">
                <TextField
                  autoComplete={false}
                  label="Email address*"
                  variant="outlined"
                  fullWidth
                  InputProps={{ sx: { borderRadius: "50px" }, autoComplete: 'off' }}
                  onChange={(e) => {
                    console.log(e);
                  }}
                  {...register("email", {
                    required: "Email address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                <span className="text-danger">{errors.email?.message}</span>
              </div>
              <div className="col-12 mb-5">
                {currentCompany && currentCompany != "" ? (
                  <TextField

                    variant="outlined"
                    disabled={true}
                    value={currentCompany?.name}
                    fullWidth
                    InputProps={{
                      sx: { borderRadius: "50px" }, autoComplete: 'off',
                    }}
                  />
                ) : (
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    disabled={false}
                    fullWidth
                    InputProps={{ sx: { borderRadius: "50px" } }}
                    {...register("company", {
                      required: "Comapny Name is required",
                    })}
                  />
                )}
              </div>
              <div className="col-12 mb-5">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="company-type-select-label">Company Type*</InputLabel>
                    <Controller
                      name="role_id"
                      control={control}
                      rules={{ required: "Company Type is required" }}
                      render={({ field }) => (
                        <Select
                          labelId="company-type-select-label"
                          id="company-type-select"
                          {...field}
                          value={roleID}
                          label="Company Type"
                          onChange={(e) => {
                            field.onChange(e);
                            handleCompanyTypeChange(e);
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "50px",
                            },
                          }}
                        >
                          <MenuItem disabled={currentCompany?.type !== "agency"} value={2}>
                            Agency
                          </MenuItem>
                          <MenuItem disabled={currentCompany?.type !== "operator"} value={4}>
                            Operator
                          </MenuItem>
                          <MenuItem disabled={currentCompany?.type !== "supplier"} value={3}>
                            Supplier
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>
                <span className="text-danger">
                  {errors.companyType?.message}
                </span>
              </div>
              <div className="col-12 mb-5">
                <TextField
                  label="Password*"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: "50px" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("password", {
                    required: "Password is required",
                    validate: (value) => {
                      return (
                        isPasswordValid(value) ||
                        "Must contain minimum 6 characters including at least one letter, one number, and one special character (@$!%*#?&)"
                      );
                    },
                  })}
                />
                <span className="text-danger">{errors.password?.message}</span>
              </div>
              <div className="col-12 mb-5">
                <TextField
                  label="Confirm Password*"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: "50px" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("password_confirmation", {
                    required: "Confirm Password is required",
                    validate: (value) => {
                      return (
                        value === watch("password") || "Passwords do not match"
                      );
                    },
                  })}
                />
                <span className="text-danger">
                  {errors.password_confirmation?.message}
                </span>
              </div>
              <div className="col-12 d-flex flex-column">
                <span className="text-danger fw-bold">{errorMessage}</span>
                <button
                  type="submit"
                  className="btn btn-custom-primary mb-3 w-100"
                  disabled={!isValid}
                >
                  Register
                </button>
                <div className="seperator d-flex align-items-center gap-2">
                  <hr />
                  <span>OR</span>
                  <hr />
                </div>
                <span className="fs-3">
                  Already have an account?{" "}
                  <Link to={"/login"} className="text-primary-blue fw-bold">
                    Sign-in here.
                  </Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
