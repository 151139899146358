import { Outlet } from "react-router-dom";
import { useState } from "react";

import Header from "layouts/includes/Header";
import MobileHeader from "layouts/includes/MobileHeader";
import Menu from "layouts/includes/Menu";
import ScrollTop from "layouts/includes/ScrollTop";


export default function AppLayout() {

    return (
        <div id="kt_body" className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">
            <MobileHeader />
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                    <Menu />
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="d-flex flex-column-fluid">
                                <div className="container">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTop />
        </div>
    );
}
