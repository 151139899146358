import { forwardRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRfpProducts, selectRfpProducts, selectStatus } from "src/plugins/redux/reducers/RFPsReducer";
import ProductsFilter from 'src/pages/Products/includes/ProductsFilter';
import { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch } from 'src/plugins/redux/reducers/HeaderReducer';
import Papa from 'papaparse';
import { useForm, Controller } from "react-hook-form";
import ProductsFilterModal from "./productFiltersModal";



const ProductsSubmissions = forwardRef((props: any, ref: any): JSX.Element => {

    const dispatch = useDispatch();
    const location = useLocation();

    const products = useSelector(selectRfpProducts);
    const status = useSelector(selectStatus);
    const { id } = useParams();
    const rfp = location.state?.item;
    const [filters, setFilters] = useState({});
    const [sortBy, setSortBy] = useState();
    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, isValid, dirtyFields },
        getValues,
        control,
        setError
    } = useForm({});



    useEffect(() => {
        dispatch(setHeaderTitle(rfp?.rfp_title));
        dispatch(setHeaderActionBtns([{
            'link': '/create',
            'text': 'Add New RFP'
        },
        {
            'link': `/rfp/products/short-list/${id}`,
            'text': 'View Shortlist'
        }]));

        dispatch(setHeaderArrowBack(true));
        dispatch(setShowSearch(false));
        dispatch(fetchRfpProducts({ rfp_id: id }));
    }, [filters, sortBy]);


    const exportToCsv = () => {
        var productsLocal = []
        products.map(product => {
            productsLocal.push({
                "Product Name": product.name,
                "Supplier": product.supplier,
                "Category": product.catinfo ? product.catinfo.name : '',
                "Type": product.typeinfo ? product.typeinfo.name : '',
                "Varietal/Sup-Type": product.subtypeinfo ? product.subtypeinfo.name : '',
                "Pour": product.pourinfo ? product.pourinfo.name : '',
                "Unit Size": product.sizes,
                "Item Closure": product.closureinfo ? product.closureinfo.name : '',
                "ABV": product.abv,
                "Unit Price": product.price_per_unit
            })
        })
        var csv = Papa.unparse(productsLocal);

        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        var csvURL = window.URL.createObjectURL(csvData);

        var testLink = document.createElement('a');
        testLink.href = csvURL;
        testLink.setAttribute('test', 'test.csv');
        testLink.click();
    }


    const handleFilterProducts = async (data: any) => {
        data.rfp_id = id
        let sizes = [];
        let flavors = [];
        if (data.sizes && Array.isArray(data.sizes)) {
            sizes = data.sizes.map((size) => size.value);
            data.sizes = sizes.join(",");
        }
        data.sizes = sizes.join(",");

        if (data.flavors && Array.isArray(data.flavors)) {
            flavors = data.flavors.map((flavor) => size.flavor);
            data.flavors = flavors.join(',')
        }

        const response = await dispatch(fetchRfpProducts(data));
        console.log('filter form data', data);
        console.log(response);
    }

    return (
        <div>
            {/* <ProductsFilter
                type={filters?.category_id}
                subType={filters?.category_type_id}
                filters={filters}
                setFilters={setFilters}
                setSortBy={setSortBy}
            /> */}

            <div className="d-flex align-items-center justify-content-between m-3">
                <button className="btn btn-outline-custom-primary btn-pill" onClick={() => setShowFiltersModal(true)}>
                    All Filters
                    <i class="fa-solid fa-filter"></i>
                </button>
                <button type="button" className="btn btn-pill btn-outline-custom-primary font-weight-bold" onClick={() => exportToCsv()}>
                    Export Csv
                    <i className="la la-upload"></i>
                </button>
            </div>
            <div className="row mt-5">
                <div className="col-12 col-md-12">

                    <div className="card mb-4 rounded-pill bg-custom-secondary">
                        <div className="row p-5">
                            <div className="col-2 col-md-2 text-center text-white">Product Image</div>
                            <div className="col-3 col-md-2 text-white">Product name</div>
                            <div className="col-1 col-md-2 text-center text-white">Category</div>
                            <div className="col-1 col-md-1 text-center text-white">Type</div>
                            <div className="col-2 col-md-2 text-center text-white">Varietal</div>
                            <div className="col-2 col-md-2 text-center text-white">Producer</div>
                            <div className="col-1 col-md-1 text-center text-white">Action</div>
                        </div>
                    </div>
                    {!products &&
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{ minHeight: '50vh' }}>
                            <strong className="text-center fs-2 text-muted">
                                There is No Submitted Products For This RFP
                            </strong>
                        </div>
                    }
                    {status === 'loading' ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '50vh' }}>
                            <div className="spinner-border text-custom-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) :
                        products && products.map((product: any, key: any) => (
                            <div key={key} className="card mb-4 bg-white border border-secondary rounded-lg">
                                <div className="row p-5 align-items-center text-custom-primary">
                                    <div className="col-2 col-md-2 text-center"><img src={product.assets?.some((file) => file.assets_type == 'product_image_front') ? product.assets?.find((file) => file.assets_type == 'product_image_front').asset_file : '/images/product-placeholder.png'} style={{ width: '56px', height: '56px' }} /></div>
                                    <div className="col-3 col-md-3">{product.name}</div>
                                    <div className="col-1 col-md-1 text-center">{product.catinfo.name}</div>
                                    <div className="col-1 col-md-1 text-center">{product.categoryTypeInfo?.name}</div>
                                    <div className="col-2 col-md-2 text-center">{product.categorySubTypeInfo?.name}</div>
                                    <div className="col-2 col-md-2 text-center">${product.producerInfo?.name}</div>
                                    <div className="col-1 col-md-1 text-center d-flex pr-3">
                                        <Link className="mr-4" to={`/products/${product.id}`}
                                            state={{ product: product }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <g clip-path="url(#clip0_4_3167)">
                                                    <path d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z" fill="#003ACE" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4_3167">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </Link>
                                        <Link to={`/products/${product.id}/edit`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <g clip-path="url(#clip0_4_3170)">
                                                    <path d="M16.5 3C14.76 3 13.09 3.81 12 5.09C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.42 2 8.5C2 12.28 5.4 15.36 10.55 20.04L12 21.35L13.45 20.03C18.6 15.36 22 12.28 22 8.5C22 5.42 19.58 3 16.5 3ZM12.1 18.55L12 18.65L11.9 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 5.99 11.07 7.36H12.94C13.46 5.99 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55Z" fill="#003ACE" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4_3170">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        ))}

                    {status === 'error' &&
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{ minHeight: '50vh' }}>
                            <strong className="text-center fs-1 text-custom-primary">
                                Error occurred while fetching items.
                            </strong>
                            <button className="btn btn-custom-primary btn-pill" onClick={() => dispatch(fetchRfpProducts({
                                filters: { ...filters },
                                sortBy: sortBy,
                                rfp_id: rfp.id
                            }) as any)}>
                                <i className="fa fa-redo-alt" />
                                Retry
                            </button>
                        </div>
                    }
                </div>
            </div>
            <form onSubmit={handleSubmit(handleFilterProducts)}>
                <ProductsFilterModal
                    showFiltersModal={showFiltersModal}
                    setShowFiltersModal={setShowFiltersModal}
                    register={register}
                    control={control}
                    onSubmit={handleSubmit(handleFilterProducts)}
                />
            </form>
        </div>


    );

});

export default ProductsSubmissions;