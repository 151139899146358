import { forwardRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { useDispatch, useSelector } from "react-redux";
import { inviteBeveragePartners } from "src/plugins/redux/reducers/RFPsReducer";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Papa from "papaparse";
import toast from "react-hot-toast";
import { fetchItem, selectCurrentItem } from "src/plugins/redux/reducers/RFPsReducer";
import formatDate from "src/componenets/DateFormat";
import RfpInvites from "src/componenets/RfpInvites";

const InviteBeveragePartners = forwardRef(
  (props: any, ref: any): JSX.Element => {
    const { id } = useParams<{ id: string }>();

    const [csvData, setCSVData] = useState();
    const [selectedFileName, setSelectedFileName] = useState("");
    const rfp = useSelector(selectCurrentItem);

    const handleCSVUpload = (event) => {
      const file = event.target.files[0];
      const fileInput = event.target;
      if (file && file.type !== "text/csv") {
        return toast.error("Invalid file type. Please select a .csv file.");
      }
      if (fileInput.files && fileInput.files[0]) {
        const file_name = fileInput.files[0].name;
        setSelectedFileName(file_name);
        if (file) {
          Papa.parse(file, {
            header: true,
            complete: (results) => {
              setCSVData(results.data.map((item) => {
                return item['Corporate Email Address (Required)']
              }));
            },
          });
        }
      }
    };

    useEffect(() => {
      if (id) {
        dispatch(fetchItem({ 'id': id }));
      }
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, getValues } = useForm();

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    useEffect(() => {
      dispatch(setHeaderTitle("Invite Beverage Partners"));
      dispatch(setHeaderActionBtns([]));
      dispatch(setHeaderArrowBack(false));
      dispatch(setShowSearch(false));
    }, []);

    const handleSendEmails = async (data: any) => {
      const emails = data.emails.split(/[, \n]+/);

      let validEmails = [];
      emails.forEach((email: any) => {
        if (!isValidEmail(email)) {
          return;
        } else {
          validEmails.push(email)
        }
      });

      data.rfp_id = id;
      data.type = "suppliers";
      const response = await dispatch(inviteBeveragePartners(data));
      console.log(response);
      if (response.payload.success) {
        toast.success("Emails Sent Successfully");
        setValue('emails', response.payload?.success?.join(','));
        if (id) {
          dispatch(fetchItem({ 'id': id }));
        }
      }
    };
    function isValidEmail(email: any) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const showUploadCsvModal = () => {
      $("#uploadCsvModal").modal("show");
    };

    const handleSubmitCsv = () => {
      if (csvData?.length > 0) {
        const data = csvData
          .map((item: any) => {
            if (item && isValidEmail(item) && item !== "") {
              return item
            }
          });
        if (data.length > 0) {
          setValue('emails', data.join(',').replace(/,$/, ''));
        }
      }
      $('#uploadCsvModal').modal('hide');
    }


    return (
      <form
        className="position-relative"
        onSubmit={handleSubmit(handleSendEmails)}
      >
        <div className="pb-10 ml-3 border-bottom d-flex flex-column align-items-start gap-1" >
          <span className="fs-3">Add Emails By CSV</span>
          <button
            type="button"
            className="btn btn-pill btn-outline-custom-primary ml-4 mb-4 d-flex align-items-center justify-content-center gap-1"
            onClick={showUploadCsvModal}
          >
            Upload CSV
            <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 13H10V7H14L7 0L0 7H4V13ZM7 2.83L9.17 5H8V11H6V5H4.83L7 2.83ZM0 15H14V17H0V15Z" fill="#081324" />
            </svg>
          </button>
        </div>
        <div className="mt-10">
          <span className=" fs-3 ml-3 mb-5 pb-5">Add Emails Manually</span>
          <textarea
            {...register("emails", { required: true })}
            name="emails"
            className="form-control rounded-xl mt-3"
            placeholder="Enter emails separated by commas"
            rows={10}
          ></textarea>
        </div>
        <div className="d-flex align-items-center gap-3 mt-10">
          <button
            type="button"
            className="btn btn-outline-custom-primary p-4 rounded-circle"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </button>
          <button type="submit" className="btn btn-custom-primary btn-pill">
            Send Invites
          </button>
        </div>
        <div className="position-absolute bottom-0">
          {showSuccessAlert && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Emails Sent Successfully
            </Alert>
          )}
        </div>
        <hr />

        <RfpInvites invites={rfp?.invites} handleSendEmails={handleSendEmails} />


        <div
          className="modal fade"
          id="uploadCsvModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="uploadCsvModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content px-4">
              <div className="modal-header">
                <h5
                  className="modal-title fw-bold fs-1"
                  id="uploadCsvModalLabel"
                >
                  Follow these steps to easily upload your data
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times fa-xl"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column mb-10 align-items-start">
                  <strong className="text-custom-primary mb-4">Step 1</strong>
                  <span className="text-muted fs-4">Download our template</span>
                  <a
                    className="btn btn-outline-custom-primary btn-pill d-flex align-items-center justify-content-center gap-1"
                    href="/assets/invites.csv"
                    download
                  >
                    Download template
                    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 6H10V0H4V6H0L7 13L14 6ZM6 8V2H8V8H9.17L7 10.17L4.83 8H6ZM0 15H14V17H0V15Z" fill="#081324" />
                    </svg>

                  </a>
                </div>
                <div className="d-flex flex-column mb-10">
                  <strong>Step 2</strong>
                  <span className="text-muted fs-4">
                    Paste your data into the template
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start">
                  <strong>Step 3</strong>
                  <span className="text-muted fs-4">
                    Upload the completed template here
                  </span>
                  <div>
                    {selectedFileName ? (
                      <div className="d-flex align-items-center gap-1">
                        <span className="text-primary-blue file-name">
                          {selectedFileName}
                        </span>
                        <i
                          className="fa fa-times fa-lg cursor-pointer remove-rfp-csv-file"
                          onClick={() => {
                            setSelectedFileName("");
                          }}
                        ></i>
                      </div>
                    ) : (
                      <label className="btn btn-outline-custom-primary btn-pill d-flex align-items-center justify-content-center gap-1">
                        <input
                          type="file"
                          className="d-none rfp-csv-file"
                          onChange={handleCSVUpload}
                        />
                        Upload template
                        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 13H10V7H14L7 0L0 7H4V13ZM7 2.83L9.17 5H8V11H6V5H4.83L7 2.83ZM0 15H14V17H0V15Z" fill="#081324" />
                        </svg>

                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column my-5">
                <button
                  type="button"
                  className={"btn btn-custom-primary btn-pill mb-5 "}
                  onClick={handleSubmitCsv}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form >
    );
  }
);

export default InviteBeveragePartners;
