import { Outlet } from "react-router-dom";

export default function AuthLayout() {

  return (
    <div id="kt_body">

      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Outlet />
          </div>
        </div>
      </div>

    </div>
  );
}
