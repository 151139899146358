import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCategories,
  fetchCategories,
} from "src/plugins/redux/reducers/CategoriesReducer";
import { useDispatch } from "react-redux";

const Categories = forwardRef((props: any, ref: any) => {
  const [requestsCount, setRequestsCount] = useState({} as any);
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const RFPs = props.RFPs;
  const setFilters = props.setFilters;
  const filters = props.filters;

  console.log(RFPs);

  useEffect(() => {
    dispatch(fetchCategories());


  }, []);

  useEffect(() => {
    const requests = RFPs?.rfpsRequests;
    console.log(requests);
    if (requests) {
      categories?.map((category: any) => {
        const categoryRequests = requests.filter(
          (request: any) => request.category_id === category.id
        );
        setRequestsCount((prev: any) => ({
          ...prev,
          [category.id]: category
            ? categoryRequests.length
            : 0,
        }));

        return category;
      });
    }

  }, [categories]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (category: any) => {
    setFilters({
      category_id: category.id,
      category_type_id: null,
      category_sub_type_id: null,
      pour_id: null,
    });

    setSelectedCategory(category.id);
  };
  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="d-flex align-items-stretch gap-2 flex-wrap flex-lg-nowrap mb-6">
          {categories?.map((category: any) => {
            const isSelected = selectedCategory === category.id;

            return (
              <div
                key={category.id}
                onClick={() => handleCategoryClick(category)}
                className={`d-flex align-items-center justify-content-center border-secondary text-center p-0 position-relative overflow-hidden category-card rounded-xl ${isSelected ? 'selected' : ''}`}
              >
                <span class="position-absolute bottom-0 text-white" style={{ right: 10, zIndex: 2 }}>
                  {requestsCount[category.id] || 0}
                </span>
                <img
                  src={category.image}
                  className="w-100 h-100"
                  loading="lazy"
                />
                <span className={`category-overlay d-flex align-items-center justify-content-center cursor-pointer ${isSelected ? 'selected-category' : ''}`}>
                  {category.name}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default Categories;
