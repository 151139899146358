const LightTheme = {

    font: 'San Francisco',
    colorDefault: 'rgba(255, 255, 255, 1);',
    colorSecondary: 'rgba(241, 243, 244, 1)',
    
    colorDefaultBorder: 'rgba(204, 204, 204, 1)',
    colorDefaultOutline: '0 0 0 3px rgba(175, 175, 175, 0.3)',
    colorDefaultText: 'rgba(0, 0, 0, 1)',

};

export default LightTheme;
