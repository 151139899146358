import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducers/AuthReducer";
import RFPsReducer from "./reducers/RFPsReducer";
import ProductsReducer from "./reducers/ProductsReducer";
import CategoriesReducer from "./reducers/CategoriesReducer";
import HeaderReducer from "./reducers/HeaderReducer";
import StatesReducer from "./reducers/StatesReducer";
import DropdowndataReducer from "./reducers/DropdownDataReducer";
import AdminReducer from "./reducers/AdminReducers";
import UsersReducers from "./reducers/UsersReducers";
import ClientsReducer from "./reducers/ClientsReducer";


const ReduxStorePlugin = configureStore({
  reducer: {
    auth: AuthReducer,
    rfps: RFPsReducer,
    products: ProductsReducer,
    categories: CategoriesReducer,
    header: HeaderReducer,
    states: StatesReducer,
    dropdownData: DropdowndataReducer,
    companies: AdminReducer,
    users: UsersReducers,
    clients: ClientsReducer,
  },
});

export default ReduxStorePlugin;