import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'plugins/axios';
import Cookies from "js-cookie"

interface Client {
    id: string;
    name: string;
    company_id: string;
    description: string;
    created_at: string;
    updated_at: string;
}

interface ClientsState {
    clients: Client[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState: ClientsState = {
    clients: [],
    status: 'idle',
};

export const fetchCompanyClients = createAsyncThunk('clients/fetchClients', async () => {
    const response = await axios.get('/clients');
    return response.data;
});

export const addClient = createAsyncThunk('clients/addClient', async (client: Client) => {
    const response = await axios.post('/clients/create', client);
    return response.data;
});

export const updateClient = createAsyncThunk('clients/updateClient', async (client: Client) => {
    const response = await axios.put(`/clients/${client.id}/update`, client);
    return response.data;
});


const ClientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyClients.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCompanyClients.fulfilled, (state, action) => {
                state.status = 'idle';
                state.clients = action.payload.clients;
            })
            .addCase(fetchCompanyClients.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addClient.fulfilled, (state, action) => {
                state.status = 'idle';
            }).addCase(updateClient.fulfilled, (state, action) => {
                state.status = 'idle';
            });
    }
});

export const selectClients = (state) => state.clients.clients;
export default ClientsSlice.reducer;
