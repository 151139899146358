import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { uploadImageToS3 } from "src/plugins/redux/reducers/RFPsReducer";
import { addCompany, updateCompany } from "src/plugins/redux/reducers/AdminReducers";
import { setHeaderActionBtns, setHeaderArrowBack, setHeaderTitle, setShowSearch } from "src/plugins/redux/reducers/HeaderReducer";
import { fetchOperators } from "src/plugins/redux/reducers/AdminReducers";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import Select from 'react-select';
import selectStyles from 'src/themes/Helpers';


interface AddEditOperatorModalProps {
    isOpen: boolean;
    toggle: () => void;
    operator: any;
}



export default function AddEditOperatorModal({ toggle, isOpen, operator }: AddEditOperatorModalProps) {

    const dispatch = useDispatch();
    const [logoFile, setLogoFile] = useState(null);
    const { state } = useLocation();
    const navigate = useNavigate()
    const customSelectStyles = selectStyles.formSelectStyles;

    const companyTypes = [
        { value: 'agency', label: 'Agency' },
        { value: 'supplier', label: 'Supplier' },
        { value: 'operator', label: 'Operator' },
    ]

    const [companyType, setCompanyType] = useState({ value: 'operator', label: 'Operator' });

    useEffect(() => {
        dispatch(setHeaderActionBtns(false));
        dispatch(setShowSearch(false));
        dispatch(setHeaderArrowBack(false));

    }, [])

    useEffect(() => {
        setLogoFile({ name: operator.logo_file });
        setValue("name", operator.name);
        setValue("domains", operator.domains?.map((domain: any) => domain.domain).join(","));
        setValue("type", operator.type);
    }, [operator]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        formState: { errors },
        control,
    } = useForm();


    const handleLogoInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setLogoFile(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddCompany = async (data: any) => {
        if (logoFile instanceof File) {
            const logo_file_url = await dispatch(
                uploadImageToS3({ file: logoFile, dirName: "operator" })
            );
            data.logo_file = logo_file_url ? logo_file_url.payload : "";
        } else {
            data.logo_file = logoFile?.name;
        }
        data.type = companyType.value;
        const response = await dispatch(addCompany(data));
        console.log(response);
        if (response.payload?.success) {
            dispatch(fetchOperators());
            toast.success("Operator added successfully");
            toggle();
        } else {
            setError(response.payload?.type, response.payload?.error)
        }
    };

    const handleUpdateCompany = async (data: any) => {
        if (logoFile instanceof File) {
            const logo_file_url = await dispatch(
                uploadImageToS3({ file: logoFile, dirName: "operator" })
            );
            data.logo_file = logo_file_url ? logo_file_url.payload : "";
        } else {
            data.logo_file = logoFile?.name;
        }
        data.type = companyType.value;
        data.id = operator?.id.toString();
        const response = await dispatch(updateCompany(data));
        console.log(response);
        if (response.payload.success) {
            dispatch(fetchAgencies());
            toast.success("Supplier updated successfully");
            toggle();
        } else {
            setError(response.payload?.type, response.payload?.error)
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
            <form
                className="col-12 col-md-12"
                onSubmit={operator?.name ? handleSubmit(handleUpdateCompany) : handleSubmit(handleAddCompany)}
                encType="multipart/form-data"
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 24px",
                    }}
                >
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {operator?.id ? "Update Operator" : "Add Operator"}
                    </Typography>
                    <IconButton onClick={toggle}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />
                <ModalBody>
                    <div className="d-inline-block mb-3">
                        <label className="w-100">Logo</label>
                        <div>
                            {logoFile?.name && logoFile?.name != "" ? (
                                <div className="d-flex align-items-center gap-1">
                                    <span className="text-primary-blue file-name">
                                        {logoFile.name}
                                    </span>
                                    <i
                                        className="fa fa-times fa-lg cursor-pointer"
                                        onClick={() => setLogoFile(null)}
                                    ></i>
                                </div>
                            ) : (
                                <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                    <input
                                        type="file"
                                        name="logo_file"
                                        className="d-none rfp-csv-file"
                                        onChange={handleLogoInputChange}
                                    />
                                    Browse
                                    <i className="la la-upload"></i>
                                </label>
                            )}
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="w-100">Name</label>
                        <div className="d-flex align-items-center gap-1">
                            <input
                                type="text"
                                className={"form-control rounded-pill " + (errors.name ? "invalid-input" : "")}
                                name="name"
                                {...register("name", { required: true })}
                            />
                            {
                                errors.name && (
                                    <Tooltip title="Name is required" arrow>
                                        <ErrorIcon color="error" />
                                    </Tooltip>
                                )
                            }
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="w-100">Company Type</label>
                        <div className="d-flex align-items-center gap-1">
                            <Controller
                                name="type"
                                {...register("type", { required: true })}
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        className={(errors.type ? "invalid-input" : "")}
                                        styles={customSelectStyles}
                                        options={companyTypes?.map((type) => {
                                            return {
                                                label: type.label,
                                                value: type.value
                                            }
                                        })}
                                        onChange={(e) => {
                                            setCompanyType(e);
                                            onChange(e.value);
                                        }}
                                        value={companyType}
                                    />
                                )}
                            />
                            {errors.type && (
                                <Tooltip arrow title={errors.type.message || 'Type is required'}>
                                    <ErrorIcon color="error" />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="w-100">Domains</label>
                        <div className="d-flex align-items-center gap-1">
                            <textarea
                                type="text"
                                className={`form-control ${errors.domains ? "invalid-input" : ""}`}
                                name="domains"
                                placeholder="Enter domains separated by commas"
                                {...register("domains", { required: true })}
                            />
                            {errors.domains && (
                                <Tooltip arrow title={errors.domains.message || 'Domain already exists'}>
                                    <ErrorIcon color="error" />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-outline-custom-primary rounded-pill w-100">
                        Submit
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    )

}