function formatDate(dateString: any) {
  const dateObj = new Date(dateString);
  dateObj.setDate(dateObj.getDate());
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    timeZone: "America/New_York"
    };

  const formattedDate = dateObj.toLocaleString("en-US", options).replace(/\//g, "-");
  return formattedDate;
}

export default formatDate;