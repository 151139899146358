import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch } from 'src/plugins/redux/reducers/HeaderReducer';
import { selectCategories, selectCategoriesStatus, fetchCategories, poursOptions, selectPours } from 'src/plugins/redux/reducers/CategoriesReducer';
import Select from 'react-select';
import selectStyles from 'src/themes/Helpers';
import CreatableSelect from 'react-select/creatable';
import selectStyles from "src/themes/Helpers";

const UploadedProductsPreview = forwardRef((props: any, ref: any): JSX.Element => {

    const [isSticky, setIsSticky] = useState(false);

    const pours = useSelector(selectPours);
    const dispatch = useDispatch();

    const customSelectStyles = selectStyles.customSelectStyles;

    useEffect(() => {

        dispatch(poursOptions());

        const handleScroll = () => {
            const stickyColumn = document.querySelector('.sticky-column');
            const rect = stickyColumn.getBoundingClientRect();

            if (rect.top <= 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    function isblank(str: string) {
        return (!str || /^\s*$/.test(str));
    }



    console.log(props.uploadedProducts)
    const productsNeedsAttentionCount = props.uploadedProducts?.reduce(
        (acc: number, product: any) => {

            if (!product.producer?.id && (product.category?.id == 1 || product.category?.id == 2)) {
                acc++;
            }

            if (!product.type?.id) {
                acc++;
            }

            if (!product.category?.id) {
                acc++;
            }

            if (!product.subtype?.id && (product?.category?.id == 2 || (product?.category?.id == 1 && product?.type?.id == 81)) && product.type?.sub_type?.length > 0) {
                acc++;
            }

            if (!product.unit_size?.id) {
                acc++;
            }

            if (!product.country_of_orgin?.id) {
                acc++;
            }

            if (!product.region?.id && product.category?.id == 2) {
                acc++;
            }

            if (isblank(product.name)) {
                acc++;
            }

            if (isblank(product.abv)) {
                acc++;
            }

            if (isblank(product.units_per_case)) {
                acc++;
            }


            if (isblank(product?.item_closure?.id) && product?.category?.id == 2) {
                acc++;
            }

            if (!product?.pour?.id && product?.category?.id == 3) {
                acc++;
            }

            return acc;

        },
        0
    );

    return (
        <div>
            <h1 className="mb-3">Review Imported Data</h1>
            {productsNeedsAttentionCount > 0 && <p className="text-muted">There are <strong className="text-danger" onClick={() => {
                const invalidElement = document.querySelector('.invalid');
                if (invalidElement) {
                    invalidElement.scrollIntoView();
                }
            }}>{productsNeedsAttentionCount}</strong> data points that need your attention before your import can be accepted.</p>}
            <div className="mb-13 custom-scroll" style={{ overflow: 'auto', maxHeight: '38rem', 'border-top-left-radius': '3rem', 'border-top-right-radius': '3rem' }}>
                <table className="table rounded-top-pill text-center" style={{ width: 'max-content' }}>
                    <thead>
                        <tr className="text-white text-center">
                            <th className="bg-custom-secondary">Category</th>
                            <th className="bg-custom-secondary" style={{ zIndex: 4, left: 0 }}>Product Name</th>
                            <th>Product Description</th>
                            <th>Producer</th>
                            <th>Type</th>
                            <th>Varietal/Sub-Type</th>
                            <th>Placement</th>
                            <th>ABV</th>
                            <th>Prominent Flavors</th>
                            <th>Country of Orgin</th>
                            <th>Region</th>
                            <th>Unit Size</th>
                            <th>Units per Case</th>
                            <th>Price per Case - Case 1</th>
                            <th>Other Available Sizes</th>
                            <th>Item Closure</th>
                            <th>Sell Sheets (link)</th>
                            <th>Product Image - Front (link)</th>
                            <th>Product Image - Back (link)</th>
                            <th>Label Image - Front (link)</th>
                            <th>Label Image - Back (link)</th>
                            <th>Logos (link)</th>
                            <th>Recipes (link)</th>
                            <th>Videos (links)</th>
                            <th>Awards / Accolades</th>
                            <th>Notes, if any</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.uploadedProducts && props.uploadedProducts.map((product, index) => {

                            const types = product.category?.types;
                            const subTypes = product.type?.sub_type;

                            return (
                                <tr className="text-center" key={'product-preview-' + index}>
                                    <td>
                                        <Select
                                            className={product.category?.id ? '' : 'invalid'}
                                            classNamePrefix="select-container"
                                            styles={customSelectStyles}
                                            options={props.categories.map((category) => {
                                                return {
                                                    label: category.name,
                                                    value: category.id
                                                }
                                            })}
                                            defaultValue={product.category?.id ? {
                                                label: product.category?.name,
                                                value: product.category?.id
                                            } : null}
                                            onChange={(selectedOption) => {
                                                product.category = props.categories.find((category) => category.id === selectedOption.value);
                                                product.type = null;
                                                props.setUplaodedProducts([...props.uploadedProducts]);
                                            }} />
                                    </td>
                                    <td className="" style={{ position: 'sticky', zIndex: 2, left: 0, background: '#fff' }}>
                                        {product.editingName ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.name}
                                                onBlur={(e) => {
                                                    product.name = e.target.value;
                                                    product.editingName = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className={"cursor-pointer " + (isblank(product.name) ? 'invalid' : '')}
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingName = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.name) ? product.name : '-'}
                                            </span>
                                        )
                                        }
                                    </td>
                                    <td class="table-row text-truncate">{
                                        product.editingDescription ? (
                                            <textarea
                                                className="form-control"
                                                defaultValue={product.description}
                                                onBlur={(e) => {
                                                    product.description = e.target.value;
                                                    product.editingDescription = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingDescription = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.description) ? product.description : '-'}
                                            </span>
                                        )
                                    }</td>
                                    <td>
                                        <div className="d-flex align-items-center gao-1">
                                            <Select
                                                className={((!product.producer?.id) && (product.category?.id == 1 || product.category?.id == 2 || product?.producer?.name)) ? 'invalid' : ''}
                                                classNamePrefix="select-container"
                                                styles={customSelectStyles}
                                                options={props.producers.map((producer) => {
                                                    return {
                                                        label: producer.name,
                                                        value: producer.id
                                                    }
                                                })}
                                                value={{
                                                    label: product.producer?.name,
                                                    value: product.producer?.id
                                                }}
                                                onChange={(selectedOption) => {
                                                    product.producer = props.producers.find((producer) => producer.id === selectedOption.value);
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            />
                                            {(product.producer?.name && !product.producer?.id) &&
                                                <i className="fas fa-save p-0 text-custom-primary cursor-pointer" onClick={() => {
                                                    const new_producer_id = props.handleCreateDropdownData("producers", product.producer?.name);
                                                    props.uploadedProducts.forEach((_) => {
                                                        if (_.producer?.name == product.producer?.name) {
                                                            _.producer = { id: new_producer_id, name: product.producer?.name };
                                                        }
                                                    });
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}></i>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <Select
                                            className={product.type?.id ? '' : 'invalid'}
                                            classNamePrefix="select-container"
                                            styles={customSelectStyles}
                                            options={types?.map((type) => {
                                                return {
                                                    label: type.name,
                                                    value: type.id
                                                }
                                            })}
                                            value={{
                                                label: product.type?.name,
                                                value: product.type?.id
                                            }}
                                            onChange={(selectedOption) => {
                                                product.type = types?.find((type) => type.id === selectedOption.value);
                                                product.subtype = null;
                                                props.setUplaodedProducts([...props.uploadedProducts]);
                                            }
                                            } />
                                    </td>
                                    <td>
                                        {subTypes?.length > 0 ? (<Select
                                            className={(product.subtype?.id) ? '' : 'invalid'}
                                            classNamePrefix="select-container"
                                            styles={customSelectStyles}
                                            options={subTypes?.map((subtype) => {
                                                return {
                                                    label: subtype.name,
                                                    value: subtype.id
                                                }
                                            })}
                                            value={{
                                                label: product.subtype?.name,
                                                value: product.subtype?.id
                                            }}
                                            onChange={(selectedOption) => {
                                                product.subtype = subTypes?.find((subtype) => subtype.id === selectedOption.value);
                                                props.setUplaodedProducts([...props.uploadedProducts]);
                                            }} />) : '-'
                                        }
                                    </td>
                                    <td>
                                        {pours?.length > 0 ? (<Select
                                            className={(!product.pour?.id && product?.category?.id == '3') ? 'invalid' : ''}
                                            classNamePrefix="select-container"
                                            styles={customSelectStyles}
                                            options={pours?.filter((pour) => {
                                                return pour.category_id == product.category?.id;
                                            })?.map((pour) => {
                                                return {
                                                    label: pour.name,
                                                    value: pour.id
                                                }
                                            })}
                                            value={{
                                                label: product.pour?.name,
                                                value: product.pour?.id
                                            }}
                                            onChange={(selectedOption) => {
                                                product.pour = pours?.find((pour) => pour.id === selectedOption.value);
                                                props.setUplaodedProducts([...props.uploadedProducts]);
                                            }} />) : '-'
                                        }
                                    </td>
                                    <td class="table-row text-truncate">

                                        {product.editingABV ? (
                                            <input
                                                className={"form-control"}
                                                defaultValue={product.abv}
                                                onBlur={(e) => {
                                                    product.abv = e.target.value;
                                                    product.editingABV = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className={"cursor-pointer " + (isblank(product.abv) ? ' invalid' : '')}
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingABV = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.abv) ? ('% ' + product.abv) : '-'}
                                            </span>
                                        )
                                        }
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <Select
                                                className={product.prominent_flavors?.some((flavor) => !flavor?.id && flavor?.name) ? 'invalid' : ''}
                                                classNamePrefix="select-container"
                                                styles={customSelectStyles}
                                                options={props.flavors?.map((flavor) => {
                                                    return {
                                                        label: flavor.name,
                                                        value: flavor.id
                                                    }
                                                })}
                                                value={product.prominent_flavors?.map((flavor) => {
                                                    if (flavor?.id) {
                                                        return {
                                                            label: flavor.name,
                                                            value: flavor.id
                                                        }
                                                    }
                                                })}
                                                isMulti={true}
                                                onChange={(selectedOptions) => {
                                                    product.prominent_flavors = selectedOptions.map((selectedOption) => {
                                                        return props.flavors.find((flavor) => flavor.id === selectedOption.value);
                                                    });
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }
                                                }
                                            />
                                            {
                                                product.prominent_flavors?.some((flavor) => !flavor?.id && flavor?.name) && (
                                                    <i className="fas fa-save p-0 text-custom-primary cursor-pointer" onClick={() => {
                                                        props.uploadedProducts.forEach((product) => {
                                                            product.prominent_flavors?.forEach((flavor) => {
                                                                if (!flavor.id && flavor.name) {
                                                                    const new_flavor_id = props.handleCreateDropdownData("prominentFlavors", flavor.name);
                                                                    props.uploadedProducts.forEach((_) => {
                                                                        if (_.prominent_flavors?.some((_) => _.name == flavor.name)) {
                                                                            _.prominent_flavors = _.prominent_flavors?.map((_) => {
                                                                                if (_.name == flavor.name) {
                                                                                    return { id: new_flavor_id, name: flavor.name };
                                                                                }
                                                                                return _;
                                                                            });
                                                                        }
                                                                    }
                                                                    );
                                                                }
                                                            }
                                                            );
                                                        }
                                                        );
                                                        props.setUplaodedProducts([...props.uploadedProducts]);
                                                    }}></i>)}
                                        </div>
                                    </td>
                                    <td class="table-row">
                                        <div className="d-flex align-items-center">
                                            <Select
                                                className={product.country_of_orgin?.id ? '' : 'invalid'}
                                                classNamePrefix="select-container"
                                                styles={customSelectStyles}
                                                options={props.countries.map((country) => {
                                                    return {
                                                        label: country.name,
                                                        value: country.id
                                                    }
                                                })}
                                                value={{
                                                    label: product.country_of_orgin?.name,
                                                    value: product.country_of_orgin?.id
                                                }}
                                                onChange={(selectedOption) => {
                                                    product.country_of_orgin = props.countries.find((country) => country.id === selectedOption.value);
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }
                                                } />
                                            {(product.country_of_orgin?.name && !product.country_of_orgin?.id) &&
                                                <i className="fas fa-save p-0 text-custom-primary cursor-pointer" onClick={() => {
                                                    const new_country_id = props.handleCreateDropdownData("countyOfOrigin", product.country_of_orgin?.name);
                                                    props.uploadedProducts.forEach((_) => {
                                                        if (_.country_of_orgin?.name == product.country_of_orgin?.name) {
                                                            _.country_of_orgin = { id: new_country_id, name: product.country_of_orgin?.name };
                                                        }
                                                    });
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}></i>
                                            }
                                        </div>
                                    </td>
                                    <td class="table-row">
                                        <div className="d-flex align-items-center">
                                            <Select
                                                className={(!product.region?.id && (product.category?.id == 2 || product.region?.name)) ? 'invalid' : ''}
                                                classNamePrefix="select-container"
                                                styles={customSelectStyles}
                                                options={props.regions.map((region) => {
                                                    return {
                                                        label: region.name,
                                                        value: region.id
                                                    }
                                                })}
                                                value={{
                                                    label: product.region?.name,
                                                    value: product.region?.id
                                                }}
                                                onChange={(selectedOption) => {
                                                    product.region = props.regions.find((region) => region.id === selectedOption.value);
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }
                                                } />
                                            {(product.region?.name && !product.region?.id) &&
                                                <i className="fas fa-save p-0 text-custom-primary cursor-pointer" onClick={() => {
                                                    const new_country_id = props.handleCreateDropdownData("regions", product.region?.name);
                                                    props.uploadedProducts.forEach((_) => {
                                                        if (_.region?.name == product.region?.name) {
                                                            _.region = { id: new_country_id, name: product.region?.name };
                                                        }
                                                    });
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}></i>
                                            }
                                        </div>
                                    </td>
                                    <td class="table-row">
                                        <Select
                                            className={product.unit_size?.id ? '' : 'invalid'}
                                            classNamePrefix="select-container"
                                            styles={customSelectStyles}
                                            options={props.sizes?.filter((size) => {
                                                return size.category_id == product.category?.id;
                                            })?.map((unitSize) => {
                                                return {
                                                    label: unitSize.name,
                                                    value: unitSize.id
                                                }
                                            })}
                                            value={{
                                                label: product.unit_size?.name,
                                                value: product.unit_size?.id
                                            }}
                                            onChange={(selectedOption) => {
                                                product.unit_size = props.sizes.find((unitSize) => unitSize.id === selectedOption.value);
                                                props.setUplaodedProducts([...props.uploadedProducts]);
                                            }} />
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingUnitsPerCase ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.units_per_case}
                                                onBlur={(e) => {
                                                    product.units_per_case = e.target.value;
                                                    product.editingUnitsPerCase = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className={"cursor-pointer " + (isblank(product.units_per_case) ? ' invalid' : '')}
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingUnitsPerCase = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.units_per_case) ? product.units_per_case : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingPricePerCase ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.price_per_case}
                                                onBlur={(e) => {
                                                    product.price_per_case = e.target.value;
                                                    product.editingPricePerCase = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingPricePerCase = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.price_per_case) ? ('$' + product.price_per_case) : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <CreatableSelect
                                            className="react-select"
                                            classNamePrefix="select-container"
                                            styles={customSelectStyles}
                                            defaultValue={product.other_available_sizes?.map((size) => {
                                                if (size?.id) {
                                                    return {
                                                        label: size.name,
                                                        value: size.id
                                                    }
                                                }
                                            })}
                                            options={props.sizes?.filter((size) => {
                                                return size.category_id == product.category?.id;
                                            })?.map((unitSize) => {
                                                return {
                                                    label: unitSize.name,
                                                    value: unitSize.id
                                                }
                                            })}
                                            isMulti={true}
                                            onChange={(selectedOptions) => {
                                                product.other_available_sizes = selectedOptions.map((selectedOption) => {
                                                    return props.sizes.find((unitSize) => unitSize.id === selectedOption.value);
                                                });
                                                props.setUplaodedProducts([...props.uploadedProducts]);
                                            }
                                            }
                                        />
                                    </td>
                                    <td class="table-row">
                                        {product.category?.id == 1 || product.category?.id == 2 ? (
                                            <Select
                                                className={(!product.item_closure?.id && product?.category?.id == 2) ? 'invalid' : ''}
                                                classNamePrefix="select-container"
                                                styles={customSelectStyles}
                                                options={props.itemClosures?.map((closure) => {
                                                    return {
                                                        label: closure.name,
                                                        value: closure.id
                                                    }
                                                })}
                                                value={{
                                                    label: product.item_closure?.name,
                                                    value: product.item_closure?.id
                                                }}
                                                onChange={(selectedOption) => {
                                                    product.item_closure = props.itemClosures?.find((closure) => closure.id === selectedOption.value);
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }
                                                } />)
                                            : '-'}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingSellSheets ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.sell_sheets}
                                                onBlur={(e) => {
                                                    product.sell_sheets = e.target.value;
                                                    product.editingSellSheets = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingSellSheets = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.sell_sheets) ? product.sell_sheets : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingProductImageFront ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.product_image_front}
                                                onBlur={(e) => {
                                                    product.product_image_front = e.target.value;
                                                    product.editingProductImageFront = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingProductImageFront = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.product_image_front) ? product.product_image_front : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingProductImageBack ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.product_image_back}
                                                onBlur={(e) => {
                                                    product.product_image_back = e.target.value;
                                                    product.editingProductImageBack = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingProductImageBack = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.product_image_back) ? product.product_image_back : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingLabelImageFront ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.label_image_front}
                                                onBlur={(e) => {
                                                    product.label_image_front = e.target.value;
                                                    product.editingLabelImageFront = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingLabelImageFront = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.label_image_front) ? product.label_image_front : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingLabelImageBack ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.label_image_back}
                                                onBlur={(e) => {
                                                    product.label_image_back = e.target.value;
                                                    product.editingLabelImageBack = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingLabelImageBack = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.label_image_back) ? product.label_image_back : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingLogos ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.logo}
                                                onBlur={(e) => {
                                                    product.logo = e.target.value;
                                                    product.editingLogos = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingLogos = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.logo) ? product.logo : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingRecipes ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.recipes}
                                                onBlur={(e) => {
                                                    product.recipes = e.target.value;
                                                    product.editingRecipes = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingRecipes = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.recipes) ? product.recipes : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingVideos ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.videos}
                                                onBlur={(e) => {
                                                    product.videos = e.target.value;
                                                    product.editingVideos = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                style={{ maxWidth: 200 }}
                                                onClick={() => {
                                                    product.editingVideos = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.videos) ? product.videos : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <td class="table-row text-truncate">
                                        {product.editingAwards ? (
                                            <input
                                                className="form-control"
                                                defaultValue={product.awards}
                                                onBlur={(e) => {
                                                    product.awards = e.target.value;
                                                    product.editingAwards = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    product.editingAwards = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.awards) ? product.awards : '-'}
                                            </span>
                                        )}
                                    </td>
                                    <th class="table-row text-truncate">
                                        {product.editingNotes ? (
                                            <textarea
                                                className="form-control"
                                                defaultValue={product.notes}
                                                onBlur={(e) => {
                                                    product.notes = e.target.value;
                                                    product.editingNotes = false;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    product.editingNotes = true;
                                                    props.setUplaodedProducts([...props.uploadedProducts]);
                                                }}
                                            >
                                                {!isblank(product.notes) ? product.notes : '-'}
                                            </span>
                                        )}

                                    </th>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex align-items-center gap-2">
                <button className="btn btn-outline-custom-primary rounded-circle d-flex align-item-center justify-content-center"
                    onClick={() => {
                        props.setPreviewProducts(false);
                    }}
                    style={{ width: 35, height: 35 }}>
                    <i class="fa fa-arrow-left p-0"></i>
                </button>
                <button className="btn btn-custom-primary rounded-pill" disabled={productsNeedsAttentionCount} onClick={props.hanldeSubmitBulkUpload}>
                    Submit
                </button>
            </div>
        </div>
    );
});

export default UploadedProductsPreview;
