import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import VideoPlayer from "src/componenets/VideoPlayer";
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@mui/material';
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useSelector } from "react-redux";
import { getHelp } from "src/plugins/redux/reducers/UsersReducers";
import toast from "react-hot-toast";


export default function GetHelp() {

    const dispatch = useDispatch();
    const currentUser = useSelector(currentUserSelector);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const onSubmit = async (data : any) => {
        console.log(data);
        // dispatch(getHelp(data));
        const response = await dispatch(getHelp(data));
        if( response.payload.success){

            toast.success("Question submitted!");
            setValue("message", "");
        }else{

            toast.error(response.payload);
        }
           
    };


    useEffect(() => {
        dispatch(setHeaderTitle('Get Help'));
        dispatch(setHeaderActionBtns([]));
        dispatch(setHeaderArrowBack(false));
        dispatch(setShowSearch(false));
    }, [])

    return (
        <div>
            <h1 className="mb-5">We're here to help</h1>
            {currentUser?.role_id == 3 &&
                <>
                    <h3 class="mb-5">Need a quick refresh on how to use BevBridge?</h3>
                    <section className="mb-5">
                        <h2 class="mb-3">Watch the BevBridge intro video</h2>
                        <div style={{ height: 450 }}>
                            <VideoPlayer fluid={false} autoPlay={false} height={'100%'} />
                        </div>
                    </section>
                </>}

            <section className="mt-5 row align-items-center justify-content-center">
                {currentUser?.role_id == 3 && <h3 className="mb-5 col-12 ">Need help with something else?</h3>}
                <form className="col-12 col-md-10 col-lg-6" onSubmit={handleSubmit(onSubmit)}>
                    <h2>Contact BevBridge</h2>
                    <TextField
                        label="First name"
                        {...register('first_name', { required: true })}
                        InputProps={{ sx: { borderRadius: "50px" } }}
                        readonly
                        value={currentUser?.first_name}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Last name"
                        {...register('lastn_name', { required: true })}
                        readonly
                        InputProps={{ sx: { borderRadius: "50px" } }}
                        value={currentUser?.last_name}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        {...register('email', { required: true })}
                        readonly
                        InputProps={{ sx: { borderRadius: "50px" } }}
                        value={currentUser?.email}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Company"
                        {...register('company', { required: true })}
                        value={currentUser?.company_name}
                        readonly
                        InputProps={{ sx: { borderRadius: "50px" } }}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="How can we help you?"
                        {...register('message', { required: true })}
                        fullWidth
                        multiline
                        InputProps={{ sx: { borderRadius: "25px" } }}
                        rows={4}
                        error={errors.message}
                        helperText={errors.message ? 'This field is required' : ''}
                        margin="normal"
                    />
                    <button type="submit" className="btn btn-custom-primary rounded-pill w-100">
                        Get Help
                    </button>
                </form>
            </section>
        </div>

    );

}