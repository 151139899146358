import { forwardRef } from "react";
import selectStyles from "src/themes/Helpers";
import { useEffect } from "react";
import {
  categoryTypes,
  categorySubTypes,
  poursOptions,
  selectTypes,
  selectSubTypes,
  selectPours,
  fetchCategories,
  selectCategories,
} from "src/plugins/redux/reducers/CategoriesReducer";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProducers,
  fetchProducers,
} from "src/plugins/redux/reducers/DropdownDataReducer";
import { useParams } from "react-router-dom";
import { Select, MenuItem, FormControl, InputLabel, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const ProductsFilterRFPs = forwardRef((props: any, ref: any): JSX.Element => {
  const filters = props.filters;
  const setFilters = props.setFilters;
  const renderType = props?.type;
  const renderSubType = props?.subType;
  const customSelectStyles = selectStyles.filterSelectStyles;
  const dispatch = useDispatch();
  const types = useSelector(selectTypes);
  const varietals = useSelector(selectSubTypes);
  const poursOption = useSelector(selectPours);

  const filteredTypes = types.filter(
    (item) => parseInt(item.category_id) === filters?.category_id
  );
  const filteredVarietals = varietals.filter(
    (item) => parseInt(item.category_type_id) === filters?.type_id
  );

  useEffect(() => {
    dispatch(categoryTypes());
    dispatch(categorySubTypes());
    dispatch(fetchProducers());
    dispatch(fetchCategories());
    dispatch(poursOptions());
  }, []);

  const { id } = useParams();

  return (
    <div className="d-flex align-items-center justify-content-between flex-warap  mb-5">
      <div className="d-flex align-items-center justify-content-start gap-1 flex-warap">
        <div>
          {renderType && (
            <FormControl>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                label="Type"
                defaultValue={
                  filters?.type_id === undefined ? "" : filters?.type_id
                }
                onChange={(event) => {
                  const typeValue = event.target.value;
                  setFilters((prev: any) => ({
                    ...prev,
                    type_id: typeValue,
                  }));
                }}
                sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
              >
                {filteredTypes &&
                  filteredTypes?.map(
                    (type: any, index: any) => {
                      if (index === filteredTypes.length - 1) {
                        return [
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>,
                          <MenuItem key={'other'} value={'other'}>
                            Other
                          </MenuItem>,
                        ];
                      } else {
                        return (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        );
                      }
                    }
                  ).flat()
                }
              </Select>
            </FormControl>
          )}
        </div>
        <div>
          {renderSubType && (
            <FormControl>
              <InputLabel id="varietal-select-label">
                Varietal / Sub-Type
              </InputLabel>
              <Select
                labelId="varietal-select-label"
                id="varietal-select"
                label="Varietal / Sub-Type"
                value={
                  filters?.varietal_id === undefined ? "" : filters?.varietal_id
                }
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  setFilters((prev) => ({
                    ...prev,
                    varietal_id: selectedOption,
                  }));
                }}

                sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
              >
                {filteredVarietals &&
                  filteredVarietals.map((varietal) => (
                    <MenuItem key={varietal.id} value={varietal.id}>
                      {varietal.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div>
          {filters?.category_id && filters?.category_id != 4 && (
            <FormControl>
              <InputLabel id="pours-select-label">Placement</InputLabel>
              <Select
                labelId="pours-select-label"
                id="pours-select"
                label="Placement"
                value={filters?.pour_id === undefined ? "" : filters?.pour_id}
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  setFilters((prev) => ({
                    ...prev,
                    pour_id: selectedOption,
                  }));
                }}
                sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
              >
                {poursOption &&
                  poursOption.filter((pour) => {
                    return pour.category_id === filters?.category_id;
                  })?.map((pours) => (
                    <MenuItem key={pours.id} value={pours.id}>
                      {pours.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div>
        {filters?.category_id && (
          <div>
            <IconButton color="default" onClick={() => setFilters("")}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
});

export default ProductsFilterRFPs;
