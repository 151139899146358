import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchProducts,
    selectTrash,
    selectProductsStatus,
    restoreProduct
} from "src/plugins/redux/reducers/ProductsReducer";
import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import ProductsFilter from "./ProductsFilter";
import toast from "react-hot-toast";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import SupplierUnlockPremium from "src/componenets/SupplierUnlockPremium";
import SupplierDummyProducts from "src/componenets/SupplierDummyProducts";

const ProductsTrash = forwardRef((props: any, ref: any): JSX.Element => {

    const dispatch = useDispatch();
    const fethcedProducts = useSelector(selectTrash);
    console.log(fethcedProducts);
    const [products, setProducts] = useState([]);
    const status = useSelector(selectProductsStatus);
    const [filters, setFilters] = useState({});
    const [sortBy, setSortBy] = useState();
    const [sortType, setSortType] = useState("DESC");
    const searchValue = useSelector((state) => state.header.search);
    const currentUser = useSelector(currentUserSelector);

    useEffect(() => {
        dispatch(setHeaderTitle("Trash"));
        dispatch(
            setHeaderActionBtns([
                {
                    link: "/products/create",
                    text: "Add Products",
                },
                {
                    link: "/rfps/find",
                    text: "My RFPs",
                },
            ])
        );
        dispatch(setHeaderArrowBack(false));
        dispatch(setShowSearch(true));
        if (currentUser.is_premium != 0) {
            dispatch(fetchProducts());
        }
    }, [dispatch]);


    useEffect(() => {
        let filteredProducts = [...fethcedProducts];

        for (const key in filters) {
            if (key == 'category_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product) => product.catinfo?.id == filters[key]);
            } if (key == 'category_type_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product) => product.categoryTypeInfo?.id == filters[key]);
            } if (key == 'category_sub_type_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product) => product.categorySubTypeInfo?.id == filters[key]);
            }
        }

        if (searchValue && searchValue != '') {
            filteredProducts = filteredProducts?.filter((product) => {
                const productName = product.name.toLowerCase();
                const category = product.catinfo?.name.toLowerCase();
                const categoryType = product.categoryTypeInfo?.name.toLowerCase();
                const subType = product.categorySubTypeInfo?.name.toLowerCase();
                const producer = product.producerInfo?.name.toLowerCase();

                const searchLowercase = searchValue.toLowerCase();

                return (
                    productName?.includes(searchLowercase) ||
                    category?.includes(searchLowercase) ||
                    categoryType?.includes(searchLowercase) ||
                    subType?.includes(searchLowercase) ||
                    producer?.includes(searchLowercase)
                );
            });
        }
        setProducts(filteredProducts);
    }, [filters, searchValue]);


    useEffect(() => {
        setProducts(fethcedProducts)
    }, [fethcedProducts]);

    const handleRestoreProduct = async (id) => {
        const response = await dispatch(restoreProduct(id));
        if (response?.payload?.success) {
            toast.success('Product Restored');
            dispatch(fetchProducts());
        } else {
            toast.error('An Error occurred Please Try again');
        }
    }

    const handleTypeSort = (columnName: string) => {
        const sortedProducts = [...products].sort((a, b) => {
            let comparison = 0;

            if (columnName === "name") {
                comparison = compareString(a.name, b.name);
            } else if (columnName === "category") {
                comparison = compareString(a.catinfo?.name, b.catinfo?.name);
            } else if (columnName === "type") {
                comparison = compareString(a.categoryTypeInfo?.name, b.categoryTypeInfo?.name);
            } else if (columnName === "subType") {
                comparison = compareString(a.categorySubTypeInfo?.name, b.categorySubTypeInfo?.name);
            } else if (columnName === "producer") {
                comparison = compareString(a.producerInfo?.name, b.producerInfo?.name);
            }

            return sortType === "DESC" ? comparison : -comparison;
        });

        setSortBy(columnName);
        setSortType((current) => (current === "ASC" ? "DESC" : "ASC"));
        setProducts(sortedProducts);
    };

    const compareString = (a: string | undefined, b: string | undefined) => {
        if (a === null || a === undefined || a === "") {
            return b === null || b === undefined || b === "" ? 0 : 1;
        }
        if (b === null || b === undefined || b === "") {
            return -1;
        }
        return a.localeCompare(b);
    };



    return (
        <div className="row">
            {currentUser.is_premium == 0 &&
                <SupplierUnlockPremium />
            }
            <ProductsFilter
                type={filters?.category_id}
                subType={filters?.category_type_id}
                filters={filters}
                setFilters={setFilters}
                setSortBy={setSortBy}
            />
            <div className="col-12 col-md-12">
                <div className="card mb-4 rounded-pill bg-custom-secondary">
                    <div className="row p-5">
                        <div className="col-2 col-md-2 text-center text-white"
                            onClick={() => handleTypeSort("name")}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            Product Name
                            {sortBy == 'name' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white cursor-pointer" onClick={() => handleTypeSort("producer")}>
                            Producer
                            {sortBy == 'producer' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white cursor-pointer" onClick={() => handleTypeSort("category")}>
                            Category
                            {sortBy == 'category' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handleTypeSort("type")}
                        >
                            Type
                            {sortBy == 'type' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handleTypeSort("subType")}>
                            Varietal / Sub-Type
                            {sortBy == 'subType' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white">Actions</div>
                    </div>
                </div>

                {products?.length == 0 && status == 'idle' && currentUser.is_premium != 0 && (
                    <div
                        className="d-flex flex-column gap-2 align-items-center justify-content-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <strong className="text-center fs-2 text-muted">
                            Since you have just registered, you need to add your program(s) so
                            that you can get started.
                        </strong>
                        <Link
                            className="btn btn-custom-primary btn-pill"
                            to={"/products/create"}
                        >
                            Add New Product
                        </Link>
                    </div>
                )}
                {products?.length > 0 && products.map((product: any, key: any) => (
                    <div
                        key={key}
                        className="card mb-4 bg-white border border-secondary rounded-lg"
                    >
                        <div className="row p-5 align-items-center text-custom-primary">
                            <div className="col-2 col-md-2 text-center">{product.name}</div>
                            <div className="col-2 col-md-2 text-center">
                                {product.producerInfo?.name}
                            </div>
                            <div className="col-2 col-md-2 text-center">
                                {product.catinfo?.name ?? "Category"}
                            </div>
                            {product.categoryTypeInfo?.name ?
                                <div className="col-2 col-md-2 text-center">
                                    {product.categoryTypeInfo?.name}
                                </div>
                                :
                                <div className="col-2 col-md-2 text-center">
                                    -
                                </div>
                            }
                            {product.categorySubTypeInfo?.name ?
                                <div className="col-2 col-md-2 text-center">
                                    {product.categorySubTypeInfo?.name}
                                </div>
                                :
                                <div className="col-2 col-md-2 text-center">
                                    -
                                </div>
                            }
                            <div className="col-2 col-md-2 text-center d-flex align-items-center justify-content-center">
                                <span className="cursor-pointer" onClick={() => {
                                    handleRestoreProduct(product?.id);
                                }}>
                                    <i className="fa-solid fa-trash-can-arrow-up text-primary-blue"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                {currentUser.is_premium == 0 && <SupplierDummyProducts />}
                {status === "loading" && (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <div className="spinner-border text-custom-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {status === "error" && (
                    <div
                        className="d-flex flex-column gap-2 align-items-center justify-content-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <strong className="text-center fs-1 text-custom-primary">
                            Error occurred while fetching items.
                        </strong>
                        <button
                            className="btn btn-custom-primary btn-pill"
                            onClick={() => dispatch(fetchProducts() as any)}
                        >
                            <i className="fa fa-redo-alt" />
                            Retry
                        </button>
                    </div>
                )}
            </div>
        </div >
    );
});

export default ProductsTrash;
