import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch } from 'src/plugins/redux/reducers/HeaderReducer';
import { Link, useLocation } from 'react-router-dom';
import { fetchOperators, selectOperators, deleteCompany } from "src/plugins/redux/reducers/AdminReducers";
import AddEditOperatorRepModal from "./includes/AddEditOperatorRepModal";
import AddEditOperatorModal from "./includes/AddEditOperatorModal";
import Stack from "@mui/material/Stack";
import Tooltip from '@mui/material/Tooltip';
import { loginAs } from 'src/plugins/redux/reducers/AuthReducer';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';

interface Operator {
    id: number;
    name: string;
    logo_file: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    agency_id: string;
}



export default function () {
    const { state } = useLocation()
    const dispatch = useDispatch();
    const operators = useSelector(selectOperators);
    const [selectedCompany, setSelectedCompany] = useState({} as Operator);
    const searchValue = useSelector((state) => state.header.search);
    const [filteredOperators, setFilteredOperators] = useState(operators);
    const [operatorRepModal, setOperatorRepModal] = useState(false);
    const [operatorModal, setOperatorModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const handleShowDeleteModal = () => setDeleteModal(true);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const toggleOperatorRepModal = () => setOperatorRepModal(!operatorRepModal);
    const toggleOperatorModal = () => setOperatorModal(!operatorModal);

    console.log(operators);



    useEffect(() => {
        dispatch(setHeaderTitle('Operators'));
        dispatch(setHeaderActionBtns([]));
        dispatch(setShowSearch(true));
        dispatch(setHeaderArrowBack(false));
        dispatch(fetchOperators());
    }, []);

    useEffect(() => {
        if (searchValue) {
            setFilteredOperators(operators.filter((operator: Operator) => {
                return operator.name.toLowerCase().includes(searchValue.toLowerCase()) || operator.agency_name?.toLowerCase().includes(searchValue.toLowerCase());
            }));
        } else {
            setFilteredOperators(operators);
        }
    }, [searchValue, operators]);

    const handleLoginAsOperator = async (id: number) => {
        const response = await dispatch(loginAs(id));
    }

    const handleDeleteOperator = async () => {
        const response = await dispatch(deleteCompany(selectedCompany.id));
        console.log(response);
        if (response.payload?.success) {
            toast.success('Operator deleted successfully');
            dispatch(fetchOperators());
        } else {
            toast.error('Failed to delete agency');
        }
        handleCloseDeleteModal();
    }


    return (
        <div>
            <div className="card mb-4 bg-custom-secondary rounded-pill mt-5">
                <div className="row p-5">
                    <div className="col-3 text-center text-white">Logo</div>
                    <div className="col-3 text-center text-white">Operator Company Name</div>
                    <div className="col-3 text-center text-white">Agency Relationship</div>
                    <div className="col-3 text-center text-white">Actions</div>
                </div>
            </div>
            {filteredOperators && filteredOperators.map((operator: Operator) => {
                return (
                    <div className="card border-secondary mb-4 rounded-xl">
                        <div className="row p-5 align-items-center text-center">
                            <div className="col-3">
                                <div><img onError={(e) => { e.target.onerror = null; e.target.src = '../images/BevBridgeRFPLogo.png' }}
                                    src={operator.logo_file ? operator.logo_file : '../images/BevBridgeRFPLogo.png'} width="56" /></div>
                            </div>
                            <div className="col-3">
                                <div>{operator.name}</div>
                            </div>
                            <div className="col-3">
                                <div>{operator.agency_name}</div>
                            </div>
                            <div className="col-3 d-flex align-items-center justify-content-center gap-2">
                                <Tooltip title="Add Rep">
                                    <i class="fa fa-user-plus text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(operator)
                                        toggleOperatorRepModal()
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="View Reps">
                                    <Link className="cursor-pointer" to={`/admin/operators/rep/${operator.id}`}>
                                        <i class="fa fa-users text-primary-blue"></i>
                                    </Link>
                                </Tooltip>
                                <Tooltip title="Edit Operator">
                                    <i className="fa fa-edit text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(operator)
                                        toggleOperatorModal()
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="Login as Operator" arrow>
                                    <i className="fa fa-right-to-bracket text-primary-blue cursor-pointer" onClick={() => {
                                        handleLoginAsOperator(operator.id);
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="Delete Operator" arrow>
                                    <i className="fa fa-times text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(operator);
                                        handleShowDeleteModal();
                                    }}></i>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                );
            })}

            {filteredOperators.length === 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                <div className="d-flex align-items-center my-5 flex-column">
                    <h4 className="mb-3">No Operators Found</h4>
                    <button type="button" className="btn btn-outline-custom-primary rounded-pill" onClick={() => {
                        setSelectedCompany({} as Operator);
                        toggleSupplierModal();
                    }}>
                        Add Operator
                    </button>
                </div>
            </Stack>}

            <Dialog
                open={deleteModal}
                onClose={handleCloseDeleteModal}>
                <DialogTitle>
                    Are you sure you want to delete this operator?
                </DialogTitle>
                <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <button className="btn btn-outline-custom-primary btn-pill" onClick={handleCloseDeleteModal}>Close</button>
                    <button className="btn btn-danger btn-pill" onClick={handleDeleteOperator}>Delete</button>
                </div>
            </Dialog>
            <AddEditOperatorRepModal isOpen={operatorRepModal} toggle={toggleOperatorRepModal} company={selectedCompany} />
            <AddEditOperatorModal isOpen={operatorModal} toggle={toggleOperatorModal} operator={selectedCompany} />
        </div>
    );

}
