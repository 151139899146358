import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { forwardRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  deleteFavorite,
  fetchFavorites,
  fetchItem,
  fetchRfpProducts,
  selectCurrentItem,
  selectFavorites,
  selectRfpProducts,
  selectStatus,
} from "src/plugins/redux/reducers/RFPsReducer";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import Papa from "papaparse";
import { useForm } from "react-hook-form";
import ProductsFilterModal from "./productFiltersModal";
import toast from "react-hot-toast";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";
import formatDate from "src/componenets/DateFormat";


const ProductsShortList = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const fetchedProducts = useSelector(selectRfpProducts);
  const status = useSelector(selectStatus);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState("ASC");
  const [products, setProducts] = useState([]);
  const rfp = useSelector(selectCurrentItem);
  const favorites = useSelector(selectFavorites);
  const [columnToSort, setColumnToSort] = useState("");
  const currentUser = useSelector(currentUserSelector);
  const [supplier, setSupplier] = useState();

  useEffect(() => {
    setProducts(fetchedProducts);
  }, [fetchedProducts])

  function convertSize(size, price) {
    const ozRegex = /(\d+(\.\d+)?) oz/;
    const mlRegex = /(\d+(\.\d+)?) ml/;
    const literRegex = /(\d+(\.\d+)?) L/;
    const GRegex = /(\d+(\.\d+)?) G/;
    const kegRegex = /Keg - (1\/2|1\/4|1\/6|Euro Keg) Barrel/;

    const ozMatch = size?.match(ozRegex);
    const mlMatch = size?.match(mlRegex);
    const literMatch = size?.match(literRegex);
    const kegMatch = size?.match(kegRegex);
    const GMatch = size?.match(GRegex);

    if (ozMatch) {
      const prices = price / parseFloat(ozMatch[1]);
      return `${prices.toFixed(2)} $`;
    } else if (mlMatch) {
      const mlValue = parseFloat(mlMatch[1]);
      const ozValue = mlValue / 29.5741324921;
      const prices = price / ozValue;
      return `${prices.toFixed(2)} $`;
    } else if (literMatch) {
      const literValue = parseFloat(literMatch[1]);
      const ozValue = literValue * 33.814;
      const prices = price / ozValue;
      return `${prices.toFixed(2)} $`;
    } else if (GMatch) {
      const GValue = parseFloat(GMatch[1]);
      const ozValue = GValue * 128;
      const prices = price / ozValue;
      return `${prices.toFixed(2)} $`;
    } else if (kegMatch) {
      const kegSize = kegMatch[1];
      let kegPrices;
      if (kegSize === "1/2") {
        kegPrices = 1984;
      } else if (kegSize === "1/4") {
        kegPrices = 992;
      } else if (kegSize === "1/6") {
        kegPrices = 661;
      } else if (kegSize === "Euro Keg") {
        kegPrices = 1690;
      }
      if (kegPrices !== undefined) {
        const prices = price / kegPrices;
        return `${prices.toFixed(2)} $`;
      }
    }

    return "-";
  }
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, dirtyFields },
    getValues,
    control,
    setError,
    setValue,
  } = useForm({});
  const [refreshProducts, setRefreshProducts] = useState(false);
  useEffect(() => {
    dispatch(
      fetchItem({
        id: id,
      })
    );
    if (currentUser?.role_id == 2) {
      dispatch(
        setHeaderActionBtns([
          {
            link: "/create",
            text: "Add New RFP",
          },
          {
            link: `/rfp/products/favorites-list/${id}`,
            text: "Favorites",
          }, ,

        ])
      );
    } else if (currentUser?.role_id == 4) {
      dispatch(
        setHeaderActionBtns([
          {
            link: `/rfp/products/favorites-list/${id}`,
            text: "Favorites",
          }, ,

        ])
      );
    } else {
      dispatch(setHeaderActionBtns([]));
    }
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
    dispatch(fetchRfpProducts({ rfp_id: id }));
    dispatch(fetchFavorites(id));
  }, []);


  useEffect(() => {
    if (rfp.rfp_title) {
      dispatch(setHeaderTitle(`${rfp?.rfp_title}`));
    }
  }, [rfp]);

  const extractNumericalValue = (str) => {
    if (str) {
      const numericalValue = parseFloat(str.match(/\d+(\.\d+)?/));
      return isNaN(numericalValue) ? 0 : numericalValue;
    } else {
      return 0;
    }
  };

  const handleTypeSort = (columnName: any, numeric: boolean = false) => {
    const sortedProducts = [...products].sort((a, b) => {
      if (numeric) {
        if (columnName == 'productSizeInfo') {
          if (sortType === 'ASC') {
            return extractNumericalValue(a[columnName][0]?.name) - extractNumericalValue(b[columnName][0]?.name);
          } else {
            return extractNumericalValue(b[columnName][0]?.name) - extractNumericalValue(a[columnName][0]?.name);
          }
        } else if (sortType === 'ASC') {
          return extractNumericalValue(a[columnName]) - extractNumericalValue(b[columnName]);
        } else {
          return extractNumericalValue(b[columnName]) - extractNumericalValue(a[columnName]);
        }
      } else {
        let valueA;
        let valueB;
        if (columnName == 'catinfo' || columnName == 'categoryTypeInfo' || columnName == 'categorySubTypeInfo' || columnName == 'itemPourInfo' || columnName == 'supplierInfo') {
          valueA = a[columnName]?.name?.toUpperCase();
          valueB = b[columnName]?.name?.toUpperCase();
        } else {
          valueA = a[columnName]?.toUpperCase();
          valueB = b[columnName]?.toUpperCase();
        }

        if (sortType === 'ASC') {
          return valueA?.localeCompare(valueB);
        } else {
          return valueB?.localeCompare(valueA);
        }
      }
    });

    setSortBy(columnName);
    setSortType((current) => (current === "ASC" ? "DESC" : "ASC"));
    setColumnToSort(columnName);
    setProducts(sortedProducts);
  }

  const exportToCsv = () => {
    var productsLocal = [];
    products.map((product) => {
      let unitSizes = "";

      if (product.productSizeInfo && product.productSizeInfo.length > 0) {
        unitSizes = product.productSizeInfo
          .filter(size => size?.name != null && size?.name !== '')
          .map(size => size.name)
          .join(", ");
      }

      const product_sell_sheets = product.assets?.find((asset) => asset.assets_type == 'sell_sheets')?.asset_file;
      const image_front = product.assets?.find((asset) => asset.assets_type == 'product_image_front')?.asset_file;
      const image_back = product.assets?.find((asset) => asset.assets_type == 'product_image_back')?.asset_file;
      const label_front = product.assets?.find((asset) => asset.assets_type == 'label_image_front')?.asset_file;
      const label_back = product.assets?.find((asset) => asset.assets_type == 'label_image_back')?.asset_file;
      const recipes = product.recipes?.map((recipe) => { return recipe.asset_file })?.join(',');
      const logos = product.assets?.find((asset) => asset.assets_type == 'logos')?.asset_file;

      productsLocal.push({
        Category: product.catinfo ? product.catinfo.name : "",
        "Product Name": product.name ? product.name : "",
        "Product Description": product.description ? product.description : '',
        Supplier: product.supplierInfo ? product.supplierInfo.name : "",
        Producer: product.producerInfo ? product.producerInfo.name : "",
        Type: product.categoryTypeInfo ? product.categoryTypeInfo.name : "",
        "Varietal/Sup-Type": product.categorySubTypeInfo
          ? product.categorySubTypeInfo.name
          : "",
        Placement: product.itemPourInfo ? product.itemPourInfo.name : "",
        'ABV %': product.abv ? product.abv : "",
        "Prominent Flavors": product.prominentFlavorsInfo ? product.prominentFlavorsInfo?.map((flavor) => { if (flavor?.name) { return flavor.name } }).join(',') : "",
        "Country of Origin": product.countyOfOriginInfo ? product.countyOfOriginInfo?.name : "",
        "Region / Appellation": product.regionsInfo ? product.regionsInfo?.name : "",
        "Unit Size": product.productSizeInfo ? product.productSizeInfo[0]?.name : "",
        "Item Closure": product.itemClosureInfo
          ? product.itemClosureInfo.name
          : "",
        "National Average Unit Price - Case 1": product.national_pricing ? product.national_pricing : "",
        "Price/Ounce": product.price_per_ounce ? product?.price_per_ounce : "",
        "Units per Case": product.units_per_case ? product?.units_per_case : "",
        "Other Available Sizes": unitSizes ? unitSizes : "",
        "Product Sell Sheet": product_sell_sheets ? product_sell_sheets : '',
        "Product Image (front)": image_front ? image_front : '',
        "Product Image (back)": image_back ? image_back : '',
        "Label Image (front)": label_front ? label_front : '',
        "Label Image (back)": label_back ? label_back : '',
        "Recipes": recipes ? recipes : '',
        "Logos": logos ? logos : '',
        "Video Links": product.videos ? product.videos : '',
        "Awards / Accolades": product.awards ? product.awards : '',
        "Notes, if any": product.notes ? product.notes : '',
      });
    });
    var csv = Papa.unparse(productsLocal);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = window.URL.createObjectURL(csvData);

    var testLink = document.createElement("a");
    testLink.href = csvURL;
    testLink.setAttribute("test", "test.csv");
    testLink.click();
  };

  const handleFilterProducts = (data: any) => {

    console.log(data);
    let filteredProducts = products;
    if (data.supplier_id) {
      filteredProducts = filteredProducts.filter((product) => product.supplierInfo?.id == data.supplier_id);
    }
    if (data.producer_id) {
      filteredProducts = filteredProducts.filter((product) => product.producerInfo?.id == data.producer_id);
    }
    if (data.category_id) {
      filteredProducts = filteredProducts.filter((product) => product.catinfo?.id == data.category_id);
    }
    if (data.category_type_id) {
      filteredProducts = filteredProducts.filter((product) => product.categoryTypeInfo?.id == data.category_type_id);
    }
    if (data.category_sub_type_id) {
      filteredProducts = filteredProducts.filter((product) => product.categorySubTypeInfo?.id == data.category_sub_type_id);
    }
    if (data.item_closure_id) {
      filteredProducts = filteredProducts.filter((product) => product.itemClosureInfo?.id == data.item_closure_id);
    }
    if (data.pour_id) {
      filteredProducts = filteredProducts.filter((product) => product.itemPourInfo?.id == data.pour_id);
    }
    if (data.sizes) {
      console.log(data.sizes)
      filteredProducts = filteredProducts.filter(product =>
        product.productSizeInfo.some(size => data.sizes?.map((size) => { return size.value }).includes(size.id))
      );
    }
    if (data.min_abv || data.max_abv) {
      filteredProducts = filteredProducts.filter((product) => {
        const abv = extractNumericalValue(product.abv);
        return (data.min_abv ? abv >= data.min_abv : true) && (data.max_abv ? abv <= data.max_abv : true);
      });
    }
    if (data.min_price || data.max_price) {
      filteredProducts = filteredProducts.filter((product) => {
        const price = extractNumericalValue(product.national_pricing);
        return (data.min_price ? price >= data.min_price : true) && (data.max_price ? price <= data.max_price : true);
      });
    }
    if (data.min_price_ounce || data.max_price_ounce) {
      filteredProducts = filteredProducts.filter((product) => {
        const priceOunce = extractNumericalValue(product.price_per_ounce);
        return (data.min_price_ounce ? priceOunce >= data.min_price_ounce : true) && (data.max_price_ounce ? priceOunce <= data.max_price_ounce : true);
      });
    }

    setProducts(filteredProducts);
    setShowFiltersModal(false);
  };


  const handleClick = async (product_id: any, rfp_id: any) => {
    console.log("handleClick");

    const response = await dispatch(
      addFavorite({
        product_id: product_id,
        rfp_id: rfp_id,
      })
    );
    if (response.payload.success === "Add.") {
      dispatch(fetchFavorites(id));
      toast.success("Product added to Favorites");
    } else {
      toast.error("Product is already in Favorites");
    }
  };

  const isFavorite = (productId: any) => {
    const is = favorites.some(
      (favorite: any) => favorite.product_id === productId
    );
    return is;
  };

  const handleDeleteFav = async (Id: any) => {
    try {
      const uid = favorites.find((fav: any) => fav.product_id === Id);
      const response = await dispatch(deleteFavorite(uid?.id));
      if (response.payload.success) {
        dispatch(fetchFavorites(id));
        toast.success("Product is deleted from Favorites");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearAll = () => {
    reset({ 'supplier_id': '', 'category_id': '', 'producer_id': '', 'category_type_id': '', 'sizes': '', 'category_sub_type_id': '', 'item_closure_id': '', 'pour_id': '' });
    setProducts(fetchedProducts);
  }

  return (
    <div>
      <form onSubmit={handleSubmit(handleFilterProducts)}>
        <ProductsFilterModal
          products={products}
          showFiltersModal={showFiltersModal}
          setShowFiltersModal={setShowFiltersModal}
          register={register}
          control={control}
          clearAll={clearAll}
          onSubmit={handleSubmit(handleFilterProducts)}
          supplier={supplier}
          setSupplier={setSupplier}
        />
      </form>
      <div className="d-flex align-items-center justify-content-between m-3">
        <button
          className="btn btn-outline-custom-primary btn-pill d-flex align-items-center justify-conten-center gap-1"
          onClick={() => setShowFiltersModal(true)}
        >
          All Filters
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.99969 2H12.9997L7.98969 8.3L2.99969 2ZM0.24969 1.61C2.26969 4.2 5.99969 9 5.99969 9V15C5.99969 15.55 6.44969 16 6.99969 16H8.99969C9.54969 16 9.99969 15.55 9.99969 15V9C9.99969 9 13.7197 4.2 15.7397 1.61C16.2497 0.95 15.7797 0 14.9497 0H1.03969C0.20969 0 -0.26031 0.95 0.24969 1.61Z" fill="#6A7682" />
          </svg>
        </button>
        <button
          type="button"
          className="btn btn-pill btn-outline-custom-primary font-weight-bold"
          onClick={() => exportToCsv()}
        >
          Export CSV
          <i className="la la-upload"></i>
        </button>
      </div>
      <div className="d-flex justify-content-end m-3">

      </div>
      <div
        style={{ "max-height": "70vh", "overflow-y": "auto", minWidth: '1300px', 'border-top-left-radius': '3rem', 'border-top-right-radius': '3rem' }}
        className="custom-scroll"
      >
        <table className="table submissions-table rounded-top-pill table-striped table-lg text-center justify-content-center" style={{ 'overflow': 'auto !important', 'min-width': 'max-content' }}>
          <thead class="bg-custom-secondary">
            <tr className="text-white">
              <th
                style={{ minWidth: "100px", width: "100px", cursor: "pointer", 'border-top-left-radius': '2rem' }}
                onClick={() => handleTypeSort("supplierInfo")}
              >
                Supplier
                {columnToSort === 'supplierInfo' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("name")}
                style={{ minWidth: "100px", cursor: "pointer", width: "100px" }}
              >
                Product Name
                {columnToSort === 'name' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("catinfo")}
                style={{ cursor: "pointer", width: "100px" }}
              >
                Category
                {columnToSort === 'catinfo' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("categoryTypeInfo")}
                style={{ cursor: "pointer", width: "90px" }}
              >
                Type
                {columnToSort === 'categoryTypeInfo' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("categorySubTypeInfo")}
                style={{ minWidth: "100px", width: "120px", cursor: "pointer" }}
              >
                Varietal/Sub-Type
                {columnToSort === "categorySubTypeInfo" &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("itemPourInfo")}
                style={{ cursor: "pointer" }}
              >
                Placement
                {columnToSort === "itemPourInfo" &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort("productSizeInfo", true)} style={{ cursor: "pointer" }}>
                Unit Size
                {columnToSort === 'productSizeInfo' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort("abv", true)} style={{ cursor: "pointer" }}>
                ABV
                {columnToSort === 'abv' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort("national_pricing", true)} style={{ cursor: "pointer" }}>
                Unit Price
                {columnToSort === 'national_pricing' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort('price_per_ounce', true)} style={{ cursor: "pointer" }}>
                Price/Ounce
                {columnToSort === 'price_per_ounce' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th>Last Updated</th>
              <th style={{ 'border-top-right-radius': '2rem' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((product: any, index: number) => (
              <tr>
                <td>
                  {product.supplierInfo ? product.supplierInfo.name : "-"}
                </td>
                <td>{product.name}</td>
                <td>{product.catinfo ? product.catinfo.name : "-"}</td>
                <td>
                  {product.categoryTypeInfo
                    ? product.categoryTypeInfo.name
                    : "-"}
                </td>
                <td>
                  {product.categorySubTypeInfo
                    ? product.categorySubTypeInfo.name
                    : "-"}
                </td>
                <td>
                  {product.itemPourInfo ? product.itemPourInfo.name : "-"}
                </td>
                <td>
                  {product.productSizeInfo
                    ? product.productSizeInfo[0]?.name
                    : "-"}
                </td>
                <td>{product.abv ? `${product.abv} %` : "-"}</td>
                <td>
                  {product.national_pricing ? `$${product.national_pricing}` : "-"}
                </td>
                <td>
                  {product.price_per_ounce ? `$${product.price_per_ounce}` : "-"}
                </td>
                <td>{product.updated_at ? formatDate(product.updated_at) : '-'}</td>
                <td className="d-flex align-items-center">
                  <a
                    target="_blank"
                    className="mr-4"
                    href={`/submission/${product.id}`}
                    state={{ product: product }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4_3167)">
                        <path
                          d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                          fill="#003ACE"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4_3167">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <div
                    onClick={() => {
                      !isFavorite(product.id)
                        ? handleClick(product.id, product.rfp_id)
                        : handleDeleteFav(product.id);
                    }}
                    style={{ cursor: "pointer", marginBottom: '2px' }}
                  >
                    {isFavorite(product.id) ? (
                      <FavoriteIcon sx={{ color: "#003ace" }} />
                    ) : (
                      <FavoriteBorderIcon sx={{ color: "#003ace" }} />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {status === "loading" && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "50vh" }}
        >
          <div className="spinner-border text-custom-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {status === "error" && (
        <div
          className="d-flex flex-column gap-2 align-items-center justify-content-center"
          style={{ minHeight: "50vh" }}
        >
          <strong className="text-center fs-1 text-custom-primary">
            Error occurred while fetching items.
          </strong>
          <button
            className="btn btn-custom-primary btn-pill"
            onClick={() => dispatch(fetchRfpProducts(rfp?.id) as any)}
          >
            <i className="fa fa-redo-alt" />
            Retry
          </button>
        </div>
      )}
    </div>
  );
});

export default ProductsShortList;
