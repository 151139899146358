import axios from 'plugins/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";


interface DropdownDataState {
    prominentFlavors: [];
    pours: [];
    regions: [];
    sizes: [];
    unitsPerCase: [];
    itemClosures: [];
    suppliers: [];
    producers: [];
    contries: [];
    status: 'idle' | 'loading' | 'error';
    error: string | null;
}

const initialState: DropdownDataState = {
    prominentFlavors: [],
    pours: [],
    regions: [],
    sizes: [],
    unitsPerCase: [],
    itemClosures: [],
    suppliers: [],
    producers: [],
    contries: [],
    status: 'idle',
};

/* ProminentFlavors */
export const fetchProminentFlavors = createAsyncThunk('dropdownData/fetchProminentFlavors', async () => {
    const response = await axios.get('/prominentFlavors');
    return response.data;
});

/* regions */
export const fetchRegions = createAsyncThunk('dropdownData/fetchRegions', async () => {
    const response = await axios.get('/regions');
    return response.data;
});

export const fetchUnitSizes = createAsyncThunk('dropdownData/fetchUnitSizes', async (category_id) => {
    let params = {};
    params.category_id = category_id;

    const response = await axios.get('/unitSizes');

    return response.data;
});

export const fetchUnitsPerCase = createAsyncThunk('dropdownData/fetchUnitsPerCase', async () => {
    const response = await axios.get('/unitPerCase');
    return response.data;
});

export const fetchItemClosures = createAsyncThunk('dropdownData/fetchItemClosures', async () => {
    const response = await axios.get('/itemClosures');
    return response.data;
});

export const fetchSuppliers = createAsyncThunk('dropdownData/fetchSuppliers', async () => {
    const response = await axios.get('/suppliers');
    return response.data;
});

export const fetchProducers = createAsyncThunk('dropdownData/fetchProducers', async () => {
    const response = await axios.get('/producers');
    return response.data;
});

export const fetchCountryOfOrigins = createAsyncThunk('dropdownData/fetchCountryOfOrigins', async () => {
    const response = await axios.get('/countyOfOrigin');
    return response.data;
});


export const addDropdownData = createAsyncThunk('dropdownData/addDropdownData', async (data) => {
    const formData = new FormData();
    const name = data.name;
    if (data.category_id) {
        formData.append('category_id', data.category_id)
    }
    formData.append('name', name)
    const response = await axios.post(`${data.type}/create`, formData);
    return response.data;
});

const dropdownDataSlice = createSlice({
    name: 'dropdownData',
    initialState,
    reducers: {
        clearError: (state) => {
            state.status = 'idle';
        },
    },
    extraReducers: (builder) => {

        builder.addCase(fetchProminentFlavors.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
            state.prominentFlavors = action.payload.success;
        });

        builder.addCase(fetchRegions.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
            state.regions = action.payload.success;
        });
        builder.addCase(fetchUnitSizes.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
            state.sizes = action.payload.success;
        });
        builder.addCase(fetchUnitsPerCase.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
            state.unitsPerCase = action.payload.success;
        });

        builder.addCase(fetchItemClosures.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
            state.itemClosures = action.payload.success;
        });

        builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
            state.status = 'idle';
            state.suppliers = action.payload.success;
        });

        builder.addCase(fetchProducers.fulfilled, (state, action) => {
            state.status = 'idle';
            state.producers = action.payload.success;
        });

        builder.addCase(fetchCountryOfOrigins.fulfilled, (state, action) => {
            state.status = 'idle';
            state.countries = action.payload.success;
        });

        builder.addCase(addDropdownData.fulfilled, (state, action) => {
            const { type, data } = action.payload;
            return state;
        });
    }
});


export const selectProminentFlavors = (state: any) => state.dropdownData.prominentFlavors;
export const selectRegions = (state: any) => state.dropdownData.regions;
export const selectSizes = (state: any) => state.dropdownData.sizes;
export const selectUnitsPerCase = (state: any) => state.dropdownData.unitsPerCase;
export const selectItemClosures = (state: any) => state.dropdownData.itemClosures;
export const selectSuppliers = (state: any) => state.dropdownData.suppliers;
export const selectProducers = (state: any) => state.dropdownData.producers;
export const selectCountries = (state: any) => state.dropdownData.countries;
export default dropdownDataSlice.reducer;
