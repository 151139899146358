import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { addClient, updateClient } from "src/plugins/redux/reducers/ClientsReducer";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { fetchOperators, selectOperators } from "src/plugins/redux/reducers/AdminReducers";
import Select from "react-select";
import selectStyles from "src/themes/Helpers";
import { fetchCompanyClients } from "src/plugins/redux/reducers/ClientsReducer";

interface ClientModalProps {
    toggke: () => void;
    modal: boolean;
    client?: any;
}

export default function ClientModal({ toggle, modal, client }: ClientModalProps) {

    const dispatch = useDispatch();
    const operators = useSelector(selectOperators);
    const customSelectStyles = selectStyles.formSelectStyles;

    const [selectedClient, setSelectedClient] = React.useState<any>(null);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setValue("name", client.name);
    }, [client]);


    useEffect(() => {
        dispatch(fetchOperators());
    }, [])



    const onSubmit = async (data: any) => {
        data.client_id = selectedClient?.value;
        if (client?.id) {
            const response = await dispatch(updateClient({ id: client.id, ...data }))
            if (response.payload?.success) {
                toast.success("Client Updated Successfully");
                dispatch(fetchCompanyClients());
                toggle();
            }
        }
        else {
            const response = await dispatch(addClient(data))
            if (response.payload?.success) {
                toast.success("Client Added Successfully");
                dispatch(fetchCompanyClients());
                toggle();
            }
        }
        toggle();
    };

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 24px",
                    }}
                >
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {client?.id ? "Update Client" : "Add Client"}
                    </Typography>
                    <IconButton onClick={toggle}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />

                <ModalBody>
                    <div className="form-group">
                        <label>Client</label>
                        <Select
                            options={operators.map((operator: any) => ({
                                value: operator.id,
                                label: operator.name,
                            }))}
                            value={selectedClient}
                            styles={customSelectStyles}
                            isClearable={true}
                            placeholder=""
                            onChange={(selectedOption: any) => {
                                setSelectedClient(selectedOption);
                                setValue("client_id", selectedOption?.value);
                            }}
                        />
                    </div>
                    <div className="d-flex align-items-center gap-1">
                        <hr className="w-100" />
                        <span className="text-primary flex-shrink-0 cursor-pointer" onClick={() => setSelectedClient(null)}>Add New Client</span>
                        <hr className="w-100" />
                    </div>
                    {!selectedClient && <>
                        <div className="form-group">
                            <label>Client Name*</label>
                            <input
                                {...register("name", { required: true })}
                                type="text"
                                className={"form-control rounded-pill " + (errors.name ? "invalid-input" : "")}
                            />
                            {errors.name && (
                                <small className="text-danger">This field is required</small>
                            )}
                        </div>
                    </>}
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-outline-custom-primary rounded-pill w-100">
                        Submit
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    );
}
