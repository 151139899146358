import axios from 'axios';

export default async function downloadFileFromLink(link) {
    try {
        const fileId = extractFileIdFromLink(link);
        const response = await axios.get(`https://lh3.googleusercontent.com/d/${fileId}=w1000?authuser=0`, { responseType: 'arraybuffer' });
        const extension = extractFileExtension(response.headers['content-type']);
        const file = new File([response.data], `${fileId}.${extension}`, { type: response.headers['content-type'] });
        return file;
    } catch (error) {
        throw error;
    }
}

function extractFileIdFromLink(link) {
    const fileIdRegex = /[-\w]{25,}/;
    const match = link.match(fileIdRegex);
    console.log("🚀 ~ extractFileIdFromLink ~ match:", match)
    if (match) {
        return match[0];
    } else {
        throw new Error('Invalid Google Drive link');
    }
}

function extractFileExtension(mimeType) {
    const extensionRegex = /\/([a-z0-9]+)$/i;
    const match = mimeType.match(extensionRegex);
    if (match) {
        return match[1];
    } else {
        throw new Error('Unable to extract file extension');
    }
}