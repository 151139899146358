import axios from "src/plugins/axios";
import { useParams } from 'react-router-dom';
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState, useEffect } from "react";
import { sendContactEmail } from "src/plugins/redux/reducers/RFPsReducer";
import toast from "react-hot-toast";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ContactFormModal({ showAskModal, setShowAskModal }) {

  const dispatch = useDispatch();
  const [messageSent, setMessageSent] = useState<boolean>(false);

  const user = useSelector(currentUserSelector);
  const { register, handleSubmit, reset, formState: { errors }, control } = useForm();

  const { id } = useParams()

  useEffect(() => {
    if (!showAskModal) {
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) backdrop.remove();
    }
  }, [showAskModal]);

  const handleSendMessage = async (data: any) => {
    const user_id = user?.id.toString();
    data.rfp_id = id;
    data.user_id = user_id;
    const response = await dispatch(sendContactEmail(data))

    if (response.payload.success) {
      reset();
      setShowAskModal(false);
      toast.success("Question submitted.");

    }
  }

  return (
    <Modal id="AskQuestionModal"
      isOpen={showAskModal}
      toggle={() => setShowAskModal(!showAskModal)}
      scrollable={true}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px 24px",
        }}
      >
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Contact Form
        </Typography>
        <IconButton onClick={() => setShowAskModal(!showAskModal)}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <ModalBody >
        <form onSubmit={handleSubmit(handleSendMessage)}>
          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label>Name*</label>
              <input {...register("name", { required: true })} type="text" className="form-control" name="name" placeholder="Name" />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label>Email*</label>
              <input  {...register("email", { required: true })} type="text" className="form-control" name="email" placeholder="Email" />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label>Message</label>
              <textarea  {...register("message", { required: true })} className="form-control" name="message" placeholder="Message" style={{ resize: 'none' }} />
            </div>
          </div>

          {messageSent && <Alert
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" />,
            }}
          >
            Your message has been submitted
          </Alert>}
          <ModalFooter>
            <button type="button" className="btn btn-pill btn-outline-dark font-weight-bold" onClick={() => setShowAskModal(false)}>Cancel</button>
            <button type="submit" className="btn btn-pill btn-dark font-weight-bold">Submit</button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}
