import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch } from 'src/plugins/redux/reducers/HeaderReducer';
import { Link, useLocation } from 'react-router-dom';
import { fetchAgencies, selectAgencies, deleteCompany } from "src/plugins/redux/reducers/AdminReducers";
import AddEditAgencyRepModal from "./includes/AddEditAgencyRepModal";
import AddEditAgencyModal from "./includes/AddEditAgencyModal";
import Stack from "@mui/material/Stack";
import Tooltip from '@mui/material/Tooltip';
import { loginAs } from 'src/plugins/redux/reducers/AuthReducer';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';

interface Agency {
    id: number;
    name: string;
    logo_file: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
}


export default function () {

    const dispatch = useDispatch();
    const agencies: Agency[] = useSelector(selectAgencies);
    const [selectedCompany, setSelectedCompany] = useState<Agency>({} as Agency);
    const [agencyModal, setAgencyModal] = useState<boolean>(false);
    const [agencyRepModal, setAgencyRepModal] = useState<boolean>(false);
    const searchValue = useSelector((state) => state.header.search);
    const [filteredAgencies, setFilteredAgencies] = useState<Agency[]>([]);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const handleShowDeleteModal = () => setDeleteModal(true);
    const handleCloseDeleteModal = () => setDeleteModal(false);


    const toggleAgencyModal = () => {
        setAgencyModal(!agencyModal);
    }

    const toggleAgencyRepModal = () => {
        setAgencyRepModal(!agencyRepModal);
    }


    useEffect(() => {
        dispatch(setHeaderTitle('Agencies'));
        dispatch(setHeaderActionBtns([]));
        dispatch(setShowSearch(true));
        dispatch(setHeaderArrowBack(false));
        dispatch(fetchAgencies());

    }, [])

    useEffect(() => {
        if (searchValue && searchValue != '') {
            setFilteredAgencies(agencies.filter((agency) => {
                return agency.name.toLowerCase().includes(searchValue.toLowerCase());
            }));
        } else {
            setFilteredAgencies(agencies);
        }
    }, [searchValue, agencies])

    const handleLoginAsAgency = async (id: number) => {
        const response = await dispatch(loginAs(id));
    }

    const handleDeleteAgency = async () => {
        const response = await dispatch(deleteCompany(selectedCompany.id));
        console.log(response);
        if (response.payload?.success) {
            toast.success('Agency deleted successfully');
            dispatch(fetchAgencies());
        } else {
            toast.error('Failed to delete agency');
        }
        handleCloseDeleteModal();
    }


    return (
        <div>
            <div className="d-flex align-items-center justify-content-end">
                <button type="button" className="btn btn-outline-custom-primary rounded-pill ml-auto" onClick={() => {
                    setSelectedCompany({} as Agency);
                    toggleAgencyModal();
                }}>
                    Add Agency
                </button>
            </div>
            <div className="card mb-4 bg-custom-secondary rounded-pill mt-5">
                <div className="row p-5">
                    <div className="col-4 text-center text-white">Logo</div>
                    <div className="col-4 text-center text-white">Agency Name</div>
                    <div className="col-4 text-center text-white">Actions</div>
                </div>
            </div>
            {filteredAgencies && filteredAgencies.map((agency: Agency, key) => {
                return (
                    <div className="card border-secondary mb-4 rounded-xl" key={key}>
                        <div className="row p-5 align-items-center text-center">
                            <div className="col-4">
                                <div>
                                    <img onError={(e) => {
                                        e.currentTarget.src = '../images/BevBridgeRFPLogo.png';
                                    }} src={agency.logo_file ? agency.logo_file : '../images/BevBridgeRFPLogo.png'} width="56" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div>{agency.name}</div>
                            </div>
                            <div className="col-4 d-flex align-items-center justify-content-center gap-2">
                                <Tooltip title="Add Rep" arrow>
                                    <i className="fa fa-user-plus text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(agency);
                                        toggleAgencyRepModal(true);
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="View Reps" arrow>
                                    <Link className="cursor-pointer p-0 m-0" to={`/admin/agency/rep/${agency.id}`}>
                                        <i className="fa fa-users text-primary-blue"></i>
                                    </Link>
                                </Tooltip>
                                <Tooltip title="Edit Agency" arrow>
                                    <i className="fa fa-edit text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(agency);
                                        toggleAgencyModal();
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="Login as Agency" arrow>
                                    <i className="fa fa-right-to-bracket text-primary-blue cursor-pointer" onClick={() => {
                                        handleLoginAsAgency(agency.id);
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="Delete Agency" arrow>
                                    <i className="fa fa-times text-primary-blue cursor-pointer" onClick={() => {
                                        setSelectedCompany(agency);
                                        handleShowDeleteModal();
                                    }}></i>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                );
            })}
            {filteredAgencies && filteredAgencies.length == 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                <div className="d-flex align-items-center my-5 flex-column">
                    <h4 className="mb-3">No Agencies Found</h4>
                    <button type="button" className="btn btn-outline-custom-primary rounded-pill" onClick={() => {
                        setSelectedCompany({} as Agency);
                        toggleAgencyModal();
                    }}>
                        Add Agency
                    </button>
                </div>
            </Stack>}

            <Dialog
                open={deleteModal}
                onClose={handleCloseDeleteModal}>
                <DialogTitle>
                    Are you sure you want to delete this agency?
                </DialogTitle>
                <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <button className="btn btn-outline-custom-primary btn-pill" onClick={handleCloseDeleteModal}>Close</button>
                    <button className="btn btn-danger btn-pill" onClick={handleDeleteAgency}>Delete</button>
                </div>
            </Dialog>
            <AddEditAgencyRepModal isOpen={agencyRepModal} toggle={toggleAgencyRepModal} company={selectedCompany} />
            <AddEditAgencyModal isOpen={agencyModal} toggle={toggleAgencyModal} agency={selectedCompany} />
        </div>
    );

}
