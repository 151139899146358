import { createGlobalStyle } from 'styled-components';
import ThemeContract from '../contracts/ThemeContract';

import 'themes/fonts/san-francisco/all.css';
import 'themes/fonts/fontawesome/css/all.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${({theme}: ThemeContract) => theme.colorDefault};
    font-family: 'San Francisco', ui-rounded;
    font-size: 1rem;

    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

export default GlobalStyle;
