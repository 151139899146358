import { forwardRef } from "react";
import selectStyles from "src/themes/Helpers";
import { useEffect } from "react";
import {
  categoryTypes,
  categorySubTypes,
  poursOptions,
  selectTypes,
  selectSubTypes,
  selectPours,
  fetchCategories,
  selectCategories,
} from "src/plugins/redux/reducers/CategoriesReducer";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProducers,
  fetchProducers,
} from "src/plugins/redux/reducers/DropdownDataReducer";
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Filter = forwardRef((props: any, ref: any): JSX.Element => {
  const filters = props.filters;
  const setFilters = props.setFilters;
  const customSelectStyles = selectStyles.filterSelectStyles;
  const dispatch = useDispatch();
  const types = useSelector(selectTypes);
  const varietals = useSelector(selectSubTypes);
  const producers = useSelector(selectProducers);
  const categories = useSelector(selectCategories);
  const poursOption = useSelector(selectPours);
  const renderType = props.type;
  const renderSubType = props.subType;

  const filteredTypes = types.filter(
    (item) => parseInt(item.category_id) === filters?.category_id
  );
  const filteredVarietals = varietals.filter(
    (item) => parseInt(item.category_type_id) === filters?.category_type_id
  );

  useEffect(() => {
    dispatch(categoryTypes());
    dispatch(categorySubTypes());
    dispatch(fetchProducers());
    dispatch(fetchCategories());
    dispatch(poursOptions());
  }, []);
  useEffect(() => { }, [renderType]);

  const { id } = useParams();

  return (
    <div className="d-flex align-items-center justify-content-between flex-warap">
      <div className="d-flex align-items-center justify-content-start gap-1 flex-warap">
        <div>
          <FormControl>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              label="Category"
              id="category-select"
              value={filters?.category_id || ""}
              onChange={(event) => {
                const selectedOption = event.target.value;
                setFilters((prev) => ({
                  ...prev,
                  category_id: selectedOption,
                  category_type_id: "",
                  category_sub_type_id: "",
                  pour_id: "",
                }));
              }}
              // placeholder="Category"
              sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
            >
              {categories?.length > 0 &&
                categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div>
          {renderType && (
            <FormControl>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                size="small"
                labelId="type-select-label"
                id="type-select"
                label="Type"
                value={filters?.category_type_id || ""}
                onChange={(event) => {
                  const typeValue = event.target.value;
                  setFilters((prev) => ({
                    ...prev,
                    category_type_id: typeValue,
                  }));
                }}

                sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
              >
                {filteredTypes &&
                  filteredTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div>
          {renderSubType && (
            <FormControl>
              <InputLabel id="varietal-select-label">
                Varietal / Sub-Type
              </InputLabel>
              <Select
                labelId="varietal-select-label"
                size="small"
                id="subtype-select"
                label="Varietal / Sub-Type"
                value={filters?.category_sub_type_id || ""}
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  setFilters((prev) => ({
                    ...prev,
                    category_sub_type_id: selectedOption,
                  }));
                }}
                // placeholder="Varietal / Sub-Type"
                sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
              >
                {filteredVarietals &&
                  filteredVarietals.map((varietal) => (
                    <MenuItem key={varietal.id} value={varietal.id}>
                      {varietal.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div>
          {filters?.category_id && filters?.category_id != 4 && (
            <FormControl>
              <InputLabel id="pours-select-label">Placement</InputLabel>
              <Select
                size="small"
                label="Placement"
                labelId="pours-select-label"
                id="pours-select"
                value={filters?.pour_id || ""}
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  setFilters((prev) => ({
                    ...prev,
                    pour_id: selectedOption,
                  }));
                }}
                sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
              >
                {poursOption &&
                  poursOption.filter((pour) => {
                    return pour.category_id === filters?.category_id;
                  })?.map((pours) => (
                    <MenuItem key={pours.id} value={pours.id}>
                      {pours.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div>
        {filters?.category_id && (
          <div>
            <IconButton color="default" onClick={() => setFilters("")}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
});

export default Filter;
