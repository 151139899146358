import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'plugins/axios';
import Cookies from "js-cookie";

interface Company {
  id: number;
  name: string;
}

interface CompaniesState {
  companies: companies[];
  agencies: agencies[];
  suppliers: suppliers[];
  operators: operators[]
  currentCompany: {};
  companyUsers: users[];
  status: 'idle' | 'loading' | 'error';
}

const initialState: CompaniesState = {
  companies: [],
  agencies: [],
  suppliers: [],
  operators: [],
  companyUsers: [],
  status: 'idle',
};

export const fetchCompanies = createAsyncThunk('companies/fetchCompanies', async () => {
  const response = await axios.get('/companies');
  return response.data;
});


export const fetchCompany = createAsyncThunk('companies/fetchCompany', async (id: number) => {
  const response = await axios.get(`/companies/${id}`);
  return response.data;
});

export const addCompany = createAsyncThunk('companies/addCompanies', async (data: any, thunkAPI) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post('/companies/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}
)
export const updateCompany = createAsyncThunk('companies/edit', async (data: any, thunkAPI) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.put(`/companies/${data?.id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.data.error
  }
})

export const deleteCompany = createAsyncThunk('companies/delete', async (id: number) => {
  try {
    const response = await axios.delete(`/companies/${id}/delete`);
    return response.data;
  } catch (error: any) {
    return error.response.data.error
  }
});

export const deleteUser = createAsyncThunk('companies/deleteUser', async (id: number) => {
  try {
    const response = await axios.delete(`/users_rep/${id}/delete`);
    return response.data;
  } catch (error: any) {
    return error.response.data.error
  }
});


export const fetchAgencies = createAsyncThunk('companies/fetchAgencies', async () => {

  try {
    const formData = new FormData();
    formData.append('type', 'agency');

    const response = await axios.get('/companies?type=agency', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.data.error
  }
})

export const fetchOperators = createAsyncThunk('companies/fetchOperators', async () => {

  try {
    const formData = new FormData();

    console.log('formData', formData);
    const response = await axios.get('/companies?type=operator', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.data.error
  }
})

export const fetchSuppliers = createAsyncThunk('companies/fetchSuppliers', async () => {
  try {
    const formData = new FormData();
    const response = await axios.get('/companies?type=supplier', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.data.error
  }
})

export const addUser = createAsyncThunk('companies/addUser', async (data) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post('/users_rep/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.data.error
  }
});

export const fetchCompanyUsers = createAsyncThunk('companies/fetchCompanyUsers', async (id) => {

  const params = {
    'company_id': id
  }
  const response = await axios.get('/users', { params });

  console.log(response);
  return response.data;
});


const CompaniesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAgencies.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchAgencies.fulfilled, (state, action) => {
      state.status = 'idle';
      state.agencies = action.payload.success;
    }).addCase(fetchAgencies.rejected, (state) => {
      state.status = 'error';
    });

    builder.addCase(fetchSuppliers.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.status = 'idle';
      state.suppliers = action.payload.success;
    }).addCase(fetchSuppliers.rejected, (state) => {
      state.status = 'error';
    });

    builder.addCase(fetchOperators.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchOperators.fulfilled, (state, action) => {
      state.status = 'idle';
      state.operators = action.payload.success;
    }).addCase(fetchOperators.rejected, (state) => {
      state.status = 'error';
    });

    builder.addCase(fetchCompanies.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchCompanies.fulfilled, (state, action) => {
      state.status = 'idle';
      state.companies = action.payload.success;
    }).addCase(fetchCompanies.rejected, (state) => {
      state.status = 'error';
    });

    builder.addCase(addUser.pending, (state) => {
      state.status = 'loading';
    }).addCase(addUser.fulfilled, (state, action) => {
      state.status = 'idle';
    }).addCase(addUser.rejected, (state) => {
      state.status = 'error';
    });

    builder.addCase(fetchCompanyUsers.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchCompanyUsers.fulfilled, (state, action) => {
      console.log(action);

      state.status = 'idle';
      state.companyUsers = action.payload.success;
    }).addCase(fetchCompanyUsers.rejected, (state, action) => {
      state.status = 'error';
      console.log(action);
    });

    builder.addCase(fetchCompany.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchCompany.fulfilled, (state, action) => {
      state.status = 'idle';
      state.currentCompany = action.payload.success;
    }).addCase(fetchCompany.rejected, (state) => {
      state.status = 'error';
    }).addCase(deleteCompany.pending, (state) => {
      state.status = 'loading';
    }
    ).addCase(deleteCompany.fulfilled, (state, action) => {
      state.status = 'idle';
    }).addCase(deleteCompany.rejected, (state) => {
      state.status = 'error';
    }).addCase(addCompany.pending, (state) => {
      state.status = 'loading';
    }).addCase(deleteUser.pending, (state) => {
      state.status = 'loading';
    }).addCase(deleteUser.fulfilled, (state, action) => {
      state.status = 'idle';
    }).addCase(deleteUser.rejected, (state) => {
      state.status = 'error';
    });
  }
});

export const selectCompanies = (state: any) => state.companies.companies;
export const selectAgencies = (state: any) => state.companies.agencies;
export const selectSuppliers = (state: any) => state.companies.suppliers;
export const selectOperators = (state: any) => state.companies.operators;
export const selectCompanyUsers = (state: any) => state.companies.companyUsers;
export const selectCurrentCompany = (state: any) => state.companies.currentCompany;
export default CompaniesSlice.reducer;
