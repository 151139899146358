import Tooltip from '@mui/material/Tooltip';
import formatDate from "src/componenets/DateFormat";

interface RfpInvitesProps {
    handleSendEmails: () => void;
    invites: any[];
}

export default function RfpInvites({ invites, handleSendEmails }: RfpInvitesProps) {
    return (
        <>
            <h2 className="my-5">Invited Users</h2>
            <div className="row p-5 bg-custom-secondary rounded-pill mb-4">
                <div className="col-4 text-center text-white">
                    User
                </div>
                <div className="col-4 text-center text-white">
                    Invited Date
                </div>
                <div className="col-4 text-center text-white">
                    Actions
                </div>
            </div>
            {invites?.map((invite) => {
                return (
                    <div className="row p-5 align-items-center justify-content-center mb-4 bg-white border border-secondary rounded-lg">
                        <div className="col-4 text-center">
                            {invite.email}
                        </div>
                        <div className="col-4 text-center">
                            {formatDate(invite.created_at)}
                        </div>
                        <div className="col-4 text-center">
                            <Tooltip title="Resend Invite" className="cursor-pointer" onClick={() => {
                                handleSendEmails({ emails: invite.email })
                            }}>
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 21 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.01 3.03L9.52 6.25L2 5.25L2.01 3.03ZM9.51 11.75L2 14.97V12.75L9.51 11.75ZM0.00999999 0L0 7L15 9L0 11L0.00999999 18L21 9L0.00999999 0Z"
                                        fill="#003ACE"
                                    />
                                </svg>
                            </Tooltip>
                        </div>
                    </div>
                )
            })}

        </>
    );
}