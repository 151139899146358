import { forwardRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { inviteBeveragePartners } from "src/plugins/redux/reducers/RFPsReducer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import RfpInvites from "src/componenets/RfpInvites";
import { fetchItem, selectCurrentItem } from "src/plugins/redux/reducers/RFPsReducer";
import toast from "react-hot-toast";

const InviteOperatorClient = forwardRef((props: any, ref: any): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { register, handleSubmit, setValue } = useForm();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const rfp = useSelector(selectCurrentItem);

  useEffect(() => {
    dispatch(setHeaderTitle("Invite Operator Clients"));
    dispatch(setHeaderActionBtns([]));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
    if (id) {
      dispatch(fetchItem({ "id": id }));
    }
  }, []);

  const handleSendEmails = async (data) => {
    data.rfp_id = id;
    const response = await dispatch(inviteBeveragePartners(data));
    console.log(response);
    if (response.payload.success) {
      setValue('emails', response.payload?.success?.join(','));
      toast.success("Emails Sent Successfully");
      if (id) {
        dispatch(fetchItem({ "id": id }));
      }

    }
  };

  return (
    <>
      <form
        className="position-relative"
        onSubmit={handleSubmit(handleSendEmails)}
      >
        <div className="mt-10">
          <label>Add Emails Manually</label>
          <textarea
            {...register("emails", { required: true })}
            name="emails"
            className="form-control rounded-xl"
            placeholder="Enter emails separated by commas"
            rows={10}
          ></textarea>
        </div>
        <div className="d-flex align-items-center gap-3 mt-10">
          <button
            type="button"
            className="btn btn-outline-custom-primary p-4 rounded-circle"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </button>
          <button type="submit" className="btn btn-custom-primary btn-pill">
            Send Invites
          </button>
          <div className="position-absolute bottom-0">
            {showSuccessAlert && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Emails Sent Successfully
              </Alert>
            )}
          </div>
        </div>
      </form>
      <hr />
      <RfpInvites invites={rfp?.invites} handleSendEmails={handleSendEmails} />
    </>
  );
});

export default InviteOperatorClient;
