import React, { useState, useRef } from 'react';
import 'node_modules/video-react/dist/video-react.css';
import {
    Player,
    ControlBar,
    CurrentTimeDisplay,
    TimeDivider,
    VolumeMenuButton,
} from 'video-react';

interface VideoPlayerProps {
    fluid: Boolean;
    autoPlay: Boolean;
    height: String
}

const VideoPlayer = ({ fluid, autoPlay, height }: VideoPlayerProps) => {
    const [showReplayIcon, setShowReplayIcon] = useState(false);
    const [muted, setMuted] = useState(true);
    const playerRef = useRef(null);

    const handleVideoEnded = () => {
        setShowReplayIcon(true);
    };

    const handleReplayIconClick = () => {
        setShowReplayIcon(false);
        playerRef.current.load();
        setMuted(true);
    };

    const handleMuteToggle = () => {
        setMuted(!muted);
    };

    return (
        <>
      <Player
                ref={playerRef}
                autoPlay={true}
                muted={false}
                fluid={fluid}
                volume={1.0}
                width={'80%'}
                height={height}
                src="https://bevbridge-assets.s3.amazonaws.com/supplier/Supplier+Walkthrough+_+May+2024.mp4"
                onEnded={handleVideoEnded}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nofullscreen',
                        },
                    },
                }}
            >
                <ControlBar>
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <VolumeMenuButton disabled />
                    <button
                        className="custom-unmute-button"
                        onClick={handleMuteToggle}
                        style={{ color: muted ? 'white' : 'red' }}
                    >
                        <i className={`fa ${muted ? 'fa-volume-off' : 'fa-volume-up'}`} />
                    </button>
                </ControlBar>
            </Player>
            {showReplayIcon && (
                <div
                    className="replay-icon position-absolute d-flex flex-column align-items-center justify-content-center cursor-pointer"
                    onClick={handleReplayIconClick}
                >
                    <i className="fa fa-rotate-left text-white fs-1" />
                    <h2 className="text-white">Watch again</h2>
                </div>
            )}
        </>
    );
};

export default VideoPlayer;