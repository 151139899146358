import { useLocation, useParams } from 'react-router-dom';

import UploadPricingByStateModal from "pages/Products/includes/UploadPricingByStateModal";
import PricingByStateModal from "pages/Products/includes/PricingByStateModal";

import ProductsOverview from "./includes/ProductsOverview";
import ProductsUpdate from "./includes/ProductsUpdate";
import ProductsView from "./includes/ProductsView";

export default function () {

  const location = useLocation();
  const route = location.pathname;
  const { id } = useParams();

  const product = location.state ? location.state.product : null;

  const isCreate = route.startsWith('/products') && route.endsWith('/create');
  const isEdit = id && route.startsWith('/products') && route.endsWith('/edit');
  const isRfpProducts = route.startsWith('/rfp/products');

  if (isCreate || isEdit) {
    return (
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="row">
            <div className="col-12 col-md-12">
              <ProductsUpdate product={product} />
            </div>
          </div>

        </div>
      </div>
    );
  }

  if (id) {
    return (
      <div className="row">
        <div className="col-12 col-md-12">

          <div className="row">
            <div className="col-12 col-md-12">
              <ProductsView product={product} />
            </div>
          </div>

        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="row">
          <div className="col-12 col-md-12">
            <ProductsOverview />
          </div>
        </div>

      </div>
    </div>
  );
}
