import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState, forwardRef } from 'react';
import { addUser, updateUser, fetchCompanies, selectCompanies, fetchCompanyUsers } from "src/plugins/redux/reducers/AdminReducers";
import { EditUser } from 'src/plugins/redux/reducers/UsersReducers';
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import Select from 'react-select';
import selectStyles from 'src/themes/Helpers';
import toast from "react-hot-toast";

interface AddEditSupplierRepModalProps {
    isOpen: boolean;
    toggle: () => void;
    user?: any;
    company: any;
}

const AddEditSupplierRepModal = ({ isOpen, toggle, user, company }: AddEditSupplierRepModalProps) => {


    const dispatch = useDispatch();
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, reset } = useForm();
    const [errorMessage, setErrorMessage] = useState('');
    const companyDomains = company?.domains;
    const customSelectStyles = selectStyles.formSelectStyles;

    const [selectedStatus, setSelectedStatus] = useState({ value: 1, label: "Active" });
    const [userVerified, setUserVerified] = useState({ value: 1, label: 'Verified' });
    const [selectedCompany, setSelectedCompany] = useState<Object | Null>(null)
    const companies = useSelector(selectCompanies);

    useEffect(() => {
        dispatch(fetchCompanies());
    }, [])

    useEffect(() => {
        console.log(user);
        setValue("first_name", user?.first_name || "");
        setValue("last_name", user?.last_name || "");
        setValue("email", user?.email || "");
        setSelectedStatus({ value: user?.active ? 1 : 0, label: user?.active ? "Active" : "InActive" });
        setUserVerified({ value: user?.verified_at ? 1 : 0, label: user?.verified_at ? "Verified" : "Not Verified" });
        setSelectedCompany({ value: company?.id, label: company?.name });
        setValue("company_id", company?.id);
    }, [user]);

    const validateEmailDomain = (email) => {
        const emailDomain = email?.split("@")[1];
        if (!companyDomains?.map((domain) => domain?.domain).includes(emailDomain)) {
            return "Email domain must be the same as the company domain.";
        }
        return true;
    };


    const handleAddEditUser = async (data) => {
        data.active = selectedStatus.value;
        data.verified = userVerified.value;
        let response;
        if (user?.id) {
            data.id = user.id;
            response = await dispatch(EditUser(data));
        } else {
            response = await dispatch(addUser(data));
        }
        await dispatch(fetchCompanyUsers(company.id));
        if (response?.payload?.success) {
            toast.success(user?.id ? "User updated successfully" : "User added successfully");
            toggle();
        }

    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} id="addSupplierRepModal">
            <form onSubmit={handleSubmit(handleAddEditUser)}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 24px",
                    }}>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {user?.id ? "Update Rep" : "Add Rep"}
                    </Typography>
                    <IconButton onClick={toggle}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />
                <ModalBody>
                    <div className="d-flex flex-row align-items-center mb-4 gap-2">
                        <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="first_name">First Name</label>
                            <div className="d-flex align-items-center gap-1">
                                <input {...register("first_name", { required: true })} name="first_name" type="text" id="first_name"
                                    className={"form-control rounded-pill " + (errors.first_name ? 'invalid-input' : '')} />
                                {
                                    errors.first_name && (
                                        <Tooltip title="First Name is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="last_name">Last Name</label>
                            <div className="d-flex align-items-center gap-1">
                                <input {...register("last_name", { required: true })} name="last_name" type="text" id="last_name"
                                    className={"form-control rounded-pill " + (errors.last_name ? 'invalid-input' : '')} />
                                {errors.last_name && (
                                    <Tooltip title="Last Name is required" arrow>
                                        <ErrorIcon color="error" />
                                    </Tooltip>)}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-row align-items-center mb-4">
                        <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="email">Email</label>
                            <div className="d-flex align-items-center gap-1">
                                <input {...register("email", { required: true, validate: validateEmailDomain })} name="email" type="email" id="email"
                                    className={"form-control rounded-pill " + (errors.email ? 'invalid-input' : '')} />
                                {
                                    errors.email && (
                                        <Tooltip title={errors.email.message} arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {user?.id && <div className="d-flex flex-row align-items-center mb-4">
                        <div className="form-outline flex-fill mb-0">
                            <label className="form-label">Comapny</label>
                            <div className="d-flex align-items-center gap-1">
                                <Controller
                                    {...register('company_id', { required: true })}
                                    name="company_id"
                                    control={control}
                                    render={({ field, onChange }) => (
                                        <Select
                                            name="company_id"
                                            className={(errors.company_id ? 'invalid-input' : '')}
                                            options={companies?.map((company) => { return { value: company?.id, label: company?.name } })}
                                            value={selectedCompany}
                                            onChange={(e) => {
                                                setSelectedCompany(e)
                                                onChange(e.value)
                                            }}
                                            styles={customSelectStyles}
                                        />
                                    )}
                                />
                                {
                                    errors.company_id && (
                                        <Tooltip title="Company is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )
                                }
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex flex-row align-items-center mb-4">
                        <div className="form-outline flex-fill mb-0">
                            <label className="form-label">Status</label>
                            <div className="d-flex align-items-center gap-1">
                                <Select
                                    name="status"
                                    id="status"
                                    options={[
                                        { value: 1, label: "Active" },
                                        { value: 0, label: "InActive" }
                                    ]}
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e)}
                                    styles={customSelectStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-4">
                        <div className="form-outline flex-fill mb-0">
                            <label className="form-label">Verified</label>
                            <div className="d-flex align-items-center gap-1">
                                <Select
                                    name="verified"
                                    id="verified"
                                    options={[
                                        { value: 1, label: "Verified" },
                                        { value: 0, label: "Not Verified" }
                                    ]}
                                    value={userVerified}
                                    onChange={(e) => setUserVerified(e)}
                                    styles={customSelectStyles}
                                />
                            </div>
                        </div>
                    </div>

                    <span className="text-danger fw-bold">{errorMessage}</span>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-outline-custom-primary rounded-pill w-100">
                        Submit
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default AddEditSupplierRepModal;