import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "layouts/includes/Header";
import MobileHeader from "layouts/includes/MobileHeader";
import Menu from "layouts/includes/Menu";
import ScrollTop from "layouts/includes/ScrollTop";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import VideoPlayer from "src/componenets/VideoPlayer";
import { RemoveFirstLogin } from "src/plugins/redux/reducers/AuthReducer";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function AppLayout() {

  const location = useLocation();
  const currentUser = useSelector(currentUserSelector);
  const isRouteProductProfile = location.pathname.match(/^\/products\/\d+$/) || location.pathname.match(/^\/submission\/\d+$/);
  const navigate = useNavigate();
  const videoShowed = Cookies.get('video_showed');
  const [showVideo, setShowVideo] = useState<boolean>(true);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      if (!videoShowed) {
        setTimeout(() => {
          Cookies.set('video_showed', true);
        }, 5000)
      }
    }
  }, [currentUser])



  const handleCloseVideo = () => {
    setShowVideo(false);
    toast((t) => (
      <span className="d-flex align-items-center justify-content-center gap-2">
        To access this video just go to
        <button href="/get-help" className="btn btn-custom-primary btn-sm" onClick={() => {
          navigate('/get-help')
          toast.dismiss(t.id)
        }}>
          Get Help
        </button>
      </span>
    ));
  }


  if (isRouteProductProfile) {
    return (
      <div class="m-0 overflow-hidden">
        <Outlet />
      </div>
    )
  }

  return (
    <div id="kt_body" className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">
      <MobileHeader />
      {currentUser?.first_login && currentUser.role_id == 3 && !videoShowed && showVideo &&
        <div className="video-container">
          <i className="fa fa-times fa-xl close-video-icon cursor-pointer" onClick={handleCloseVideo}></i>
          <VideoPlayer fluid={false} autoPlay={true} height={'80%'} />
        </div>}
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <Menu />
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
              <div className="d-flex flex-column-fluid">
                <div className="container">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </div >
  );
}
