import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { confirmationRequest } from "plugins/redux/reducers/AuthReducer";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues, // Use getValues to access field values
  } = useForm<any>({
    mode: "onBlur",
  });

  const onSubmit = async (data: Object) => {
     if (isValid) {
      data["confirmation_code"] = id;
      const response = await dispatch(confirmationRequest(data) as any);
      if (response.payload.success) {
        navigate("/login");
      } else {
        setErrorMessage(response.payload);
      }
    }
  };
  return (
    <div className="row m-0">
      <div className="col-4">
        <div className="left-logo">
          <img className="w-25 p-6" src="/images/BevBridgeRFPLogo.png" />
        </div>
        <div className="min-100vh d-flex flex-column  justify-content-center">
          <div className="welcome-message ">
            <h1 className="font-weight-bold text-uppercase mb-10 fw-bold">New Password</h1>
          </div>
          <div className="login-image align-self-center">
            {/* <img className="" src="/assets/media/bg/password-reset-bot.png" /> */}
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-8 d-flex align-items-center justify-content-center min-100vh bg-custom-light">
        <div>
          <h1 className="mb-10 fw-bold">New Password</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-12 col-md-12 mb-5">
              <TextField
                  label="Password*"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("new_password", {
                    required: "Password is required",
                  })}
                />
              </div>
              <div className="col-12 col-md-12 mb-5">
              <TextField
                  label="Confirm Password*"
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("confirm_new_password", {
                    required: "Confirm Password is required",
                  })}
                />
              </div>

            </div>

            <div className="row">
              <div className="col-12 col-md-12 d-flex flex-column">
                <span className="text-danger fw-bold">{errorMessage}</span>
                <button
                  type="submit"
                  className="btn btn-custom-primary w-100 mb-3"
                  disabled={!isValid}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}