import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useNavigate } from 'react-router-dom';

export default function () {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(currentUserSelector);


    useEffect(() => {
        if (currentUser.role_id != '1') {
            if (currentUser.role_id == '2') {
                window.location.href = '/rfps';
            } else if (currentUser.role_id == '3') {
                window.location.href = '/rfps/find';
            } else if (currentUser.role_id == '4') {
                window.location.href = '/opertator-rfps';
            }
        }
    }, [currentUser])

}