export default function MobileHeader() {
  return(
    <div id="kt_header_mobile" className="header-mobile align-items-center header-mobile-fixed">
      <div className="d-flex align-items-center">
        <button className="btn p-0 burger-icon ml-4" id="kt_aside_mobile_toggle">
          <span></span>
        </button>
      </div>
    </div>
  );
}
