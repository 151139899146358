import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchComapnyRfps,
  selectStatus,
  selectCompanyItems,
} from "plugins/redux/reducers/RFPsReducer";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import moment from "moment";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
  setQuery,
} from "src/plugins/redux/reducers/HeaderReducer";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from '@mui/material/Tooltip';

export default function () {
  const dispatch = useDispatch();
  const items = useSelector(selectCompanyItems);
  const status = useSelector(selectStatus);
  const searchValue = useSelector((state) => state.header.search);
  const currentUser = useSelector(currentUserSelector);
  const [showMessage, setShowMessage] = useState(false);
  console.log(items);

  function updateClipboard(newClip) {
    navigator.clipboard.writeText(newClip).then(
      () => {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      },
      () => { }
    );
  }
  useEffect(() => {
    dispatch(setHeaderTitle("Manage RFPs"));
    dispatch(setHeaderActionBtns(false));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
  }, []);

  useEffect(() => {
    dispatch(
      fetchComapnyRfps({
        id: currentUser?.company_id,
        query: searchValue ?? "",
      })
    );
  }, [currentUser, searchValue]);

  const [anchorEl, setAnchorEl] = useState([]);
  const open = Boolean(anchorEl);

  const handleClick = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };

  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-12">
        {showMessage && (
          <Stack
            style={{
              position: "fixed",
              width: "200px",
              top: "65px",
              right: "0",
              zIndex: "1",
              border: "1px solid #3F4254",
            }}
            sx={{ width: "100%" }}
            spacing={2}
          >
            <Alert severity="success">Link Copied</Alert>
          </Stack>
        )}
        <div className="card mb-4 bg-custom-secondary rounded-pill">
          <div className="row p-5">
            <div className="col-2 col-md-2  text-center text-white">
              RFP Name
            </div>
            <div className="col-2 col-md-2 text-center text-white">
              Submissions
            </div>
            <div className="col-2 col-md-2 text-center text-white">
              Suppliers
            </div>
            <div className="col-2 col-md-2 text-center text-white">
              Date Created
            </div>
            <div className="col-2 col-md-2 text-center text-white">Status</div>
            <div className="col-2 col-md-2 text-center text-white">Actions</div>
          </div>
        </div>
        <div>
          {items.length == 0 && status == "idle" && (
            <div
              className="d-flex flex-column gap-2 align-items-center justify-content-center"
              style={{ minHeight: "50vh" }}
            >
              <strong className="text-center fs-2 text-muted">
                Because you just registered, you will need to add your
                program(s) so that you can get started.
              </strong>
              <Link className="btn btn-custom-primary btn-pill" to={"/create"}>
                Add New RFP
              </Link>
            </div>
          )}
          {items && items.length == 0 && status === "idle" && searchValue && (
            <div
              className="d-flex flex-column gap-2 align-items-center justify-content-center"
              style={{ minHeight: "50vh" }}
            >
              <strong className="text-center fs-2 text-muted">
                No RFPS Found
              </strong>
              <Link className="btn btn-custom-primary btn-pill" to={"/create"}>
                Add New RFP
              </Link>
            </div>
          )}
          {status === "loading" ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "50vh" }}
            >
              <div className="spinner-border text-custom-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            items &&
            items.map((item: any, key) => (
              <div
                className="row p-5 align-items-center mb-4 bg-white border border-secondary rounded-lg"
                key={key}
              >
                <div className="col-2 col-md-2 text-center">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-0 pr-3">
                      <img src={item.logo?.asset_file} width="56" />
                    </div>
                    <div className="flex-grow-1 text-left">
                      {item.rfp_title}
                    </div>
                  </div>
                </div>

                <div className="col-2 col-md-2 text-center">
                  <Link
                    state={{ item }}
                    to={`/rfp/products/short-list/${item.id}`}
                    style={{
                      color: "#003ace",
                      fontWeight: "bold",
                      borderBottom: "3px solid #003ace",
                    }}
                  >
                    {item.submissions}
                  </Link>
                </div>
                <div className="col-2 col-md-2 text-center text-white">
                  <Link
                    state={{ item }}
                    to={`/rfp/supplier-assets/${item.id}`}
                    style={{
                      color: "#003ace",
                      fontWeight: "bold",
                      borderBottom: "3px solid #003ace",
                    }}
                  >
                    {item.supplier_count}
                  </Link>
                </div>
                <div className="col-2 col-md-2 text-center">
                  {moment(item.created_at).format("MM-DD-YY")}
                </div>
                <div className="col-2 col-md-2 text-center">{item.status}</div>
                <div className="col-2 col-md-2 text-center d-flex align-items-center justify-content-center gap-2">
                  <p
                    style={{ display: "contents", cursor: "pointer" }}
                    onClick={() => {
                      updateClipboard(`${window.location.origin}/${item.id}`);
                    }}
                  >
                    <Tooltip title="Copy link">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clippath="url(#clip0_128_4425)">
                          <path
                            d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z"
                            fill="#003ACE"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_128_4425">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Tooltip>
                  </p>
                  <Link to={`/edit/${item.id}`}>
                    <Tooltip title="Edit RFP">
                      <i className="fa fa-pencil-alt text-primary-blue" />
                    </Tooltip>
                  </Link>
                  <Link to={"/" + item.id} state={{ item }}>
                    <Tooltip title="View RFP">
                      <i className="far fa-eye text-primary-blue" />
                    </Tooltip>
                  </Link>
                  <div
                    onClick={(event) => handleClick(event, key)}
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip title="Invite to RFP">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 21 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.01 3.03L9.52 6.25L2 5.25L2.01 3.03ZM9.51 11.75L2 14.97V12.75L9.51 11.75ZM0.00999999 0L0 7L15 9L0 11L0.00999999 18L21 9L0.00999999 0Z"
                          fill="#003ACE"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                  <Menu
                    id={"inviteMenu" + key}
                    anchorEl={anchorEl[key]}
                    open={Boolean(anchorEl[key])}
                    onClose={() => handleClose(key)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      style: { boxShadow: "none" },
                    }}
                  >
                    <MenuItem>
                      <Link to={`/rfp/invite/${item.id}`}>
                        Invite Beverage Partners
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to={`/rfp/client/${item.id}`}>
                        Invite Operator Clients
                      </Link>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            ))
          )}

          {status === "error" && (
            <div
              className="d-flex flex-column gap-2 align-items-center justify-content-center"
              style={{ minHeight: "50vh" }}
            >
              <strong className="text-center fs-1 text-custom-primary">
                Error occurred while fetching items.
              </strong>
              <button
                className="btn btn-custom-primary btn-pill"
                onClick={() =>
                  dispatch(
                    fetchComapnyRfps({
                      id: 1,
                      query: searchValue,
                    }) as any
                  )
                }
              >
                <i className="fa fa-redo-alt" />
                Retry
              </button>
            </div>
          )}
        </div>
        {/* <InfiniteScrollList /> */}
      </div>
    </div>
  );
}
