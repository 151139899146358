import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import {
  currentUserSelector,
  loginRequest,
  updateToken
} from "src/plugins/redux/reducers/AuthReducer";
import {
  EditUser,
  uploadImageToS3,
} from "src/plugins/redux/reducers/UsersReducers";

import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast from "react-hot-toast";
import { PatternFormat } from 'react-number-format';
import formatDate from "src/componenets/DateFormat";

export default function Users() {
  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserSelector);
  console.log(currentUser);
  const [logoFile, setLogoFile] = useState(null);

  console.log(currentUser);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm();

  const [changePassword, setChangePassword] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCurrentUserId, setSelectedCurrentUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.phone_number);

  const logoFileInputRef = useRef(null);

  useEffect(() => {
    dispatch(setHeaderTitle("Profile"));
    dispatch(setHeaderActionBtns(false));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
  }, []);

  useEffect(() => {
    setSelectedCompany(currentUser?.company_id);
    setSelectedCurrentUserId(currentUser?.id);
    setLogoFile(currentUser?.company_logo);
  }, [currentUser]);



  const getJwtData = (token: string): any => {
    const jwt = token.split('.');
    return JSON.parse(Buffer.from(jwt[1], 'base64').toString());
  }
  const handleEditUser = async (data: any) => {
    if (data.password !== data.password_confirmation && changePassword) {
      toast.error("New password does not match");
      return;
    }

    setIsLoading(true);
    data.oldEmail = currentUser?.email;
    data.company_id = selectedCompany;
    data.company_name = currentUser?.company_name;
    data.verified = true;
    data.role_id = currentUser?.role_id;
    data.id = selectedCurrentUserId;
    if (logoFile instanceof File) {
      const company_logo_url = await dispatch(
        uploadImageToS3({ file: logoFile, dirName: "company_logo" })
      );
      data.company_logo = company_logo_url.payload;
      setLogoFile(company_logo_url.payload);
    }
    if (!changePassword) {
      delete data.password;
      delete data.password_confirmation;
    }
    const response = await dispatch(EditUser(data));
    if (response.payload.success) {
      toast.success("Profile Updated!");
    } else {
      if (changePassword) {
        toast.error("The Password Entered Does Not Match Your Current Paswword");
      } else {
        toast.error(response.payload);
      }
    }

    setProfileUpdated(true);
    dispatch(updateToken(response.payload.token));
    setIsLoading(false);
  };

  console.log(currentUser);
  const handleLogoInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogoFile(file);
      };
      reader.readAsDataURL(file);
    }
    setProfileUpdated(false);
  };

  const toggleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <form onSubmit={handleSubmit(handleEditUser)}>
          <div className="row mb-6">
            <div className="col-4 col-md-4">
              <label className="w-100">Upload Company Logo</label>
              {formatDate(moment().format("YYYY-MM-DD HH:mm:ss")) }
              {!logoFile ? (
                <label className="btn btn-pill btn-dark">
                  <input
                    name="logo_file"
                    id="logo_file"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    className="form-control d-none"
                    ref={logoFileInputRef}
                    onChange={handleLogoInputChange}
                  />
                  <i className="la la-upload" style={{ cursor: "pointer" }}></i>
                  Browse
                </label>
              ) : (
                <label className="d-flex align-items-center gap-2">
                  {logoFile instanceof File ? (
                    <span>{logoFile.name}</span>
                  ) : (
                    <span>
                      {logoFile?.substring(logoFile.lastIndexOf("/") + 1)}
                    </span>
                  )}
                  <i
                    className="fa fa-times fa-lg text-custom-primary"
                    onClick={() => {
                      setLogoFile(null);
                      setProfileUpdated(false);
                    }}
                  ></i>
                </label>
              )}
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-4 col-md-4">
              <label className="w-100">Company Name</label>
              <input
                {...register("company_name")}
                name="company_name"
                id="company_name"
                type="text"
                className="form-control rounded-pill"
                defaultValue={currentUser?.company_name}
                placeholder="Title"
                disabled={true}
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-4 col-md-4">
              <label className="w-100">Email*</label>
              <input
                {...register("email", {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                })}
                name="email"
                id="email"
                type="email"
                className="form-control rounded-pill"
                defaultValue={currentUser?.email}
                placeholder="Email"
                onChange={() => setProfileUpdated(false)}
              />
            </div>
          </div>
          {errors?.email && (
            <p style={{ color: "red" }}>Please enter a valid email</p>
          )}
          {(currentUser?.role_id === 3 || currentUser?.role_id == 2) && (
            <>
              <div className="row mb-6">
                <div className="col-4 col-md-4">
                  <label className="w-100">First Name*</label>
                  <input
                    {...register("first_name", { required: true })}
                    name="first_name"
                    id="first_name"
                    type="text"
                    className="form-control rounded-pill"
                    defaultValue={currentUser?.first_name}
                    placeholder="First Name"
                    onChange={() => setProfileUpdated(false)}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-4 col-md-4">
                  <label className="w-100">Last Name*</label>
                  <input
                    {...register("last_name", { required: true })}
                    name="last_name"
                    id="last_name"
                    type="text"
                    className="form-control rounded-pill"
                    defaultValue={currentUser?.last_name}
                    placeholder="Last Name"
                    onChange={() => setProfileUpdated(false)}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-4 col-md-4">
                  <label className="w-100">Phone Number</label>
                  <PatternFormat
                    name="phone_number"
                    {...register("phone_number")}
                    className="form-control rounded-pill"
                    type="tel"
                    format="+1 (###) ###-####"
                    mask="_"
                    defaultValue={currentUser?.phone_number}
                    onValueChange={value => setPhoneNumber(value.formattedValue)}
                  />
                </div>
              </div>
            </>
          )}

          <div id="password-editor" className="row mb-6">
            <div className="col-4 col-md-4">
              <button
                type="button"
                className="btn btn-pill btn-outline-dark"
                onClick={toggleChangePassword}
              >
                Change Password
              </button>
            </div>
          </div>

          {changePassword && (
            <>
              <div className="row mb-6">
                <div className="col-4 col-md-4">
                  <label className="w-100">Current Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("current_password", { required: true })}
                      name="current_password"
                      id="current_password"
                      type={showCurrentPassword ? "text" : "password"}
                      className="form-control rounded-pill "
                      placeholder="********"
                      onChange={() => setProfileUpdated(false)}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-4 col-md-4">
                  <label className="w-100">
                    New Password{" "}
                    <small className="text-mute">Hard Password</small>
                  </label>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      {...register("password", {
                        required: true,
                        pattern:
                          "/^(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/",
                      })}
                      name="password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      className="form-control rounded-pill"
                      placeholder="********"
                      onChange={() => setProfileUpdated(false)}
                    />
                    <InputAdornment>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <div className="col-4 col-md-4">
                  <label className="w-100">Retype New Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("password_confirmation", { required: true })}
                      name="password_confirmation"
                      id="password_confirmation"
                      type={showPassword ? "text" : "password"}
                      className="form-control rounded-pill"
                      onChange={() => setProfileUpdated(false)}
                      placeholder="********"
                    />
                    <InputAdornment>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                </div>
              </div>
              <span className="text-danger">
                {errors.password?.message}
              </span>
            </>
          )}

          <div className="row mb-6">
            <div className="col-4 col-md-4">
              <button
                type="submit"
                className="btn btn-pill btn-dark"
                disabled={isLoading || profileUpdated}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
