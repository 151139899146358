import AppLayout from "layouts/AppLayout";
import AuthLayout from "layouts/AuthLayout";
import AdminLayout from "layouts/Admin/AdminLayout";
import NotFound from "themes/pages/NotFound";

import RFPs from "pages/RFPs";
import SubmittedRFPs from "pages/Users/RFPs";
import Products from "pages/Products";
import ProductsUpdate from "pages/Products/includes/ProductsUpdate";
import ProductsDraft from "pages/Products/includes/ProductsDraft";
import ProductsTrash from "pages/Products/includes/ProductsTrash";
import ProductSubmissions from "pages/RFPs/includes/ProductSubmissions";
import Submissions from "pages/Products/includes/Submissions";
import ProductsSubmissionView from "pages/Products/includes/ProductSubmissionView";
import ProductsShortList from "pages/RFPs/includes/ProductsShortList";
import FindRFPs from "pages/RFPs/includes/FindRFPs";
import RFPUpdate from "pages/RFPs/includes/RFPUpdate";
import InviteBeveragePartners from "pages/RFPs/includes/InviteBeveragePartners";
import InviteOperatorClient from "pages/RFPs/includes/InviteOperatorClient";
import ProductsFavouriteList from "pages/RFPs/includes/ProductsFavouriteList";
import OpertatorRfpInvited from "pages/RFPs/includes/OpertatorRfpInvited";
import RFPSupplierAssets from "pages/RFPs/includes/RFPSupplierAssets";

import LoginAs from "pages/Admin/LoginAs";

/* Admin */
import Agencies from "pages/Admin/Agencies";
import Suppliers from "pages/Admin/Suppliers";
import AgencyReps from "pages/Admin/Agencies/includes/AgencyReps";
import SupplierReps from "pages/Admin/Suppliers/includes/SupplierReps";
import Operators from "pages/Admin/Operators";
import OperatorReps from "pages/Admin/Operators/includes/OperatorReps";

/* Auth */
import Users from "pages/Users";
import Login from "pages/Users/Login";
import Registration from "pages/Users/Registration";
import Confirmation from "pages/Users/Confirmation";
import Reset from "pages/Users/Reset";
import Verification from "pages/Users/Verification";
import GetHelp from "pages/Users/GetHelp";
import UpgragePremium from "pages/Users/UpgradePremium";
import MyClients from "pages/Clients/MyClients";


interface Route {
  path: string;
  element: JSX.Element;
  allowedRoles?: number[];
  children?: Route[];
}

const routes: Route[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/rfps",
        element: <SubmittedRFPs />,
        allowedRoles: [1, 2],
      },
      {
        path: "/opertator-rfps",
        element: <OpertatorRfpInvited />,
        allowedRoles: [4],
      },
      {
        path: "/create",
        element: <RFPUpdate />,
        allowedRoles: [1, 2],
      },
      {
        path: "/edit/:id",
        element: <RFPUpdate />,
        allowedRoles: [1, 2],
      },
      {
        path: "/:id",
        element: <RFPs />,
        allowedRoles: [1, 2, 3, 4],
      },
      {
        path: "/login-as",
        element: <LoginAs />,
        allowedRoles: [1, 2, 3, 4],
      },
      {
        path: "/rfps/find",
        element: <FindRFPs />,
        allowedRoles: [1, 3],
      },
      {
        path: "/products",
        element: <Products />,
        allowedRoles: [1, 3],
      },
      {
        path: "/products/draft",
        element: <ProductsDraft />,
        allowedRoles: [1, 3],
      },
      {
        path: "/rfp/supplier-assets/:id",
        element: < RFPSupplierAssets />,
        allowedRoles: [1, 2, 4],
      },
      {
        path: "/products/trash",
        element: <ProductsTrash />,
        allowedRoles: [1, 3],
      },
      {
        path: "/submissions",
        element: <Submissions />,
        allowedRoles: [1, 3],
      },
      {
        path: "/submissions/draft",
        element: <Submissions />,
        allowedRoles: [1, 3],
      },
      {
        path: "/submissions/trash",
        element: <Submissions />,
        allowedRoles: [1, 3],
      },
      {
        path: "/submission/:id",
        element: <ProductsSubmissionView />,
        allowedRoles: [1, 2, 3, 4],
      },

      {
        path: "/products/create",
        element: <ProductsUpdate />,
        allowedRoles: [1, 3],
      },
      {
        path: "/products/:id",
        element: <Products />,
        allowedRoles: [1, 2, 3, 4],
      },
      {
        path: "/products/:id/edit",
        element: <ProductsUpdate />,
        allowedRoles: [1, 3],
      },
      {
        path: "/rfp/products/:id",
        element: <ProductSubmissions />,
        allowedRoles: [1, 2],
      },
      {
        path: "/rfp/products/short-list/:id",
        element: <ProductsShortList />,
        allowedRoles: [1, 2, 4],
      },
      {
        path: "/rfp/products/favorites-list/:id",
        element: <ProductsFavouriteList />,
        allowedRoles: [1, 2, 4],
      },
      {
        path: "/rfp/invite/:id",
        element: <InviteBeveragePartners />,
        allowedRoles: [1, 2],
      },
      {
        path: "/my-clients",
        element: <MyClients />,
        allowedRoles: [1, 2],
      },
      {
        path: "/rfp/client/:id",
        element: <InviteOperatorClient />,
        allowedRoles: [1, 2],
      },
      {
        path: "/users",
        element: <Users />,
        allowedRoles: [1, 2, 3, 4],
      },
      {
        path: "/users/:id",
        element: <Users />,
        allowedRoles: [1, 2, 3, 4],
      },
      {
        path: "/upgrade-premium",
        element: <UpgragePremium />,
        allowedRoles: [1, 2, 3, 4],
      },
      {
        path: "/get-help",
        element: <GetHelp />,
        allowedRoles: [1, 2, 3, 4],
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/registration",
        element: <Registration />,
      },
      {
        path: "/confirmation/:id",
        element: <Confirmation />,
      },
      {
        path: "/reset",
        element: <Reset />,
      },
      {
        path: "/verification/:id",
        element: <Verification />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "/admin/agencies",
        element: <Agencies />,
        allowedRoles: [1],
      },
      {
        path: "/admin/agency/rep/:id",
        element: <AgencyReps />,
        allowedRoles: [1],
      },
      {
        path: "/admin/suppliers",
        element: <Suppliers />,
        allowedRoles: [1],
      },
      {
        path: "/admin/supplier/rep/:id",
        element: <SupplierReps />,
        allowedRoles: [1],
      },
      {
        path: "/admin/operators",
        element: <Operators />,
        allowedRoles: [1],
      },
      {
        path: "/admin/operators/rep/:id",
        element: <OperatorReps />,
        allowedRoles: [1],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
