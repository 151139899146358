import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'plugins/axios';
import Cookies from "js-cookie";

interface State {
    id: number;
    name: string;
}

interface States {
    states: State[];
    status: 'idle' | 'loading' | 'error';
}

const initialState: States = {
    states: [],
    status: 'idle',
};

const params = {
    'limit': '100'
};
export const fetchStates = createAsyncThunk('states/fetchStates', async () => {
    const response = await axios.get('/states', { params });
    return response.data;
});

const StatesSlice = createSlice({
    name: 'states',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchStates.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchStates.fulfilled, (state, action) => {
            state.status = 'idle';
            state.states = action.payload.success;
        }).addCase(fetchStates.rejected, (state) => {
            state.status = 'error';
        });
    }
});

export const selectStates = (state: any) => state.states.states;
export default StatesSlice.reducer;
