import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  fetchSuppliers,
  selectSuppliers,
} from "plugins/redux/reducers/AdminReducers";
import {
  fetchProducers,
  selectProducers,
  fetchProminentFlavors,
  selectProminentFlavors,
  fetchCountryOfOrigins,
  selectCountries,
  fetchRegions,
  selectRegions,
  fetchUnitSizes,
  selectSizes,
  fetchUnitsPerCase,
  selectUnitsPerCase,
  fetchItemClosures,
  selectItemClosures,
} from "plugins/redux/reducers/DropdownDataReducer";
import {
  fetchCategories,
  selectCategories,
  poursOptions,
  selectPours,
} from "plugins/redux/reducers/CategoriesReducer";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectStyles from "src/themes/Helpers";
import { Controller } from "react-hook-form";
import { Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { multiValueLabelCSS } from "react-select/dist/declarations/src/components/MultiValue";
import {
  fetchItem,
  fetchRfpProducts,
} from "plugins/redux/reducers/RFPsReducer";
import { useParams } from "react-router-dom";

function ProductsFilterModal(props: any) {
  const handleFormSubmit = () => {
    props.onSubmit();
  };
  const products = props.products;
  const dispatch = useDispatch();
  const formSelectStyles = CustomSelectStyles.formSelectStyles;
  const toggle = () => props.setShowFiltersModal(!props.showFiltersModal);
  const [selectedCategory, setSelectedCategory] = useState();
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [subTypes, setSubTypes] = useState([]);
  const suppliers = useSelector(selectSuppliers);

  const producers = useSelector(selectProducers);
  const categories = useSelector(selectCategories);
  const countries = useSelector(selectCountries);
  const regions = useSelector(selectRegions);
  const sizes = useSelector(selectSizes);
  const itemClosures = useSelector(selectItemClosures);
  const unitsPerCase = useSelector(selectUnitsPerCase);
  const flavors = useSelector(selectProminentFlavors);
  const pours = useSelector(selectPours);

  const register = props.register;
  const control = props.control;

  useEffect(() => {
    dispatch(fetchSuppliers());
    dispatch(fetchProducers());
    dispatch(fetchCategories());
    dispatch(fetchProminentFlavors());
    dispatch(fetchCountryOfOrigins());
    dispatch(fetchRegions());
    dispatch(fetchUnitsPerCase());
  }, []);

  useEffect(() => {
    if (selectedCategory?.id) {
      dispatch(fetchUnitSizes(selectedCategory?.id));
      console.log(selectedCategory);
      setTypes(selectedCategory?.types.filter((type) => {
        return products.some((product) => product?.categoryTypeInfo?.id == type.id);
      }));
    }
    if (selectedCategory?.name == "Wine") {
      dispatch(fetchItemClosures());
    }
    dispatch(poursOptions());
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedType?.id) {
      setSubTypes(selectedType?.sub_type?.filter((subType) => {
        return products.some((product) => product?.categorySubTypeInfo?.id == subType?.id);
      }));
    }

  }, [selectedType]);



  const [producerss, setProducerss] = useState([]);
  const [uniqueCatinfo, setUniqueCatinfo] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);

  useEffect(() => {
    const uniqueProducers = products?.reduce((unique, product) => {
      const producer = product.producerInfo;
      if (unique?.findIndex((p) => p?.id === producer?.id) === -1) {
        return [...unique, producer];
      }
      return unique;
    }, []);

    setProducerss(uniqueProducers);
    const uniqueCatinfos = categories?.filter(category =>
      products?.some(product => product.catinfo.id == category.id)
    );

    setUniqueCatinfo(uniqueCatinfos);

    const filterSuppliers = () => {
      const filteredData = suppliers?.filter((supplier) =>
        products?.some((product) => product?.supplier_id === supplier?.id)
      );
      setFilteredSuppliers(filteredData);
    };
    filterSuppliers();

  }, [products, categories]);


  const { supplier, setSupplier } = props;
  const [producer, setProducer] = useState();
  const [category, setCategory] = useState();
  const [typesState, setTypesState] = useState();
  const [subTypeState, setSubTypesState] = useState();
  const [itemClosuresState, setitemClosures] = useState();
  const [poursState, setPoues] = useState();
  const [sizesState, setSizes] = useState();
  const { id } = useParams<{ id: string }>();
  const [refreshProducts, setRefreshProducts] = useState(false);
  const [minAbv, setMinabvSet] = useState("");
  const [maxAbv, setMaxAbv] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPriceOunce, setMinPriceOunce] = useState("");
  const [maxPriceOunce, setMaxPriceOunce] = useState("");

  const clearAll = () => {
    setSupplier(null);
    setProducer(null);
    setCategory(null);
    setSelectedCategory(null);
    setTypesState(null);
    setitemClosures(null);
    setPoues(null);
    setSizes(null);
    setMinPrice("");
    setMaxPrice("");
    setMinPriceOunce("");
    setMaxPriceOunce("");
    setMinabvSet("");
    setMaxAbv("");
    props.clearAll()
  };


  return (
    <div>
      <Modal isOpen={props?.showFiltersModal} toggle={toggle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px 24px",
          }}
        >
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            All Filters
          </Typography>
          <IconButton onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </div>

        <Divider />
        <ModalBody>
          <div className="mb-3">
            <label>Supplier</label>
            <Controller
              name="supplier_id"
              control={control}
              render={({ field: { onChange, value, ref, name } }) => (
                <Select
                  styles={formSelectStyles}
                  value={supplier}
                  options={filteredSuppliers?.map((supplier: any) => {
                    return { label: supplier.name, value: supplier.id };
                  })}
                  onChange={(value) => {
                    setSupplier(value);
                    onChange(value.value);
                  }}
                />
              )}
            />
          </div>

          <div className="mb-3">
            <label>Producer</label>
            <Controller
              name="producer_id"
              control={control}
              render={({ field: { onChange, value, ref, name } }) => (
                <Select
                  styles={formSelectStyles}
                  value={producer}
                  options={producerss?.map((producer: any) => {
                    return { label: producer?.name, value: producer?.id };
                  })}
                  onChange={(value) => {
                    setProducer(value);
                    onChange(value.value);
                  }}
                />
              )}
            />
          </div>

          <div className="mb-3">
            <label>Category</label>
            <Controller
              name="category_id"
              control={control}
              render={({ field: { onChange, value, ref, name } }) => (
                <Select
                  styles={formSelectStyles}
                  value={category}
                  options={uniqueCatinfo?.map((category: any) => {
                    return { label: category.name, value: category.id };
                  })}
                  onChange={(newCategory: any) => {
                    setSelectedCategory(
                      categories.find((cat: any) => cat.id == newCategory.value)
                    );
                    onChange(newCategory.value);
                    setCategory(newCategory);
                  }}
                />
              )}
            />
          </div>
          {selectedCategory && types?.length > 0 && (
            <div className="mb-3">
              <label>Type</label>
              <Controller
                name="category_type_id"
                control={control}
                render={({ field: { onChange, value, ref, name } }) => (
                  <Select
                    styles={formSelectStyles}
                    value={typesState}
                    options={types?.map((type: any) => {
                      return { label: type.name, value: type.id };
                    })}
                    onChange={(type) => {
                      setSelectedType(
                        types.find((typeData) => typeData.id == type.value)
                      );
                      console.log(types.find((typeData) => typeData.id == type.value))
                      onChange(type.value);
                      setTypesState(type);
                    }}
                  />
                )}
              />
            </div>
          )}
          {selectedType?.id && subTypes?.length > 0 && (
            <div className="mb-3">
              <label>Varietal / Sub-Type</label>
              <Controller
                name="category_sub_type_id"
                control={control}
                render={({ field: { onChange, value, ref, name } }) => (
                  <Select
                    styles={formSelectStyles}
                    value={subTypeState}
                    options={subTypes?.map((type: any) => {
                      return { label: type.name, value: type.id };
                    })}
                    onChange={(value) => {
                      onChange(value.value);
                      setSubTypesState(value);
                    }}
                  />
                )}
              />
            </div>
          )}
          {selectedCategory?.name == "Wine" && itemClosures?.length > 0 && (
            <div className="mb-3">
              <label>Item Closure</label>
              <Controller
                name="item_closure_id"
                control={control}
                render={({ field: { onChange, value, ref, name } }) => (
                  <Select
                    styles={formSelectStyles}
                    value={itemClosuresState}
                    options={itemClosures?.map((itemClosure: any) => {
                      return { label: itemClosure.name, value: itemClosure.id };
                    })}
                    onChange={(value) => {
                      onChange(value.value);
                      setitemClosures(value);
                    }}
                  />
                )}
              />
            </div>
          )}
          {selectedCategory?.id && selectedCategory?.id != 4 && pours?.length > 0 && (
            <div className="mb-3">
              <label>Placement</label>
              <Controller
                name="pour_id"
                control={control}
                render={({ field: { onChange, value, ref, name } }) => (
                  <Select
                    styles={formSelectStyles}
                    value={poursState}
                    options={pours?.filter((pour) => {
                      return pour.category_id == selectedCategory?.id;
                    })?.map((pour: any) => {
                      return { label: pour.name, value: pour.id };
                    })}
                    onChange={(values) => {
                      onChange(values.value);
                      setPoues(values);
                    }}
                  />
                )}
              />
            </div>
          )}
          {selectedCategory?.id && sizes?.length > 0 && (
            <div className="mb-3">
              <label>Sizes</label>
              <Controller
                name="sizes"
                control={control}
                render={({ field: { onChange, value, ref, name } }) => (
                  <Select
                    isMulti
                    styles={formSelectStyles}
                    value={sizesState}
                    options={sizes?.filter((size) => {
                      return products.some((product) => product?.productSizeInfo[0]?.id == size?.id);
                    })?.map((size: any) => {
                      return { label: size.name, value: size.id };
                    })}
                    onChange={(values) => {
                      onChange(values);
                      setSizes(values);
                    }}
                  />
                )}
              />
            </div>
          )}
          {/* {flavors?.length > 0 && (
            <div className="mb-3">
              <label>prominent Flavors</label>
              <Controller
                name="flavors"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    isMulti
                    styles={formSelectStyles}
                    options={flavors?.map((flavor: any) => {
                      return { label: flavor.name, value: flavor.id };
                    })}
                    onChange={(values) => {
                      onChange(values);
                    }}
                  />
                )}
              />
            </div>
          )} */}
          {/* {unitsPerCase && (
            <div className="mb-3">
              <label>Units Per Case</label>
              <Controller
                {...register("units_per_case")}
                name="units_per_case"
                control={control}
                render={({ field: { onChange, value, ref, name } }) => (
                  <Select
                    styles={formSelectStyles}
                    options={unitsPerCase?.map((unitPerCase: any) => {
                      return { label: unitPerCase.name, value: unitPerCase.id };
                    })}
                    onChange={(value) => {
                      onChange(value.value);
                    }}
                  />
                )}
              />
            </div>
          )} */}

          <label className="text-muted fs-2">ABV</label>
          <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
            <div>
              <label>Min %</label>
              <input
                {...register("min_abv")}
                value={minAbv}
                onChange={(e) => setMinabvSet(e.target.value)}
                name="min_abv"
                type="text"
                className="form-control rounded-pill"
              />
            </div>
            <div>
              <label>Max %</label>
              <input
                {...register("max_abv")}
                value={maxAbv}
                onChange={(e) => setMaxAbv(e.target.value)}
                name="max_abv"
                type="text"
                className="form-control rounded-pill"
              />
            </div>
          </div>
          <label className="text-muted fs-2">Unit Price</label>
          <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
            <div>
              <label>Min Price</label>
              <input
                {...register("min_price")}
                onChange={(e) => setMinPrice(e.target.value)}
                value={minPrice}
                name="min_price"
                type="text"
                className="form-control rounded-pill"
              />
            </div>
            <div>
              <label>Max Price</label>
              <input
                {...register("max_price")}
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                name="max_price"
                type="text"
                className="form-control rounded-pill"
              />
            </div>
          </div>

          <label className="text-muted fs-2">Price / Ounce</label>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div>
              <label>Min Price</label>
              <input
                {...register("min_price_ounce")}
                onChange={(e) => setMinPriceOunce(e.target.value)}
                value={minPriceOunce}
                name="min_price_ounce"
                type="text"
                className="form-control rounded-pill"
              />
            </div>
            <div>
              <label>Max Price</label>
              <input
                {...register("max_price_ounce")}
                onChange={(e) => setMaxPriceOunce(e.target.value)}
                value={maxPriceOunce}
                name="max_price_ounce"
                type="text"
                className="form-control rounded-pill"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            className="btn btn-pill btn-outline-custom-primary"
            onClick={clearAll}
          >
            Clear All
          </Button>{" "}
          <button
            className="btn btn-pill btn-custom-primary"
            onClick={handleFormSubmit}
            onKeyDown={toggle}
          >
            Apply
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ProductsFilterModal;
