import React from "react";
import { useEffect } from "react";
import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
    setTitleLink
} from "src/plugins/redux/reducers/HeaderReducer";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { PricingTable } from 'stripe-pricing-table';



export default function upgradePremium() {

    const dispatch = useDispatch();
    const currentUser = useSelector(currentUserSelector);

    console.log(currentUser);

    useEffect(() => {
        dispatch(setHeaderTitle("Upgrade to Premium"));
        dispatch(setHeaderActionBtns([]));
        dispatch(setHeaderArrowBack(true));
        dispatch(setShowSearch(false));
    }, []);

    const StripePricingTables = () => {

        const pricing_tables = [
            {
                id: 'prctbl_1OjQZ5Gj69fz4A54OFyI1MQN',
                role_id: 3

            },
            {
                id: 'prctbl_1OjCGiGj69fz4A54Sym54gkG',
                role_id: 2
            },
        ]

        useEffect(() => {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/pricing-table.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }, []);
        return pricing_tables.map((pricing_table) => {
            if (currentUser.role_id === pricing_table.role_id) {
                return React.createElement("stripe-pricing-table", {
                    "pricing-table-id": pricing_table.id,
                    "publishable-key": 'pk_test_51OKkMjGj69fz4A54Lw7a1y1oYznwnyQRJUZsgPzOt9dlxPzz1wF4jheRET6zmytsw6KKIlFI5lgV0IDSS5Wifbuu00ddqa23xb',
                    "class": "d-flex align-items-center flex-wrap col-12",
                    "client-reference-id": currentUser.id,
                    "success-url": `${window.location.origin}/products`,
                });
            }
        }
        );
    };


    return (
        <>
            <StripePricingTables />
        </>
    );
}