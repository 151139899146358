import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'plugins/axios';
import Cookies from "js-cookie";

interface Category {
    id: number;
    name: string;
}

interface CategoriesState {
    categories: Category[];
    currentCategory: {};
    types: [];
    varietal: [];
    pours: [];
    status: 'idle' | 'loading' | 'error';
}

const initialState: CategoriesState = {
    Categories: [],
    types: [],
    varietal: [],
    pours: [],
    status: 'idle',
};

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
    const includeArray = ['types', 'sub-type'].join(',');
    const response = await axios.get('/categories', {
        params: {
            include: includeArray,
        },
    });
    return response.data;
});

export const categorySubTypes = createAsyncThunk('categories/categorySubTypes', async () => {
    const response = await axios.get('/categorySubTypes');
    return response.data;
});

export const addSubType = createAsyncThunk('categories/addSubType', async (data) => {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    const response = await axios.post('/categorySubTypes/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const categoryTypes = createAsyncThunk('categories/categoryTypes', async () => {
    const response = await axios.get('/categoryTypes');
    return response.data;
});

export const poursOptions = createAsyncThunk('categories/pours', async () => {
    const response = await axios.get('/pours');
    return response.data;
});

export const addPour = createAsyncThunk('categories/addPour', async (data) => {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    const response = await axios.post('/pours/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const addType = createAsyncThunk('categories/addType', async (data) => {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    const response = await axios.post('/categoryTypes/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
})

export const fetchCategory = createAsyncThunk('categories/fetchCategory', async (id, includeTypes, includeSubtypes) => {
    try {
        const includeArray = ['types', 'sub-type'].join(',');
        const response = await axios.get(`http://3.20.58.44:3001/categories/${id}`, {
            params: {
                include: includeArray,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});



const CategoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.categories = action.payload.success;
        }).addCase(fetchCategories.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(fetchCategory.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchCategory.fulfilled, (state, action) => {
            console.log(action);
            state.status = 'idle';
            state.currentCategory = action.payload.success;
        }).addCase(fetchCategory.rejected, (state, action) => {
            state.status = 'error';
        });

        builder.addCase(categoryTypes.fulfilled, (state, action) => {
            state.types = action.payload.success;
        }).addCase(categoryTypes.rejected, (state, action) => {
            console.log(action);
            state.status = 'error';
        });

        builder.addCase(categorySubTypes.fulfilled, (state, action) => {
            state.varietal = action.payload.success;
        }).addCase(categorySubTypes.rejected, (state, action) => {
            console.log(action);
            state.status = 'error';
        });

        builder.addCase(poursOptions.fulfilled, (state, action) => {
            state.pours = action.payload.success;
        }).addCase(poursOptions.rejected, (state, action) => {
            console.log(action);
            state.pours = 'error';
        });

        builder.addCase(addPour.fulfilled, (state, action) => {
            console.log(action);
            return state;
        }).addCase(addPour.rejected, (state, action) => {
            console.log(action);
            return state;
        });

        builder.addCase(addType.fulfilled, (state, action) => {
            console.log(action);
            return state;
        }).addCase(addType.rejected, (state, action) => {
            console.log(action);
            return state;
        });

        builder.addCase(addSubType.fulfilled, (state, action) => {
            console.log(action);
            return state;
        }).addCase(addSubType.rejected, (state, action) => {
            console.log(action);
            return state;
        });
    }
});


export const selectCategories = (state: any) => state.categories.categories;
export const selectCategoriesStatus = (state: any) => state.categories.status;
export const selectCurrentCategory = (state: any) => state.categories.currentCategory;
export const selectTypes = (state: any) => state.categories.types;
export const selectSubTypes = (state: any) => state.categories.varietal;
export const selectPours = (state: any) => state.categories.pours;
export default CategoriesSlice.reducer;