import { Link } from "react-router-dom";


export default function SuppplierDummyProducts() {
    return (
        Array.from({ length: 5 }, (_, index) => index + 1).map((item, key) => {
            return (
                <div
                    key={key}
                    className="card mb-4 bg-white border border-secondary rounded-lg"
                >
                    <div className="row p-5 align-items-center text-custom-primary">
                        <div className="col-2 col-md-2 text-center">Product Name</div>
                        <div className="col-2 col-md-2 text-center">
                            Producer
                        </div>
                        <div className="col-2 col-md-2 text-center">
                            Category
                        </div>
                        <div className="col-2 col-md-2 text-center">
                            Type
                        </div>
                        <div className="col-2 col-md-2 text-center">
                            Varietal / Sub-Type
                        </div>
                        <div className="col-2 col-md-2 text-center d-flex align-items-center justify-content-center">
                            <Link
                                className="mr-6"
                                to={`/products`}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <g clipPath="url(#clip0_4_3167)">
                                        <path
                                            d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                                            fill="#003ACE"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4_3167">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>
                            <Link to={`/products`} className="mr-6">
                                <i className="fa fa-edit text-primary-blue"></i>
                            </Link>
                            <span className="cursor-pointer">
                                <i className="fa fa-times text-primary-blue"></i>
                            </span>
                        </div>
                    </div>
                </div>)
        })
    )
}