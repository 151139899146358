import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { fetchCompanyClients, selectClients } from "src/plugins/redux/reducers/ClientsReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ClientModal from "src/componenets/ClientModal";
import formatDate from "src/componenets/DateFormat";

interface Client {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    rfps: any[];
}

export default function MyClients() {

    const dispatch = useDispatch();

    const clients: Client[] = useSelector(selectClients);
    const [selectedClient, setSelectedClient] = useState<Client>({} as Client);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        dispatch(setHeaderTitle("My Clients"));
        dispatch(setHeaderActionBtns([]));
        dispatch(setHeaderArrowBack(false));
        dispatch(setShowSearch(false));
        dispatch(fetchCompanyClients());
    }, [])

    return (
        <div className="row">
            <button className="btn btn-outline-custom-primary rounded-pill mb-3 ml-auto"
                onClick={() => {
                    setSelectedClient({} as Client);
                    toggle();
                }}>
                Add New Client
            </button>

            <div className="col-12 col-md-12">
                <div className="card mb-4 rounded-pill bg-custom-secondary">
                    <div className="row p-5">
                        <div className="col-4 text-white text-center">
                            Client Name
                        </div>
                        <div className="col-4 text-white text-center">
                            Status
                        </div>
                        <div className="col-4 text-white text-center">
                            Next RFP
                        </div>
                        {/* <div className="col-3 text-white text-center">
                            Actions
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="col-12">
                {clients?.map((client: Client, i) => (
                    <div
                        key={i}
                        className="card mb-4 bg-white border border-secondary rounded-lg"
                    >
                        <div className="row p-5 align-items-center text-custom-primary">
                            <div className="col-4 text-center">
                                {client.client_name}
                            </div>
                            <div className="col-4 text-center">
                                {client.status}
                            </div>
                            <div className="col-4 text-center">
                                {client.next_rfp ? formatDate(client.next_rfp) : '-'}
                            </div>
                            {/* <div className="col-3 text-center d-flex align-items-center justify-content-center gap-1">
                                <i className="fa fa-edit text-primary-blue cursor-pointer" onClick={() => {
                                    setSelectedClient(client);
                                    toggle();
                                }}></i>
                            </div> */}
                        </div>
                    </div>
                ))}
                {clients.length === 0 && (
                    <div className="d-flex align-items-center justify-content-center flex-column gap-3 my-10">
                        <h3>No clients found</h3>
                        <button className="btn btn-outline-custom-primary rounded-pill"
                            onClick={() => {
                                setSelectedClient({} as Client);
                                toggle();
                            }}
                        >
                            Add New Client
                        </button>
                    </div>
                )}
            </div>
            <ClientModal
                modal={modal}
                toggle={toggle}
                client={selectedClient}
            />
        </div >
    );
}